import { Box, CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import JupyterViewer from "react-jupyter-notebook";
import { collaborationsService } from "services/api/collaborations";
import "./style.css";

const NoteBook = ({ json }) => {
  const [eda, setEda] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNotebook = async () => {
      if (!json) return;

      try {
        setLoading(true);
        const response = await collaborationsService.getNotebookJson(json);
        setEda(response.data);
      } catch (error) {
        console.error("Error fetching notebook:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotebook();
  }, [json]);

  if (loading) {
    return (
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
          minHeight: 500,
        }}>
        <Grid item>
          <CircularProgress size={30} />
        </Grid>
      </Grid>
    );
  }

  if (!json) {
    return (
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
          minHeight: 500,
        }}>
        <Grid item>No EDA found.</Grid>
      </Grid>
    );
  }

  return (
    eda && (
      <Box className="notebook-viewer">
        <JupyterViewer rawIpynb={eda} mediaAlign="center" />
      </Box>
    )
  );
};

export default NoteBook;
