import { Badge, IconButton, Tooltip, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NotificationIcon } from "Components/Icon";
import NotificationItem from "./NotificationItem";
import axios from "axios";
import { collaborationsService } from "services/api/collaborations";

const HtmlNotiTooltip = withStyles((theme) => ({
  arrow: {
    color: "#FFFFFF",
  },
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 273,
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #FFFFFF",
    borderRadius: "2px",
  },
}))(Tooltip);

const UPDATE_NOTIFICATIONS_INTERVAL = 8000;
let cancelExpToken;

function Notifications(props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notificationsList, setNotificationsList] = useState({
    unread_count: 0,
    count: 0,
  });

  const handleTooltipClose = () => {
    props.handleNotifications(!props.open);
  };

  const getNotifications = async () => {
    setLoading(true);

    if (typeof cancelExpToken != typeof undefined) {
      cancelExpToken.cancel("Operation canceled due to new request.");
    }

    cancelExpToken = axios.CancelToken.source();

    try {
      const response = await collaborationsService.getNotifications(
        cancelExpToken.token
      );
      setNotificationsList({ ...response.data });
    } catch (error) {
      if (error.response) {
        setError("Please make sure your credentials are correct.");
      } else {
        setError("Make sure you are connected to the internet.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let intervalDetails;
    getNotifications();
    const token = localStorage.getItem("_key_usr_tkn");
    intervalDetails = setInterval(() => {
      if (token) {
        getNotifications();
      }
    }, UPDATE_NOTIFICATIONS_INTERVAL);

    return () => {
      clearInterval(intervalDetails);
    };
  }, []);

  return (
    <>
      <HtmlNotiTooltip
        arrow
        interactive
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={props.open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          notificationsList.count > 0 ? (
            notificationsList.results.map((item) => (
              <NotificationItem {...item} />
            ))
          ) : (
            <div className="notifications-tooltip">No new notifications</div>
          )
        }>
        <IconButton
          role="notifications-button"
          className="notifications-button"
          onClick={handleTooltipClose}
          variant="outlined">
          <Badge badgeContent={notificationsList.unread_count} color="error">
            <NotificationIcon active={props.open} />
          </Badge>
        </IconButton>
      </HtmlNotiTooltip>
    </>
  );
}

export default Notifications;
