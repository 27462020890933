import React, { useContext, useEffect, useState } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";

import "./style.css";
import { Box, CircularProgress, Grid } from "@material-ui/core";

import { useHistory } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import CollaborationCard from "Components/CollaborationCard";

const CollaborationList = (props) => {
  const {
    state: {
      competitions,
      competitions: { loading, list },
    },
    getCollaborations,
  } = useContext(GlobalContext);
  let history = useHistory();
  const [page, setPage] = useState(0);
  const appInsights = useAppInsightsContext();
  const trackCollaborationCard = useTrackMetric(
    appInsights,
    "CollaborationCard",
    {}
  );

  useEffect(() => {
    if (list && list.length === 0 && !loading) {
      getCollaborations();
    }
    if (list && list.length > 0 && competitions.next) {
      getCollaborations(page);
    }
  }, [page]);

  const handleClick = (event, row) => {
    history.push({
      pathname: `/collaborations/${row.slug}/details/`,
      state: { ...row },
    });

    trackCollaborationCard({
      competitionName: row?.title,
      competitionSlug: row.slug,
    });
  };

  return list && list.length > 0 ? (
    <Box
      style={{
        display: "flex",
        flexWrap: "wrap",
        marginTop: 16,
        marginBottom: 16,
        rowGap: 16,
        columnGap: 15,
      }}>
      {list.map((competition, index) => (
        <CollaborationCard
          key={competition.dataset}
          competition={competition}
          index={index}
          onClick={(event) => handleClick(event, competition)}
        />
      ))}
    </Box>
  ) : loading ? (
    <Grid
      style={{ marginTop: 24 }}
      container
      justifyContent="center"
      alignItems="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid
      style={{ marginTop: 24 }}
      container
      justifyContent="center"
      alignItems="center">
      <div>No competitions found</div>{" "}
    </Grid>
  );
};

export default CollaborationList;
