import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Portal,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import Header from "Components/Header";
import { CustomTabs } from "Components/CustomTabs/CustomTabs";
import { CustomTab } from "Components/CustomTabs/CustomTab";
import { Alert, AvatarGroup, TabContext, TabPanel } from "@material-ui/lab";
import CollaborationDetails from "Components/CollaborationDetails";
import { useHistory, useLocation, useParams } from "react-router";
import {
  EditObjectiveIcon,
  InviteIcon,
  NotificationCheckIcon,
} from "Components/Icon";
import CloseIcon from "@material-ui/icons/Close";
import DocIcon from "assets/documentationIcon.png";

import {
  convertDateToDDMMYY,
  formatDateWithTimeZone,
  nFormatter,
} from "utils/format";
import { GlobalContext } from "Context/GlobalState";
import CustomPopOver from "Components/CustomPopOver";
import TeamPopup from "Components/ExperimentsList/TeamPopup";
import TeamEditPopup from "Components/ExperimentsList/TeamEditPopup";
import CopyToClipboard from "react-copy-to-clipboard";
import { collaborationsService } from "services/api/collaborations";

const CollaborationDetailsPage = (props) => {
  const {
    state: {
      competitions: { loading, list },
    },
    joinCollaboration,
    getCollaborations,
    updateCollaboration,
  } = useContext(GlobalContext);
  const [value, setValue] = useState("leaderboard");
  const [open, setOpen] = useState(false);
  let history = useHistory();
  const { state } = useLocation();
  const location = useLocation();

  const [anchorUserEl, setAnchorUserEl] = useState(null);
  const [anchorTeamEl, setAnchorTeamEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const [competition, setCollaboration] = useState({});
  const { competitionId, tab } = useParams();

  const usersPopover = Boolean(anchorUserEl);

  const teamPopover = Boolean(anchorTeamEl);

  const getCollaboration = () => {
    collaborationsService
      .getSingleCollaboration(competitionId)
      .then((res) => {
        const data = res.data;
        setCollaboration({ ...data });
        updateCollaboration({ ...data });
      })
      .catch((error) => {
        console.log("CATCH", error);
        window.location.href = "/";
      });
  };

  useEffect(() => {
    getCollaboration();
    if (tab) {
      setValue(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const propCollaboration =
      list.find((competition) => competition.dataset === state?.dataset) ||
      state;
    if (propCollaboration) {
      setCollaboration({ ...propCollaboration });
    }

    if (list.length === 0 && !loading) {
      getCollaborations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, state]);

  const handleUsersDetails = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setAnchorUserEl(e.currentTarget);
  };

  const handleUserPopoverClose = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setAnchorUserEl(null);
  };

  const handleTeamDetails = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setAnchorTeamEl(e.currentTarget);
  };

  const handleTeamPopoverClose = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setAnchorTeamEl(null);
  };

  const onUpdateTeamName = (data) => {
    updateCollaboration({
      ...competition,
      user_team: { ...data },
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const progressStatusColor = () => {
    if (competition?.progress_status === "Upcoming") {
      return "#4BBDCC";
    } else if (competition?.progress_status === "Finished") {
      return "#FF0000";
    } else if (competition?.progress_status === "Preparation") {
      return "#EAC706";
    } else if (competition?.progress_status === "Active") {
      return "#5DD39E";
    }
  };

  const showCopyMessage = (e) => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box paddingX={7}>
      <Box pb={1}>
        <Header />
      </Box>

      {competition && Object.keys(competition).length > 0 ? (
        <>
          <div className="competition-header__top">
            <Box
              style={{
                backgroundImage: "url(" + competition?.competition_image + ")",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "130px",
              }}>
              <Tooltip
                title={`Status of Collaboration: ${competition?.progress_status}​`}>
                <div
                  style={{ backgroundColor: progressStatusColor() }}
                  className="competition-status progress">
                  {competition?.progress_status === "Upcoming"
                    ? `Upcoming ${formatDateWithTimeZone(
                        competition?.start_date
                      )}`
                    : competition?.progress_status}
                </div>
              </Tooltip>

              <Grid
                style={{
                  paddingTop: "56px",
                  paddingLeft: "32px",
                  alignItems: "center",
                }}
                container>
                <Grid item>
                  <Tooltip title={`Host of the Collaboration`}>
                    <div className="competition-header__org-thumbnail">
                      <img
                        role="presentation"
                        alt=""
                        className="competition-header__org-thumbnail-image"
                        src={competition?.host_company?.company_logo}
                      />
                    </div>
                  </Tooltip>
                </Grid>
                <Grid style={{ marginRight: "auto" }} item>
                  <div
                    style={{
                      flexDirection: "column",
                    }}
                    className="competition-header__info">
                    <Tooltip title={competition?.title}>
                      <h1 className="competition-header__title">
                        {competition?.title}
                      </h1>
                    </Tooltip>
                    <Tooltip title={competition?.goal}>
                      <h2
                        style={{
                          textAlign: "left",
                          color: "#FFFFFF",
                          marginLeft: 0,
                          textShadow: "1px 1px 1px #474747",
                        }}
                        className="competition-header__subtitle">
                        {competition?.goal}
                      </h2>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Grid
              container
              direction="row"
              className="competition-header__meta">
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flex: "1 0 18%",
                }}
                // lg={2}
                item>
                <Tooltip title={`# of participating Teams`}>
                  <div className="competition-header__info">
                    <h1
                      style={{ minWidth: "103px" }}
                      className="competition-header__title_small">
                      Total Teams
                    </h1>
                    <h2 className="competition-header__subtitle">
                      {competition?.total_participants}
                    </h2>
                  </div>
                </Tooltip>

                <Tooltip title={`Final submission date`}>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                    className="competition-header__info">
                    <h1 className="competition-header__title_small">
                      Final submission
                    </h1>
                    <h2 className="competition-header__subtitle">
                      {convertDateToDDMMYY(competition?.final_submission_date)}
                    </h2>
                  </div>
                </Tooltip>
              </Grid>
              <Grid
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 0 18%",
                }}
                item>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>Computation budget</div>
                    </React.Fragment>
                  }>
                  <div className="competition-header__info">
                    <h1 className="competition-header__title_small">
                      Computation budget
                    </h1>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        Flops utilized{" "}
                        {nFormatter(competition?.total_flops_used, 2)}F
                      </div>
                      <div>
                        Remaining available Flops{" "}
                        {nFormatter(
                          competition?.total_flops_provided -
                            competition?.total_flops_used,
                          2
                        )}
                        F
                      </div>
                    </React.Fragment>
                  }>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                    className="competition-header__info">
                    <div className="tflops-container">
                      <div className="tflops-progress-text">
                        {nFormatter(competition?.total_flops_used, 2)}F of{" "}
                        {nFormatter(competition?.total_flops_provided, 2)}F
                        utilized
                      </div>
                      <div
                        className="tflops-progress"
                        style={{
                          width: `${
                            (competition?.total_flops_used /
                              competition?.total_flops_provided) *
                              100 || 0
                          }%`,
                          border: `1px solid #${
                            competition?.total_flops_used > 0
                              ? "4bbdcc"
                              : "c7d2d5"
                          }`,
                        }}
                      />
                    </div>
                  </div>
                </Tooltip>
              </Grid>
              <Grid
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 0 18%",
                }}
                item>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>Todays submits</div>
                    </React.Fragment>
                  }>
                  <div
                    className="competition-header__info"
                    style={{ marginLeft: "-10px" }}>
                    <h1 className="competition-header__title_small">
                      Todays submits
                    </h1>
                    <h2 className="competition-header__subtitle">
                      {competition?.user_team
                        ?.today_inferred_submissions_count || 0}
                      /5
                    </h2>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>Dataset ID</div>
                    </React.Fragment>
                  }>
                  <div
                    style={{
                      marginTop: "10px",
                      marginLeft: "68px",
                      cursor: "pointer",
                    }}
                    className="competition-header__info">
                    <CopyToClipboard
                      text={competition?.dataset_key}
                      onCopy={showCopyMessage}>
                      <h1 className="competition-header__title_small">
                        Dataset ID
                      </h1>
                    </CopyToClipboard>
                    <CopyToClipboard
                      text={competition?.dataset_key}
                      onCopy={showCopyMessage}>
                      <h2 className="competition-header__subtitle">
                        {competition?.dataset_key}
                      </h2>
                    </CopyToClipboard>
                  </div>
                </Tooltip>
              </Grid>

              <Grid
                style={{
                  marginLeft: "33px",
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 0 18%",
                }}
                item>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>High Score</div>
                    </React.Fragment>
                  }>
                  <div
                    style={{
                      marginLeft: "35px",
                    }}
                    className="competition-header__info">
                    <h1 className="competition-header__title_small">
                      High Score
                    </h1>
                    <h2 className="competition-header__subtitle">
                      {(competition?.high_score || 0).toFixed(5)}
                    </h2>
                    <h2
                      className="competition-header__subtitle"
                      style={{
                        color: "#C7D2D5",
                        fontStyle: "normal",
                      }}>
                      | 1.
                    </h2>
                  </div>
                </Tooltip>

                <Tooltip
                  title={
                    <React.Fragment>
                      <div>Your best Score</div>
                    </React.Fragment>
                  }>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                    className="competition-header__info">
                    <h1 className="competition-header__title_small">
                      Your best Score
                    </h1>
                    <h2 className="competition-header__subtitle">
                      {(competition.user_team?.high_score || 0).toFixed(5)}
                    </h2>
                    <h2
                      style={{
                        color: "#C7D2D5",
                        fontStyle: "normal",
                      }}
                      className="competition-header__subtitle">
                      | {competition.user_team?.rank || " -"}
                    </h2>
                  </div>
                </Tooltip>
              </Grid>

              <Grid
                style={{
                  marginLeft: "5px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 0 15%",
                  alignItems: competition?.is_already_participant
                    ? "flex-end"
                    : "flex-end",
                  justifyContent: "flex-end",
                }}
                item>
                {!competition?.is_already_participant ? (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <div>JOIN COMPETITION</div>
                      </React.Fragment>
                    }>
                    <div
                      style={{
                        width: "100px",
                        height: "34px",
                        backgroundColor: "#3576A7",
                        borderRadius: "30px",
                        paddingTop: "10px",
                        paddingBottom: "11px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleClickOpen}>
                      {props.loading ? (
                        <CircularProgress size={20} style={{ color: "#fff" }} />
                      ) : (
                        <Typography
                          style={{
                            textAlign: "center",
                            fontSize: "10px",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                          }}>
                          JOIN
                        </Typography>
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  <>
                    <div className="competition-header__info">
                      <h1
                        className="competition-header__title_small"
                        style={{ minWidth: "70px", textAlign: "left" }}>
                        Team name
                      </h1>

                      <Tooltip
                        title={`Team name: ${competition?.user_team?.name}`}>
                        <Typography
                          className="competition-header__subtitle"
                          component={"h2"}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                            textAlign: "left",
                            letterSpacing: "0px",
                            lineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word",
                            display: "-webkit-box",
                            boxOrient: "vertical",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: "1",
                            cursor: "pointer",
                          }}
                          onClick={handleTeamDetails}
                          title={competition?.user_team?.name}>
                          {competition?.user_team?.name}
                        </Typography>
                      </Tooltip>

                      <Tooltip title={`Edit team name`}>
                        <h2
                          className="competition-header__subtitle"
                          style={{
                            color: "#C7D2D5",
                            fontStyle: "normal",
                            cursor: "pointer",
                          }}>
                          <EditObjectiveIcon
                            onClick={handleTeamDetails}
                            className="icon"
                          />
                        </h2>
                      </Tooltip>
                    </div>

                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <AvatarGroup
                        onClick={handleUsersDetails}
                        spacing={5}
                        classes={{
                          root: "avatars-group",
                          avatar: "avatar-small",
                        }}
                        max={5}>
                        {competition?.user_team?.data_scientists &&
                          competition?.user_team?.data_scientists.map(
                            (user) => {
                              return (
                                <Tooltip
                                  title={`${user.first_name} ${user.last_name}`}>
                                  <Avatar
                                    className="avatar-small"
                                    alt={user.first_name}
                                    src={`${user.profile_image}`}
                                  />
                                </Tooltip>
                              );
                            }
                          )}
                      </AvatarGroup>

                      <Tooltip title="Invite user or another team">
                        <Avatar
                          onClick={handleUsersDetails}
                          style={{ cursor: "pointer" }}
                          className="avatar-icon">
                          <InviteIcon className="icon" />
                        </Avatar>
                      </Tooltip>
                    </Box>

                    <CustomPopOver
                      open={usersPopover}
                      anchorEl={anchorUserEl}
                      onClose={handleUserPopoverClose}>
                      <TeamPopup
                        title={`Invite user or another team`}
                        datasetKey={competition.dataset_key}
                        datasetId={competition.dataset}
                        isCollaboration={true}
                        teamId={competition?.user_team?.id || null}
                        users={competition?.user_team?.data_scientists || []}
                        owner={competition?.owner}
                        pending={competition?.pending_invitations}
                        host={competition.host_company}
                        accessType={competition?.access_type}
                      />
                    </CustomPopOver>

                    <CustomPopOver
                      open={teamPopover}
                      anchorEl={anchorTeamEl}
                      onClose={handleTeamPopoverClose}>
                      <TeamEditPopup
                        title={`Update team name`}
                        datasetKey={competition.dataset_key}
                        datasetId={competition.dataset}
                        isCollaboration={true}
                        onUpdateTeam={onUpdateTeamName}
                        team={competition?.user_team || []}
                      />
                    </CustomPopOver>
                  </>
                )}
              </Grid>
            </Grid>
          </div>

          <TabContext value={value}>
            <CustomTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example">
              <CustomTab
                title="Benchmarked Model Results"
                value="leaderboard"
                label="Leaderboard"
                style={{ marginLeft: "1px", borderTopLeftRadius: "5px" }}
              />

              <CustomTab
                title="Collaboration Details"
                value="overview"
                label="Overview"
              />

              <CustomTab
                title="Collaboration Dataset"
                value="exploratory_data_analysis"
                label="Exploratory Data Analysis"
              />
              <CustomTab
                title="Collaboration Dataset"
                value="experiments"
                label="Experiments"
              />
              <CustomTab
                title="My Benchmarked Model Results"
                value="my_submissions"
                label="My Submissions"
              />

              <CustomTab
                title="Collaboration Rules"
                value="rules"
                label="Rules"
              />

              <CustomTab
                title="Discussion"
                value="discussion"
                label="Discussion"
                style={
                  competition.user_is_admin
                    ? {}
                    : { borderTopRightRadius: "5px" }
                }
              />

              {competition.user_is_admin && (
                <CustomTab
                  title="Admin"
                  value="admin"
                  label="Admin"
                  style={{ borderTopRightRadius: "5px" }}
                />
              )}
            </CustomTabs>

            <Paper variant="outlined" style={{ marginBottom: "8px" }}>
              <TabPanel className="tab-Panel" value={"leaderboard"}>
                <CollaborationDetails {...competition} filter={value} />
              </TabPanel>
              <TabPanel className="tab-Panel" value={"overview"}>
                <CollaborationDetails {...competition} filter={value} />
              </TabPanel>
              <TabPanel className="tab-Panel" value={"data"}>
                <CollaborationDetails {...competition} filter={value} />
              </TabPanel>
              <TabPanel
                className="tab-Panel"
                value={"exploratory_data_analysis"}>
                <CollaborationDetails {...competition} filter={value} />
              </TabPanel>
              <TabPanel className="tab-Panel" value={"experiments"}>
                <CollaborationDetails {...competition} filter={value} />
              </TabPanel>
              <TabPanel className="tab-Panel" value={"my_submissions"}>
                <CollaborationDetails {...competition} filter={value} />
              </TabPanel>
              <TabPanel className="tab-Panel" value={"rules"}>
                <CollaborationDetails
                  {...competition}
                  filter={value}
                  openModel={handleClickOpen}
                />
              </TabPanel>

              <TabPanel className="tab-Panel" value={"discussion"}>
                <CollaborationDetails {...competition} filter={value} />
              </TabPanel>

              <TabPanel className="tab-Panel" value={"admin"}>
                <CollaborationDetails
                  {...competition}
                  isPrivate={true}
                  filter={value}
                />
              </TabPanel>
            </Paper>
          </TabContext>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: "12px",
              },
            }}
            style={{
              background:
                "transparent linear-gradient(119deg, rgba(75, 189, 204, 0.5) 0%, rgba(35, 58, 135, 0.5) 100%) 0% 0% no-repeat padding-box",
            }}>
            <DialogContent
              style={{
                width: "631px",
                height: "383px",
                display: "flex",
                flexDirection: "column",
              }}>
              <CloseIcon
                onClick={handleClose}
                style={{
                  color: "#3576A7",
                  alignSelf: "flex-end",
                  marginRight: "24px",
                  cursor: "pointer",
                }}
              />
              {/* <Box> */}
              <Typography
                style={{
                  color: "#3576A7",
                  fontSize: "32px",
                  letterSpacing: "0px",
                  textAlign: "center",
                  fontWeight: "500",
                  marginTop: "42px",
                }}>
                Please read the Collaboration rules
              </Typography>
              {/* </Box> */}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "24px",
                }}>
                <img src={DocIcon} alt="Collaboration Rules" height={20} />
                <Typography
                  // component={"button"}
                  onClick={() => {
                    setValue("rules");
                    handleClose();
                  }}
                  style={{
                    color: "#4BBDCC",
                    fontSize: "16px",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}>
                  General Collaboration Rules
                </Typography>
              </Box>

              <Typography
                style={{
                  marginLeft: "71px",
                  marginRight: "46px",
                  textAlign: "center",
                  color: "#707070",
                  letterSpacing: "0px",
                  fontSize: "16px",
                  marginTop: "24px",
                }}>
                By clicking on the „I understand and Accept“-Button below you
                agree to be bound by the competition rules
              </Typography>
              <Box
                onClick={() => {
                  const token = localStorage.getItem("_key_usr_tkn");
                  if (!token) {
                    // window.location = "/login";
                    const index = location.pathname.indexOf("/details");
                    const redirectPath = location.pathname.substring(
                      0,
                      index + "/details".length
                    );

                    history.push({
                      pathname: "/",
                      state: {
                        redirect_to: `${redirectPath}/rules`,
                      },
                    });
                    return false;
                  }

                  joinCollaboration(competition.dataset);
                  handleClose();
                }}
                style={{
                  width: "236px",
                  height: "59px",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderColor: "#3576A7",
                  borderRadius: "32px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "33px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}>
                <Typography
                  style={{
                    color: "#3576A7",
                    fontSize: "16px",
                    letterSpacing: "0px",
                    textAlign: "center",
                    fontWeight: "500",
                  }}>
                  I UNDERSTAND & ACCEPT
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>

          <Portal>
            {copied && (
              <Snackbar
                open={copied}
                autoHideDuration={3000}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                <Alert
                  iconMapping={{
                    success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
                  }}
                  variant="filled"
                  severity="success">
                  Dataset ID successfully copied
                </Alert>
              </Snackbar>
            )}
          </Portal>
        </>
      ) : (
        <Grid
          style={{ minHeight: 300 }}
          alignItems="center"
          justifyContent="center"
          container>
          <CircularProgress size={40} />
        </Grid>
      )}
    </Box>
  );
};

export default CollaborationDetailsPage;
