import { Box, Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import "./style.css";
import { numberWithSpaces } from "utils/format";
import { trainingState } from "utils";

export const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: "#FFFFFF",
  },
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #FFFFFF",
    borderRadius: "2px",
  },
}))(Tooltip);

const Sustainability = (props) => {
  const { value, current, estimated } = props;
  const status = props.expStatus;

  return (
    <div title={props.title} className={`like-button sustainability-container`}>
      <HtmlTooltip
        arrow
        title={
          <div className="sustain-tooltip">
            <div className="tooltip-sustain-title">
              {" "}
              This is equivalent to CO2 emissions from:
            </div>

            <ul className="tooltip-sustain-sub-title">
              <li>
                <span className="tooltip-sustain-value">
                  {parseFloat(value / 403).toFixed(2)}
                </span>{" "}
                miles driven by an avg. gasoline-powered passenger vehicle.
              </li>
              <li>
                <span className="tooltip-sustain-value">
                  {Math.round(value / 8.22)}
                </span>{" "}
                number of smartphones charged.
              </li>
            </ul>
            <div className="tooltip-sustain-sub-title">
              The calculation is based on the hourly est. kWh emissions for the
              region of the data center.
            </div>

            <div className="tooltip-sustain-sub-title">
              gCO2e = number of grams of CO2 emissions with the same global
              warming potential as one gram of another greenhouse gas.
            </div>
          </div>
        }>
        <Box>
          <span className="sustain-icon">
            {numberWithSpaces(parseFloat(current).toFixed(2))} gCO2e
          </span>{" "}
          &nbsp;
          {status !== trainingState.completed && (
            <span className="sustain-icon">
              {" "}
              of ~ {numberWithSpaces(parseFloat(estimated).toFixed(2))} gCO2e
            </span>
          )}
        </Box>
      </HtmlTooltip>
    </div>
  );
};

export default Sustainability;
