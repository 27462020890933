import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import { GlobalContext } from "Context/GlobalState";
import LoginHeader from "Components/LoginHeader";
import { useForm } from "react-hook-form";

import { useLocation, useHistory } from "react-router-dom";
import {
  EmailIcon,
  FirstNameIcon,
  LastNameIcon,
  PasswordHiddenIcon,
  PasswordIcon,
  PasswordShowIcon,
} from "Components/Icon";
import { updateUserProfile } from "services/api/auth";

const OnBoard = (props) => {
  const { user, setUserUpdate } = useContext(GlobalContext);
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const location = useLocation();
  let history = useHistory();
  const usernameRef = useRef(null);
  const firstNameRef = useRef(null);
  const passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user && user.is_password_changed) {
      history.push({ pathname: `/` });
    }
  }, [user]);

  const signupUser = (registrationData) => {
    setLoading(true);

    updateUserProfile(registrationData)
      .then((res) => {
        const data = res.data;
        setError(null);
        setSuccess(true);
        setLoading(false);
        const datasetKey = localStorage.getItem("datasetKey");
        localStorage.removeItem("datasetKey");
        setUserUpdate({ ...data });
        history.push({
          pathname: `/experiments/${datasetKey || ""}`,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setError("error", {
            type: "required",
            message: error.response.statusText,
          });
        } else if (error.response && error.response.data) {
          Object.entries(error.response.data).forEach((value) => {
            if (["email", "password", "confirm_password"].includes(value[0])) {
              setError(value[0], {
                type: "required",
                message: value[1].toString(),
              });
            }
          });
        } else {
          setError("error", {
            type: "required",
            message: "Make sure you are connected to the internet.",
          });
        }
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    signupUser(data);
  };

  return (
    <Box>
      <LoginHeader />
      <Box className="onboard-user-main">
        <div className="onboard-user">
          <Grid
            onSubmit={handleSubmit(onSubmit)}
            component={"form"}
            className="form"
            container
            direction="column">
            {success && (
              <Grid style={{ marginBottom: "8px" }} item>
                <Alert icon={false} variant="filled" severity="success">Account created successfully.</Alert>
              </Grid>
            )}

            <Grid item>
              <h3>Welcome to tracebloc!</h3>
              <h3 style={{ color: "#212529", marginBottom: "24px" }}>
                Let's begin the adventure
              </h3>
            </Grid>

            <Grid item>
              <TextField
                inputProps={{ ...register("email", { required: false }) }}
                disabled
                value={location?.state?.email}
                inputRef={usernameRef}
                fullWidth={true}
                margin="normal"
                className="login-email"
                placeholder="Your email"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon style={{ marginRight: "8px" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                inputProps={{
                  ...register("first_name", {
                    required: "Please enter your first name",
                    minLength: {
                      value: 2,
                      message: "First name must be greater than 2 characters",
                    },
                  }),
                }}
                onChange={() => clearErrors()}
                inputRef={firstNameRef}
                fullWidth={true}
                margin="normal"
                className="login-username"
                placeholder="First Name"
                helperText={
                  errors.first_name && (
                    <span className="error">{errors.first_name?.message}</span>
                  )
                }
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FirstNameIcon style={{ marginRight: "8px" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <TextField
                inputProps={{
                  ...register("last_name", {
                    required: "Please enter your last name",
                    minLength: {
                      value: 2,
                      message: "Last name must be greater than 2 characters",
                    },
                  }),
                }}
                onChange={() => clearErrors()}
                inputRef={firstNameRef}
                fullWidth={true}
                margin="normal"
                className="login-username"
                placeholder="Last Name"
                helperText={
                  errors.last_name && (
                    <span className="error">{errors.last_name?.message}</span>
                  )
                }
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LastNameIcon style={{ marginRight: "8px" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <TextField
                inputProps={{
                  ...register("password", {
                    required:
                      "Make sure it's at least 8 Characters including a number and a lowercase letter",
                    pattern: {
                      value: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                      message:
                        "Make sure it's at least 8 Characters including a number and a lowercase letter",
                    },
                  }),
                }}
                onChange={() => clearErrors()}
                inputRef={passwordRef}
                type={showPassword ? "text" : "password"}
                fullWidth={true}
                margin="normal"
                className="login-password"
                placeholder="Your password"
                helperText={
                  errors.password && (
                    <span className="error">{errors.password?.message}</span>
                  )
                }
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon style={{ marginRight: "8px" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <PasswordShowIcon />
                        ) : (
                          <PasswordHiddenIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            
            {errors.manual && (
              <div className="error-message">{errors.manual.message}</div>
            )}

            {errors.error && (
              <div className="error-message">{errors.error.message}</div>
            )}
            <Grid item>
              <Button
                type="submit"
                size="large"
                variant="contained"
                className="login-button"
                color="primary"
                classes={{ label: "label" }}
                disableElevation>
                {loading ? (
                  <CircularProgress size={35} color="inherit" />
                ) : (
                  "Sign up"
                )}
              </Button>
            </Grid>
          </Grid>

          <div className="terms-of-service">
            By creating an account, you agree to the{" "}
            <a href="https://www.tracebloc.io/terms">Terms of Service</a>. For
            more information about tracebloc's privacy practices, see the{" "}
            <a href="https://www.tracebloc.io/privacy">
              tracebloc Privacy Statement
            </a>
            . We'll occasionally send you account-related emails.
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default OnBoard;
