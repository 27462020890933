import { Box, Grid } from "@material-ui/core";
import React from "react";
import "./style.css";
import { TwitterIcon, LinkedInIcon } from "../Icon";
import logoFull from "assets/logo-full.svg";

const Footer = (props) => {
  return (
    <Box className="footer">
      <Box className="nav">
        <Grid container alignItems="center" justify="center" className="links">
          <Grid xs={2} item>
            <a href="https://www.tracebloc.io/">
              <img src={logoFull} alt="tracebloc" />
            </a>
          </Grid>
          <Grid style={{ marginLeft: "auto " }} item xs={1}>
            <div>
              <a href="https://www.tracebloc.io/impressum">Impressum</a>
            </div>
          </Grid>
          <Grid item xs={1}>
            <div>
              <a href="https://www.tracebloc.io/privacy">Privacy Policy</a>
            </div>
          </Grid>
          <Grid item xs={1}>
            <div>
              <a href="https://www.tracebloc.io/terms">Terms</a>
            </div>
          </Grid>
          <Grid style={{ marginLeft: "auto " }} item xs={1}>
            <a
              href="https://twitter.com/tracebloc"
              target="_blank"
              rel="noreferrer">
              <TwitterIcon style={{ fontSize: "18px", marginRight: "6px" }} />{" "}
              <span>Twitter</span>
            </a>
          </Grid>
          <Grid item xs={1}>
            <a
              href="https://www.linkedin.com/company/tracebloc/"
              target="_blank"
              rel="noreferrer">
              <LinkedInIcon style={{ fontSize: "18px", marginRight: "6px" }} />{" "}
              <span>LinkedIn</span>
            </a>
          </Grid>

          <Grid style={{ marginLeft: "auto " }} item xs={2}>
            <div>Copyright 2022 tracebloc GmbH</div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
