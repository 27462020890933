import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./style.css";
import {
  CircularProgress,
  Grid,
  IconButton,
  Portal,
  Snackbar,
} from "@material-ui/core";
import { CopyIcon, NotificationCheckIcon } from "Components/Icon";
import { Alert } from "@material-ui/lab";
import { experimentsService } from "services/api/experiments";

const CodeView = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState(null);
  const [copied, setCopied] = useState(false);

  const getCode = async () => {
    setLoading(true);
    try {
      const response = await experimentsService.getModelCode(props.experiment);
      setCode(response.data.code);
      setError(null);
    } catch (error) {
      if (error.response) {
        setError("Please make sure you are authorized.");
      } else {
        setError("Make sure you are connected to the internet.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!code && props.expanded) {
      getCode();
    }
  }, [props.expanded]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className={`codeview ${props.expanded ? "expand" : ""}`}>
      {props.expanded && code && (
        <Grid direction="row" justifyContent="space-between" container>
          <Grid style={{ width: "94%" }} item>
            <SyntaxHighlighter
              language="python"
              showLineNumbers
              style={vs2015}
              className="code-editor">
              {code}
            </SyntaxHighlighter>
          </Grid>
          <Grid item>
            <CopyToClipboard text={code} onCopy={handleCopy}>
              <IconButton>
                <CopyIcon className="copy-icon" />
              </IconButton>
            </CopyToClipboard>
          </Grid>
        </Grid>
      )}
      {loading && (
        <Grid container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}

      <Portal>
        <Snackbar
          open={copied}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}>
          <Alert
            variant="filled"
            severity="success"
            iconMapping={{
              success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
            }}
            onClose={() => setCopied(false)}>
            Code successfully copied
          </Alert>
        </Snackbar>
      </Portal>
    </div>
  );
};

export default CodeView;
