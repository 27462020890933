import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Header from "../../Components/LoginHeader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { PasswordIcon } from "Components/Icon";
import { resetPassword } from "services/api/auth";

const ResetPassword = (props) => {
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const { resetKey } = useParams();

  const updatePassword = async ({ new_password }) => {
    setLoading(true);

    try {
      const { data } = await resetPassword(new_password, resetKey);
      setError(null);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        setError("error", {
          type: "manual",
          message: error.response.statusText,
        });
      } else if (error.response && error.response.data) {
        Object.entries(error.response.data).forEach((value) => {
          if (["password"].includes(value[0])) {
            setError("error", {
              type: "manual",
              message: value[1],
            });
          }
        });
      } else {
        setError("error", {
          type: "manual",
          message: "Make sure you are connected to the internet.",
        });
      }
      setLoading(false);
    }
  };

  const onSubmit = (data) => {
    updatePassword(data);
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push({
          pathname: "/",
        });
      }, 2000);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Box>
      <Header />
      <Box className="reset-pass-main">
        <Grid className="reset-pass" container direction="column">
          {errors.error && (
            <Grid item>
              <Alert icon={false} severity="error">
                {errors.error?.message}
              </Alert>
            </Grid>
          )}

          {success && (
            <Grid item>
              <Alert icon={false} variant="filled" severity="success">
                Password updated successfully.
              </Alert>
            </Grid>
          )}
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid item>
              <h3>Reset Password</h3>
              <h3 style={{ color: "#212529", marginBottom: "12px" }}>
                Please enter your new password.
              </h3>
            </Grid>

            <Grid item>
              <TextField
                inputProps={{
                  ...register("new_password", {
                    required: "New password is required",
                    minLength: {
                      value: 8,
                      message:
                        "Your password must contain at least 8 characters.",
                    },
                  }),
                }}
                onChange={() => clearErrors()}
                inputRef={newPasswordRef}
                type={showNewPassword ? "text" : "password"}
                fullWidth={true}
                margin="normal"
                className="reset-pass-password"
                placeholder="New password"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon style={{ marginRight: "8px" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={() =>
                          setShowNewPassword(!showNewPassword)
                        }>
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.new_password && (
                <div className="error-message">
                  {errors.new_password?.message}
                </div>
              )}
            </Grid>
            <Grid item>
              <TextField
                inputProps={{
                  ...register("confirm_password", {
                    required:
                      "Please make sure your password is at least 8 characters long including a number and a lowercase letter.",
                    validate: (value) =>
                      value === newPasswordRef.current.value ||
                      "Confirm password must match New password.",
                    pattern: {
                      value: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                      message:
                        "Please make sure your password is at least 8 characters long including a number and a lowercase letter.",
                    },
                  }),
                }}
                onChange={() => clearErrors()}
                inputRef={confirmPasswordRef}
                type={showConfirmPassword ? "text" : "password"}
                fullWidth={true}
                margin="normal"
                className="reset-pass-password"
                placeholder="Confirm new password"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon style={{ marginRight: "8px" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }>
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.confirm_password && (
                <div className="error-message">
                  {errors.confirm_password?.message}
                </div>
              )}
            </Grid>

            <Grid item>
              <Button
                type="submit"
                size="large"
                variant="contained"
                className="reset-pass-button"
                color="primary"
                classes={{ label: "label" }}
                disableElevation>
                {loading ? (
                  <CircularProgress size={35} color="inherit" />
                ) : (
                  "Update"
                )}
              </Button>
            </Grid>
          </form>

          <div className="terms-of-service">
            By creating an account, you agree to the{" "}
            <a href="https://www.tracebloc.io/terms">Terms of Service</a>. For
            more information about tracebloc’s privacy practices, see the{" "}
            <a href="https://www.tracebloc.io/privacy">
              tracebloc Privacy Statement
            </a>
            . We’ll occasionally send you account-related emails.
          </div>
        </Grid>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default ResetPassword;
