import { Button } from "@material-ui/core";
import { HtmlTooltip } from "Components/Sustainability";
import React, { useEffect, useState } from "react";
import { UpvoteIcon } from "Components/Icon";
import "./style.css";

const ClapButton = (props) => {
  const [doClap, setDoClap] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDoClap(false);
    }, 300);
  }, [doClap]);

  const handleClap = () => {
    setDoClap(true);
    props.handleClap();
  };

  return (
    <div title={props.title} className={`like-button claps-container`}>
      <HtmlTooltip title="Juhu! 🙂">
        <Button
          disabled={props.disabled}
          onClick={handleClap}
          variant="outlined">
          <UpvoteIcon
            {...props.style}
            style={{ width: "11px", height: "auto" }}
            className="icon"
          />
          <span className="claps-count">+ {props.claps}</span>
        </Button>
      </HtmlTooltip>
    </div>
  );
};

export default ClapButton;
