import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Line, LineChart, ResponsiveContainer } from "recharts";

function EmptyGraph({ type }) {
  const [beat, setBeat] = useState(false);

  const accuracyData = useMemo(
    () => [
      [0, 138, 147, 144, 143, 144, 155, 144, 147, 150, 152, 147, 146, 144, 149, 142, 147, 142, 145, 152, 145, 156, 152, 147, 149],
      [0, 142, 149, 141, 144, 143, 154, 144, 147, 151, 147, 153, 147, 146, 148, 142, 149, 146, 151, 147, 142, 157, 152, 149, 144]
    ],
    []
  );

  const lossData = useMemo(
    () => [
      [160, 8, 7, 4, 3, 4, 5, 4, 7, 0, 2, 7, 6, 4, 9, 2, 7, 2, 5, 12, 5, 16, 12, 7, 9],
      [160, 2, 9, 1, 4, 3, 14, 4, 7, 11, 7, 13, 7, 6, 8, 2, 9, 6, 11, 7, 2, 17, 12, 9, 4]
    ],
    []
  );

  const updateData = useCallback(() => {
    setBeat((prevBeat) => !prevBeat);
  }, []);

  useEffect(() => {
    const intervalStatus = setInterval(updateData, 600);
    return () => clearInterval(intervalStatus);
  }, [updateData]);

  const data = type === "accuracy" ? accuracyData[beat ? 1 : 0] : lossData[beat ? 1 : 0];

  return (
    <div className="empty-graph">
      <ResponsiveContainer width="95%" height={185}>
        <LineChart margin={{ left: 30, right: 30, top: 30, bottom: 30 }} data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0%" y1="0" x2="100%" y2="0">
              <stop offset="0%" stopColor="#5dd39e" />
              <stop offset="33%" stopColor="#4bbdcc" />
              <stop offset="100%" stopColor="#3576a7" />
            </linearGradient>
          </defs>
          <Line dot={false} type="basis" dataKey={(v) => v} stroke="url(#colorUv)" />
        </LineChart>
      </ResponsiveContainer>

      {type === "accuracy" && (
        <div className="graph-message">
          Experiment has started successfully. Waiting for the first results to come in
        </div>
      )}
    </div>
  );
}

export default EmptyGraph;
