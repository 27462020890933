import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { motion } from "framer-motion/dist/framer-motion";
import { GlobalContext } from "Context/GlobalState";
import useOnScreen from "utils/useOneScreen";
import { collaborationsService } from "services/api/collaborations";

NotificationItem.propTypes = {};

const ACTIONS_LIST = {
  deny: "Deny",
  merge: "Merge",
  accept: "Accept",
  reject: "Reject",
};

const onScreenOptions = { threshold: 1.0 };
let cancelToken;

function NotificationItem(props) {
  const {
    id,
    message,
    interactable,
    read,
    notification_type: { actions },
    extra_data: { competition_id },
  } = props;
  const { updateCollaboration } = useContext(GlobalContext);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [approved, setApproved] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const itemRef = useRef();
  const isReadable = useOnScreen(itemRef, onScreenOptions);

  const handleAction = async () => {
    setLoading(true);

    try {
      await collaborationsService.performNotificationAction(
        id,
        actions[actionType].action
      );
      setLoading(false);
      setIsVisible(false);
      getCollaboration();
    } catch (error) {
      if (error.response) {
        setError("Please make sure your credentials are correct.");
      } else {
        setError("Make sure you are connected to the internet.");
      }
      setLoading(false);
    }
  };

  const handleActionClick = (isApproved, actionType) => {
    if (isApproved) {
      setConfirm(true);
    } else {
      setConfirm(false);
      setApproved(true);
    }
    setActionType(actionType);
  };

  useEffect(() => {
    if (approved) {
      handleAction();
    }
  }, [approved]);

  const getCollaboration = async () => {
    try {
      const response = await collaborationsService.getSingleCollaboration(
        competition_id
      );
      updateCollaboration({ ...response.data });
    } catch (error) {
      console.log("CATCH", error);
    }
  };

  const updateNotification = async () => {
    try {
      await collaborationsService.updateNotification(id, { read: true });
    } catch (error) {
      console.log("CATCH", error);
    }
  };

  useEffect(() => {
    if (isReadable && !read) {
      updateNotification();
    }
  }, [isReadable]);

  return (
    isVisible && (
      <motion.div
        ref={itemRef}
        initial={{ scale: 0.7 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        data-testid={`notification-${id}`}
        className="notifications-tooltip">
        <Box>
          <Box
            style={{ opacity: read ? "0.8" : "1.0" }}
            dangerouslySetInnerHTML={{ __html: message }}></Box>

          {!loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "24px",
              }}>
              {actions["Deny"] && (
                <Box
                  role="deny-button"
                  onClick={() =>
                    interactable
                      ? handleActionClick(false, ACTIONS_LIST.deny)
                      : null
                  }
                  className="notification-button"
                  style={{
                    marginRight: "14px",
                    borderColor: "#E2E2E2",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "30px",
                    padding: "8px 12px",
                    width: "60px",
                    textAlign: "center",
                    cursor: interactable ? "pointer" : "default",
                  }}>
                  <Typography
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                    }}>
                    Deny
                  </Typography>
                </Box>
              )}

              {actions["Reject"] && (
                <Box
                  onClick={() =>
                    interactable
                      ? handleActionClick(false, ACTIONS_LIST.reject)
                      : null
                  }
                  className="notification-button"
                  style={{
                    marginRight: "14px",
                    borderColor: "#E2E2E2",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "30px",
                    padding: "8px 12px",
                    width: "60px",
                    textAlign: "center",
                    cursor: interactable ? "pointer" : "default",
                  }}>
                  <Typography
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                    }}>
                    Reject
                  </Typography>
                </Box>
              )}

              {actions["Merge"] && (
                <Box
                  role="merge-button"
                  className="notification-button"
                  onClick={() =>
                    interactable
                      ? handleActionClick(true, ACTIONS_LIST.merge)
                      : null
                  }
                  style={{
                    borderColor: "#E2E2E2",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "30px",
                    padding: "8px 12px",
                    width: "70px",
                    textAlign: "center",
                    cursor: interactable ? "pointer" : "default",
                    background:
                      "transparent linear-gradient(119deg, rgba(75, 189, 204, 1) 0%, rgba(35, 58, 135, 1) 100%) 0% 0% no-repeat padding-box",
                  }}>
                  <Typography
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                      color: "#FFFFFF",
                    }}>
                    Merge
                  </Typography>
                </Box>
              )}

              {actions["Accept"] && (
                <Box
                  className="notification-button"
                  onClick={() =>
                    interactable
                      ? handleActionClick(true, ACTIONS_LIST.accept)
                      : null
                  }
                  style={{
                    // marginRight: "16px",
                    borderColor: "#E2E2E2",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "30px",
                    padding: "8px 12px",
                    width: "70px",
                    textAlign: "center",
                    cursor: interactable ? "pointer" : "default",
                    background:
                      "transparent linear-gradient(119deg, rgba(75, 189, 204, 1) 0%, rgba(35, 58, 135, 1) 100%) 0% 0% no-repeat padding-box",
                  }}>
                  <Typography
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                      color: "#FFFFFF",
                    }}>
                    Accept
                  </Typography>
                </Box>
              )}
            </div>
          )}

          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "24px",
              }}>
              <CircularProgress size={20} />
            </div>
          )}
        </Box>
        {confirm && (
          <Box className="notifications-confirm">
            <Typography
              style={{
                fontStyle: "italic",
                color: "#3576A7",
                fontSize: "12px",
                fontWeight: "500",
              }}>
              Are you sure?
            </Typography>

            <Typography
              style={{
                color: "#3576A7",
                fontSize: "10px",
                fontStyle: "italic",
                width: "200px",
                marginTop: "24px",
              }}>
              Once a team user or team has accepted, teams can not be separated
              again. All experiments of the users will be merged and are visible
              to every team member.
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "24px",
              }}>
              <Box
                className="notification-button"
                onClick={() => setConfirm(false)}
                style={{
                  marginRight: "14px",
                  borderColor: "#E2E2E2",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderRadius: "30px",
                  padding: "8px 12px",
                  minWidth: "60px",
                  textAlign: "center",
                  cursor: "pointer",
                }}>
                <Typography
                  style={{
                    fontStyle: "italic",
                    fontSize: "12px",
                  }}>
                  Cancel
                </Typography>
              </Box>
              <Box
                className="notification-button"
                onClick={() => {
                  setConfirm(false);
                  setApproved(true);
                }}
                style={{
                  // marginRight: "16px",
                  borderColor: "#E2E2E2",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderRadius: "30px",
                  padding: "8px 12px",
                  minWidth: "70px",
                  textAlign: "center",
                  cursor: "pointer",
                  background:
                    "transparent linear-gradient(119deg, rgba(75, 189, 204, 1) 0%, rgba(35, 58, 135, 1) 100%) 0% 0% no-repeat padding-box",
                }}>
                <Typography
                  style={{
                    fontStyle: "italic",
                    fontSize: "12px",
                    color: "#FFFFFF",
                  }}>
                  Yes
                </Typography>
              </Box>
            </div>
          </Box>
        )}
      </motion.div>
    )
  );
}

export default NotificationItem;
