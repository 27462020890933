import { Box, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "./style.css";
import logoFull from "assets/logo-white.svg";
import { GlobalContext } from "Context/GlobalState";

const LoginHeader = (props) => {
  const { user } = useContext(GlobalContext);
  let history = useHistory();
  return (
    <Box>
      <Box className="navigation">
        <Grid className="nav" container>
          <a href="https://www.tracebloc.io/" className="logo">
            <img src={logoFull} alt="tracebloc" />
          </a>
          {!user && history.location.pathname !== "/" && (
            <a href="/">
              <span>Already have an account?</span>
              <ArrowForwardIcon />
            </a>
          )}
          {!user && history.location.pathname === "/" && (
            <a href="/signup">
              <span>New to tracebloc?</span>
              <ArrowForwardIcon />
            </a>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginHeader;
