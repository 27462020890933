import React, { useContext, useState } from "react";
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import German from "./German";

import { GlobalContext } from "Context/GlobalState";
import English from "./English";
import CollaborationSpecificRules from "./CollaborationSpecificRules";

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 51,
    maxWidth: "75px",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "6px 2px",
    textAlign: "left",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: "22px",
    fontFamily: "Roboto",
    fontStyle: "italic",
    "&:hover": {
      color: "var(--primary)",
      opacity: 1,
    },
    "&$selected": {
      color: "var(--primary)",
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: "var(--primary)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ paddingLeft: "10px", width: "100%" }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    marginTop: "24px",
    "& a": {
      color: "var(--primary)",
      textDecoration: "none",
    },
    "& pre": {
      fontFamily:
        "Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L , Monaco, Courier New ,Courier, monospace",
      whiteSpace: "pre-wrap",
      overflowX: "auto",
      tabWidth: 4,
      background: "rgba(0,0,0, 0.6)",
      color: "white",
      padding: 20,
      borderRadius: 5,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "90px",
  },
  flexContainerVertical: {
    alignItems: "flex-start",
  },
}));

export default function Rules(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const { joinCollaboration } = useContext(GlobalContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleJoinCollaboration = () => {
    const token = localStorage.getItem("_key_usr_tkn");

    if (!token) {
      const index = location.pathname.indexOf("/details");
      const redirectPath = location.pathname.substring(
        0,
        index + "/details".length
      );

      history.push({
        pathname: "/",
        state: {
          redirect_to: `${redirectPath}/rules`,
        },
      });
      return false;
    }

    if (!props.loading) {
      joinCollaboration(props.dataset);
    }
  };

  return (
    <Box
      style={{
        marginTop: "48px",
      }}>
      {!props.is_already_participant && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography
            style={{
              color: "#707070",
              letterSpacing: "0px",
              textAlign: "center",
              fontSize: "16px",
              maxWidth: "514px",
            }}>
            By clicking on the „I understand and Accept“-Button below you agree
            to be bound by the collaboration rules
          </Typography>
          <Box
            onClick={handleJoinCollaboration}
            component={"button"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              width: "150px",
              height: "29px",
              backgroundColor: "#3576A7",
              borderColor: "#4576A3",
              BorderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "30px",
              marginTop: "26px",
            }}>
            {props.loading ? (
              <CircularProgress size={20} style={{ color: "#fff" }} />
            ) : (
              <Typography
                style={{
                  color: "#EDF7FA",
                  fontSize: "10px",
                  textAlign: "center",
                  letterSpacing: "0px",
                  textTransform: "uppercase",
                }}>
                I UNDERSTAND & ACCEPT
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <div className={`${classes.root}`}>
        <Tabs
          indicatorColor="primary"
          orientation="vertical"
          variant="standard"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          classes={{ flexContainerVertical: classes.flexContainerVertical }}>
          <AntTab label="Specific Collaboration Rules" {...a11yProps(0)} />
          <AntTab label="General Collaboration Rules" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <CollaborationSpecificRules
            rules={props.competition_rules_template}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <English />
          <br />
          <br />

          <German />
        </TabPanel>
      </div>
    </Box>
  );
}
