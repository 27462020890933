import axios from "axios";
import { API_URL, getAuthConfig } from "./config";

export const collaborationsService = {
  getCollaborations: async (page) => {
    return axios.get(
      `${API_URL}/competition/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },

  joinCollaboration: async (dataset) => {
    return axios.patch(
      `${API_URL}/competition/${dataset}/join_competition/`,
      null,
      getAuthConfig()
    );
  },

  getNotifications: async (cancelToken) => {
    return axios.get(`${API_URL}/notifications/`, {
      ...getAuthConfig(),
      cancelToken,
    });
  },

  getSingleCollaboration: async (competitionId) => {
    return axios.get(
      `${API_URL}/competition/${competitionId}/`,
      getAuthConfig()
    );
  },

  updateNotification: async (notificationId, data) => {
    return axios.patch(
      `${API_URL}/notifications/${notificationId}/update/`,
      data,
      getAuthConfig()
    );
  },

  performNotificationAction: async (notificationId, action) => {
    console.log(notificationId, action);
    return axios.post(
      `${API_URL}/notifications/${notificationId}/${action}`,
      null,
      getAuthConfig()
    );
  },

  getLeaderboardSummary: async (datasetId) => {
    return axios.get(
      `${API_URL}/leaderboard/${datasetId}/summary/`,
      getAuthConfig()
    );
  },

  getNotebookJson: async (jsonUrl) => {
    return axios.get(jsonUrl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getMySubmissions: async (dataset, page) => {
    return axios.get(
      `${API_URL}/inference/${dataset}/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },
  getFinalSubmissions: async (dataset) => {
    return axios.get(`${API_URL}/submit/final/${dataset}/`, getAuthConfig());
  },

  submitFinalSubmission: async (dataset, data, submissionId = "") => {
    return axios.post(
      `${API_URL}/submit/final/${dataset}/${submissionId}`,
      data,
      getAuthConfig()
    );
  },

  deleteFinalSubmission: async (dataset, submissionId) => {
    return axios.delete(
      `${API_URL}/submit/final/${dataset}/${submissionId}/`,
      getAuthConfig()
    );
  },

  getLeaderboardEntries: async (dataset, page) => {
    return axios.get(
      `${API_URL}/leaderboard/${dataset}/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },

  updateFlopsConfiguration: async (dataset, flopsLimit) => {
    const formData = new FormData();
    formData.append("flops", flopsLimit * 1e12);
    return axios.patch(
      `${API_URL}/competition/${dataset}/`,
      formData,
      getAuthConfig()
    );
  },

  updateConfiguration: async (dataset, flopsLimit, description, edaPdf) => {
    const formData = new FormData();
    formData.append("flops", flopsLimit * 1e12);
    formData.append("description", description);
    if (edaPdf) formData.append("eda_pdf", edaPdf);
    return axios.patch(
      `${API_URL}/competition/${dataset}/`,
      formData,
      getAuthConfig()
    );
  },

  toggleExperimentControl: async (dataset, allowExperiments) => {
    return axios.post(
      `${API_URL}/competition/${dataset}/admin_experiment_control/`,
      { allow_experiments: allowExperiments },
      getAuthConfig()
    );
  },

  inviteUsers: async (dataset, emails) => {
    return axios.patch(
      `${API_URL}/competition/${dataset}/admin_invite/`,
      { emails },
      getAuthConfig()
    );
  },

  deleteUser: async (dataset, email) => {
    return axios.patch(
      `${API_URL}/competition/${dataset}/admin_uninvite/`,
      { emails: email },
      getAuthConfig()
    );
  },

  updateCompetitionRules: async (dataset, competitionRules) => {
    const formData = new FormData();
    formData.append("competition_rules_template", competitionRules);
    return axios.patch(
      `${API_URL}/competition/${dataset}/`,
      formData,
      getAuthConfig()
    );
  },

  updateCollaborationDates: async (dataset, dates) => {
    const formData = new FormData();
    Object.entries(dates).forEach(([key, value]) => {
      formData.append(key, value.toISOString());
    });
    return axios.patch(
      `${API_URL}/competition/${dataset}/`,
      formData,
      getAuthConfig()
    );
  },

  updatePrivacyType: async (dataset, privacyType) => {
    const formData = new FormData();
    formData.append("privacy_type", privacyType);
    return axios.patch(
      `${API_URL}/competition/${dataset}/`,
      formData,
      getAuthConfig()
    );
  },
};

export const inviteToDataset = async (email, datasetId) => {
  const config = {
    method: "POST",
    url: `${API_URL}/invitations/invite/`,
    data: { email, datasets: [datasetId] },
    ...getAuthConfig(),
  };

  return axios(config);
};

export const inviteToTeam = async (data, teamId) => {
  const isEmail = data.includes("@");
  const key = isEmail ? "email" : "merger_team";

  return await axios.post(
    `${API_URL}/invitations/team-invite/`,
    { [key]: data, team: teamId },
    getAuthConfig()
  );
};

export const updateTeamName = async (teamId, newName) => {
  return await axios.patch(
    `${API_URL}/team/${teamId}/`,
    { name: newName },
    getAuthConfig()
  );
};
