import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  YAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";

import "./style.css";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { colorGradient } from "utils";

const GRADIENT_COLOR = {
  min: { red: 255, green: 255, blue: 255 },
  mid: { red: 121, green: 212, blue: 224 },
  max: { red: 53, green: 118, blue: 167 },
};

const IntentRow = ({ intents, confusionMatrix, intent, range }) => {
  return intents.map((key) => {
    const value = confusionMatrix[intent]?.[key] || 0;
    const { max, min } = range;
    const decrease = (max - value) / max;

    return (
      <td
        height={96}
        style={{
          backgroundColor: value
            ? colorGradient(decrease, GRADIENT_COLOR.max, GRADIENT_COLOR.mid, GRADIENT_COLOR.min)
            : GRADIENT_COLOR.min,
          color: decrease < 0.66 ? "#ffffff" : "#707070",
        }}
        className="numbers"
        key={key}
      >
        {value}
      </td>
    );
  });
};

const renderLegend = ({ payload }) => (
  <div>
    {payload.map((entry, index) => (
      <span
        style={{
          fontSize: "12px",
          lineHeight: "14px",
          fontWeight: "500",
          fontFamily: "Roboto",
          color: "#707070",
        }}
        key={`item-${index}`}
      >
        Images
      </span>
    ))}
  </div>
);

const ConfusionMatrix = ({ confusionMatrix: cmProp, expanded, cycleNo }) => {
  const [confusionMatrix, setConfusionMatrix] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof cmProp === "string" && cmProp) {
      setLoading(true);
      try {
        setConfusionMatrix(JSON.parse(cmProp));
      } catch (error) {
        console.error("Failed to parse confusion matrix:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [expanded, cycleNo, cmProp]);

  const renderConfusionMatrixTable = () => {
    if (!confusionMatrix) return null;

    const intents = Object.keys(confusionMatrix.cm || {});
    const cmTableHeader = intents.map((key) => (
      <td key={key}>
        <div>
          <span>{key}</span>
        </div>
      </td>
    ));

    return (
      <Grid container>
        <Grid item>
          <table className="confusion-matrix">
            <tbody>
              {intents.map((key, index) => (
                <tr height={96} key={key} className="intent-cm-row">
                  {index === 0 && (
                    <td align="center" className="true-label" rowSpan={intents.length}>
                      <h4>True Label</h4>
                    </td>
                  )}
                  <td className="intent-label">{key}</td>
                  <IntentRow
                    intent={key}
                    intents={intents}
                    confusionMatrix={confusionMatrix.cm}
                    range={confusionMatrix.range || {}}
                  />
                  {index === 0 && (
                    <td className="graph-column" rowSpan={intents.length}>
                      <ResponsiveContainer width={100} height={400}>
                        <BarChart
                          width={100}
                          height={290}
                          data={[confusionMatrix.range || {}]}
                          barGap={0}
                          barCategoryGap={0}
                          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorGradient"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="100%"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop
                                offset="0"
                                stopColor={`rgb(${GRADIENT_COLOR.max.red}, ${GRADIENT_COLOR.max.green}, ${GRADIENT_COLOR.max.blue})`}
                              />
                              <stop
                                offset="0.5"
                                stopColor={`rgb(${GRADIENT_COLOR.mid.red}, ${GRADIENT_COLOR.mid.green}, ${GRADIENT_COLOR.mid.blue})`}
                              />
                              <stop
                                offset="1"
                                stopColor={`rgb(${GRADIENT_COLOR.min.red}, ${GRADIENT_COLOR.min.green}, ${GRADIENT_COLOR.min.blue})`}
                              />
                            </linearGradient>
                          </defs>
                          <YAxis
                            axisLine={false}
                            tickLine={false}
                            domain={[0, "dataMax"]}
                            orientation="right"
                            tick={{
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              lineHeight: "14px",
                              fontStyle: "italic",
                              color: "#707070",
                            }}
                          />
                          <Legend verticalAlign="top" height={40} align="center" content={renderLegend} />
                          <Bar className="bar-grad" dataKey="max" fill="url(#colorGradient)" />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>
                  )}
                </tr>
              ))}
              <tr className="intent-header">
                <td></td>
                <td></td>
                {cmTableHeader}
              </tr>
              <tr>
                <td className="predicted-label" align="center" colSpan={intents.length + 3}>
                  <h4 style={{ marginBottom: 0, textAlign: "center" }}>Predicted Label</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={`confusion-matrix-container ${expanded ? "expand" : ""}`}>
      <Box mb={4}></Box>
      {expanded && confusionMatrix && (
        <Grid container alignItems="center">
          <Grid xs={8} item>
            {renderConfusionMatrixTable()}
          </Grid>
        </Grid>
      )}
      {expanded && !confusionMatrix && !loading && (
        <Grid container justifyContent="center">
          <h3>Confusion matrix not ready yet</h3>
        </Grid>
      )}
      {loading && (
        <Grid container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
    </div>
  );
};

export default ConfusionMatrix;
