import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./style.css";
import { Box, CircularProgress, Grid, Tooltip } from "@material-ui/core";

import MySubmissionsRow from "./MySubmissionsRow";

import useStickyHeader from "utils/useStickyHeader";
import PlaceHolderHelp from "Components/PlaceHolderHelp";
import { collaborationsService } from "services/api/collaborations";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "#",
    description: "",
    leftAlign: true,
  },
  {
    id: "experiment_id",
    numeric: false,
    disablePadding: false,
    label: "Experiment",
    description: "Experiment ID",
    leftAlign: true,
  },
  {
    id: "cycle",
    numeric: false,
    disablePadding: false,
    label: "Cycle",
    description: "Experiment submitted at cycle",
    leftAlign: true,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    description: "Date of Submission​",
    leftAlign: true,
  },
  {
    id: "accuracy",
    numeric: false,
    disablePadding: false,
    label: "Accuracy",
    description: "",
    leftAlign: true,
  },
  {
    id: "loss",
    numeric: false,
    disablePadding: false,
    label: "Loss",
    description: "",
    leftAlign: true,
  },
  {
    id: "model_parameters",
    numeric: false,
    disablePadding: false,
    label: "Size (Params / Bytes)",
    description: "Size of the model in parameters and in bytes",
    leftAlign: true,
  },
  {
    id: "inference_flopsutilised",
    numeric: false,
    disablePadding: false,
    label: "Energy Consumption (FLOP / Rel %)",
    description:
      "Average energy consumption per inference in FLOP and relative energy consumption compared to the baseline (leading model)",
  },
  {
    id: "gco2",
    numeric: true,
    disablePadding: false,
    label: "CO2e",
    description: "Energy consumption in grams or milligrams of CO2 equivalent",
  },
  {
    id: "submit",
    numeric: true,
    disablePadding: false,
    label: "Final Submission",
    description: "Submitted to Leaderboard",
  },
];

function EnhancedTableHead(props) {
  const { scoreFormula } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "default"}
              className={headCell.id}
              style={{}}>
              {headCell.id === "code" ? (
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        Benchmarked Model results <br />
                        <br />
                      </div>
                      <div>
                        At the end of the competition
                        <br />
                        the Model which make it into
                        <br />
                        the money will be shared
                        <br />
                        with the Host.
                      </div>
                    </React.Fragment>
                  }>
                  <span>{headCell.label}</span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`${
                    headCell.id === "score"
                      ? scoreFormula
                      : headCell.description
                  }`}>
                  <span>{headCell.label}</span>
                </Tooltip>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const MySubmissions = (props) => {
  const classes = useStyles();
  const { dataset } = props;
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [leaderboard, setLeaderBoard] = useState([]);
  const [next, setNext] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { componentRef, isBottom, coords } = useStickyHeader();

  // FInal Submision vars
  const [submissions, setSubmissions] = useState([]);

  const getLeaderBoardEntries = async (page) => {
    setLoading(true);
    try {
      const response = await collaborationsService.getMySubmissions(
        props.dataset,
        page
      );
      const data = response.data;
      setLeaderBoard([...leaderboard, ...data.results]);
      setNext(data.next);
      setLoading(false);
      setError(null);
    } catch (error) {
      if (error.response) {
        setError("Please make sure your credentials are correct.");
      } else {
        setError("Make sure you are connected to the internet.");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (leaderboard.length === 0) {
      getLeaderBoardEntries();
    }
    if (leaderboard.length > 0 && next) {
      getLeaderBoardEntries(page);
    }
  }, [page]);

  useEffect(() => {
    const allZero = Object.values(coords).every((value) => value === 0);
    if ((isBottom || allZero) && next) {
      const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      setPage(params.page);
    }
  }, [isBottom, next]);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, leaderboard.length - page * rowsPerPage);

  const getFinalScoreStatus = () => {
    if (leaderboard.length > 0) {
      if (leaderboard[0]?.final_score) {
        return true;
      }
    }
    return false;
  };

  // --------------------------------------------
  // Final Submissions
  // --------------------------------------------

  const getSubmission = async () => {
    try {
      const response = await collaborationsService.getFinalSubmissions(dataset);
      const data = response.data;
      if (data?.results) {
        setSubmissions([...data.results]);
      }
    } catch (error) {
      if (error.response) {
        setError("Please make sure your credentials are correct.");
      } else {
        setError("Make sure you are connected to the internet.");
      }
    }
  };

  useEffect(() => {
    getSubmission();
  }, []);

  const deleteSubmission = (submission) => {
    const newSubs = submissions.filter((item) => item.id !== submission);
    setSubmissions([...newSubs]);
  };

  const addSubmission = (submission) => {
    if (submission?.id) {
      setSubmissions([...submissions, submission]);
    }
  };

  return leaderboard.length > 0 ? (
    <Box pt={1}>
      <TableContainer
        TableContainer
        ref={componentRef}
        style={{ marginTop: "8px", maxHeight: "calc(100vh - 262px)" }}
        className="table-conatainer">
        <Table
          stickyHeader
          className="my-submissions-table"
          aria-label="sticky simple table">
          <EnhancedTableHead
            scoreFormula={props.score_formula_display}
            final_score={getFinalScoreStatus()}
            classes={classes}
            numSelected={selected.length}
            rowCount={leaderboard.length}
          />
          <TableBody>
            {leaderboard.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <MySubmissionsRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  totalEntries={leaderboard.length}
                  position={index + 1}
                  baseEnergyConsumption={leaderboard[0].flopsutilised}
                  totalPrizes={props.prizes.length}
                  selected={isItemSelected}
                  is_private={props.is_private}
                  key={row.id}
                  labelId={labelId}
                  dataset={props.dataset}
                  row={row}
                  finalSubmission={submissions.filter(
                    (item) =>
                      item.cycle === row.cycle &&
                      item.experiment_key === row.experiment_key
                  )}
                  totalFinalSubmissions={submissions.length}
                  hostCompany={props?.host_company}
                  addSubmission={addSubmission}
                  deleteSubmission={deleteSubmission}
                />
              );
            })}

            {loading ? (
              <TableRow>
                <TableCell className="empty-td" colSpan={7}>
                  <Grid container justify="center" alignItems="center">
                    <CircularProgress />
                  </Grid>
                </TableCell>
              </TableRow>
            ) : null}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell className="empty-td" colSpan={7}>
                  &nbsp;
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <Grid
      style={{ padding: "24px" }}
      container
      justify="center"
      alignItems="center">
      {loading ? <CircularProgress /> : <PlaceHolderHelp />}
    </Grid>
  );
};

export default MySubmissions;
