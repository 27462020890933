import React, { createContext, useReducer } from "react";
import {
  setDatasetOptions,
  setDataset,
  setCreatingDataset,
  setErrorMessage,
  setSideBar,
  setUserLogin,
  saveDatasetInDB,
  loadingDatasets,
  loadDatasets,
  loadExperiments,
  loadingExperiments,
  setMedicalDataset,
  loadingCollaborations,
  loadCollaborations,
  archiveDataset,
  setUpdateExperimentsStatus,
  loadArchivedExperiments,
  loadingArchivedExperiments,
  unArchiveDataset,
  setAutonomousDataset,
  setJoinCollaborations,
} from "./Actions";
import AppReducer from "./AppReducer";
import {
  authService,
  datasetService,
  experimentsService,
  collaborationsService,
  autonomousService,
} from "services/api";
import { API_URL as API_URL_CONFIG } from "services/api/config";

const initialState = {
  sidebar: {
    open: false,
  },
  datasetOptions: {},
  dataset: {
    loading: false,
    list: [],
    count: null,
    groups: [],
    total: 2031 + (Math.floor(Math.random() * 60) + 9), // this should ideally come from backend
    ok: 0,
    nok: 0,
    amplify: 1,
    saved: null,
  },
  datasets: { loading: false, count: 0, list: [], prev: null, next: null }, // list of logged in user datasets
  experiments: { loading: false, count: 0, list: [], prev: null, next: null }, // list of logged in user datasets
  archivedExperiments: {
    loading: false,
    count: 0,
    list: [],
    prev: null,
    next: null,
  }, // list of logged in user archived datasets
  competitions: { loading: false, count: 0, list: [], prev: null, next: null }, // list of logged in user datasets
  user: null, // {name: 'tracebloc'},
  error: null, // error message return by a failed network response
  network: false, // If True, the network request is in progress
};

export const GlobalContext = createContext(initialState);
export const API_URL = API_URL_CONFIG;
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const openSideBar = (value) => {
    dispatch(setSideBar(value));
  };

  function login({ username, password, consent }) {
    authService
      .login({ username, password, consent })
      .then((res) => {
        const user = { ...res.data };
        localStorage.setItem("_key_usr_tkn", user.token);
        delete user.token;
        console.log(user);
        dispatch(setUserLogin(user));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          if (error.response.status === 401 && error.response.data) {
            dispatch(setErrorMessage({ error: error.response.data.message }));
          }

          if (error.response.status === 400) {
            dispatch(
              setErrorMessage({
                error: "Please make sure your credentials are correct.",
              })
            );
          }
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
      });
    //dispatch(setUserLogin({ id: 1, name: 'Dr.Berhnard', location: 'Berlin', designation: 'Data Scientist' }));
  }

  const logout = () => {
    authService
      .logout()
      .then(() => {
        window.location.reload(false);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
      });
  };

  const setUserUpdate = (user) => dispatch(setUserLogin(user));

  function getUserInfo() {
    authService
      .getUserInfo()
      .then((res) => {
        const user = { ...res.data };
        dispatch(setUserLogin(user));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          if (error.response.status === 401 && error.response.data) {
            dispatch(setErrorMessage({ error: error.response.data.detail }));
            console.log(error.response.data.detail);
            if (error.response.data.detail === "Invalid token.") {
              localStorage.removeItem("_key_usr_tkn");
              window.location = "/";
            }
          } else {
            dispatch(
              setErrorMessage({
                error: "Please make sure your credentials are correct.",
              })
            );
          }
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
      });
  }
  /// this method is used for storing all the dataset configurations
  const createDatasetOptions = (oneOption) => {
    dispatch(setDatasetOptions(oneOption));
  };

  const createDataSet = (params) => {
    dispatch(setCreatingDataset(true));
    datasetService
      .createDataset(params)
      .then((res) => {
        const dataset = { ...res.data };
        dispatch(
          setDataset({
            count: dataset.count,
            dataset_type: dataset.type,
            groups: dataset.grouped_defect_data,
            ok: dataset.ok,
            nok: dataset.nok,
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(setCreatingDataset(false));
      });
  };

  const createMedicalDataSet = (params) => {
    dispatch(setCreatingDataset(true));
    datasetService
      .createMedicalDataset(params)
      .then((res) => {
        const dataset = { ...res.data };
        dispatch(
          setMedicalDataset({
            description: dataset.description,
            label_density: dataset.label_density,
            data_items_label_count: dataset.data_items_label_count,
            unique_data_items_count: dataset.unique_data_items_count,
            count: dataset.count,
            data_samples: dataset.sample_images,
            dataset_type: dataset.type,
            groups: dataset.groupedDefectData,
            ok: dataset.ok,
            nok: dataset.nok,
          })
        );
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(setCreatingDataset(false));
      });
  };

  const createAutonomousDataSet = (params) => {
    dispatch(setCreatingDataset(true));
    autonomousService
      .createAutonomousDataset(params)
      .then((res) => {
        const dataset = { ...res.data };
        dispatch(
          setAutonomousDataset({
            description: dataset.description,
            label_density: dataset.label_density,
            data_items_label_count: dataset.data_items_label_count,
            unique_data_items_count: dataset.unique_data_items_count,
            count: dataset.count,
            data_samples: dataset.sample_images,
            dataset_type: dataset.type,
            groups: dataset.groupedDefectData,
            ok: dataset.ok,
            nok: dataset.nok,
          })
        );
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(setCreatingDataset(false));
      });
  };

  const saveDataset = (title, imageSamples, dataset_type, access_type) => {
    datasetService
      .saveDataset(title, imageSamples, dataset_type, access_type)
      .then((res) => {
        const dataset = { ...res.data };
        console.log("DATASET-SAVED", dataset);
        dispatch(saveDatasetInDB(dataset));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
      });
  };

  const getUserDatasets = (page) => {
    dispatch(loadingDatasets(true));
    datasetService
      .getUserDatasets(page)
      .then((res) => {
        const datasets = { ...res.data };
        dispatch(loadDatasets(datasets));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(loadingDatasets(false));
      });
  };

  const getUserExperiments = (page) => {
    dispatch(loadingExperiments(true));
    experimentsService
      .getUserExperiments(page)
      .then((res) => {
        const datasets = res.data;
        console.log("DATASETS-LIST", datasets);
        dispatch(loadExperiments(datasets));
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(loadingExperiments(false));
      });
  };

  const getUserArchivedExperiments = (page) => {
    dispatch(loadingArchivedExperiments(true));
    experimentsService
      .getUserArchivedExperiments(page)
      .then((res) => {
        const datasets = res.data;
        dispatch(loadArchivedExperiments(datasets));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(loadingArchivedExperiments(false));
      });
  };

  const updateExperimentsStatus = (datasets) => {
    experimentsService
      .updateExperimentsStatus(datasets)
      .then((res) => {
        const data = res.data;
        dispatch(setUpdateExperimentsStatus(data.datasets));
        dispatch(setUserLogin({ ...data.flops_info }));
      })
      .catch((error) => {
        console.log("CATCH", error.response);
      });
  };

  const emptyUserExperiments = () => {
    loadExperiments({ count: 0, list: [], prev: null, next: null });
  };

  const updateArchiveDataset = (dataset) => {
    dispatch(archiveDataset(dataset));
  };

  const updateUnarchiveDataset = (dataset) => {
    console.log("updateUnarchiveDataset", "called");
    dispatch(unArchiveDataset(dataset));
  };

  const getCollaborations = (page) => {
    dispatch(loadingCollaborations(true));
    collaborationsService
      .getCollaborations(page)
      .then((res) => {
        const competitions = res.data;
        console.log("competitions-LIST", competitions);
        dispatch(loadCollaborations(competitions));
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          dispatch(
            setErrorMessage({
              error: "Please make sure your credentials are correct.",
            })
          );
        } else {
          dispatch(
            setErrorMessage({
              error: "Make sure you are connected to the internet.",
            })
          );
        }
        dispatch(loadingCollaborations(false));
      });
  };

  const updateCollaboration = (data) => {
    dispatch(setJoinCollaborations(data));
  };

  const joinCollaboration = (dataset) => {
    collaborationsService
      .joinCollaboration(dataset)
      .then((res) => {
        const data = res.data;
        console.log("joinCollaboration", data);
        dispatch(
          setJoinCollaborations({
            ...data,
            loading: false,
          })
        );
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          // setError("Please make sure your credentials are correct.")
        } else {
          // setError("Make sure you are connected to the internet.")
        }
        dispatch(
          setJoinCollaborations({
            dataset: dataset,
            loading: false,
          })
        );
      });
  };

  return (
    <GlobalContext.Provider
      value={{
        state,
        user: state.user,
        sidebar: state.sidebar,
        getUserInfo,
        getUserDatasets,
        updateArchiveDataset,
        updateUnarchiveDataset,
        getUserExperiments,
        getUserArchivedExperiments,
        updateExperimentsStatus,
        emptyUserExperiments,
        getCollaborations,
        joinCollaboration,
        updateCollaboration,
        login,
        logout,
        setUserUpdate,
        openSideBar,
        createDataSet,
        createMedicalDataSet,
        createAutonomousDataSet,
        saveDataset,
        createDatasetOptions,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
