import {
  Button,
  ClickAwayListener,
  Grid,
  Portal,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { ColabIcon, NotificationCheckIcon } from "Components/Icon";
import { GlobalContext } from "Context/GlobalState";
import { Alert } from "@material-ui/lab";
import Notifications from "Components/Notifications";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

function Header(props) {
  const { user } = useContext(GlobalContext);
  const [outOfFlops, setOutOfFlops] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  let history = useHistory();

  const handleNotifications = (status) => {
    setOpenNotifications(status);
  };

  const handleColabClick = () => {
    const url =
      "https://colab.research.google.com/drive/1N00idtpoaq1lk9OJE6g4bMqd8o-Qex2C?usp=sharing";
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (user?.total_available_flops < 10) {
      showOutOfFlopsMessage();
    }
  }, [user?.total_available_flops]);

  const showOutOfFlopsMessage = (e) => {
    setOutOfFlops(true);
    setTimeout(() => {
      setOutOfFlops(false);
    }, 5000);
  };

  return (
    <>
      <Grid className="global-header" container direction="row">
        {user && (
          <ClickAwayListener onClickAway={() => handleNotifications(false)}>
            <Grid
              style={{ marginLeft: "auto" }}
              item
              data-testid="notifications-container">
              <Notifications
                data-testid="notifications-popup"
                open={openNotifications}
                handleNotifications={handleNotifications}
              />
            </Grid>
          </ClickAwayListener>
        )}

        {!user && (
          <Grid style={{ marginLeft: "auto" }} item>
            <div className="nav">
              {!user && history.location.pathname !== "/" && (
                <a href="/">
                  <span>Login</span>
                  <ArrowForwardIcon />
                </a>
              )}
            </div>
          </Grid>
        )}

        <Grid item>
          <Tooltip title={`Start training with Google Colab​`}>
            <Button
              className="colab-button"
              endIcon={<ColabIcon style={{ width: "20px" }} />}
              onClick={handleColabClick}
              variant="outlined">
              Train your model
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <Portal>
        {outOfFlops && (
          <Snackbar
            open={outOfFlops}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <Alert
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              variant="filled"
              severity="warning">
              You have consumed your alocated flops.
            </Alert>
          </Snackbar>
        )}
      </Portal>
    </>
  );
}

export default Header;
