import axios from "axios";

let SERVER = "https://xray-json-server.azurewebsites.net/";
let API = "https://api.tracebloc.io";
let SAMPLES_IMAGES =
  "https://tracebloc-production-s3-bucket.s3.eu-central-1.amazonaws.com/xrayimagedata/xrayimages/";

if (
  process?.env?.REACT_APP_MY_ENVIRONMENT === "development" ||
  process?.env?.NODE_ENV === "development"
) {
  SERVER = "https://xray-json-server.azurewebsites.net/";
  API = "https://dev-api.tracebloc.io";
  SAMPLES_IMAGES =
    "https://tracebloc-develop-s3-bucket.s3.eu-central-1.amazonaws.com/xrayimagedata/xrayimages/";
}

if (process?.env?.REACT_APP_MY_ENVIRONMENT === "staging") {
  SERVER = "https://xray-json-server.azurewebsites.net/";
  API = "https://stg-api.tracebloc.io";
  SAMPLES_IMAGES =
    "https://tracebloc-staging-s3-bucket.s3.eu-central-1.amazonaws.com/xrayimagedata/xrayimages/";
}

axios.defaults.baseURL = API;

export const API_URL = API;
export const SERVER_URL = SERVER;
export const SAMPLES_IMAGES_URL = SAMPLES_IMAGES;

export const getAuthConfig = () => {
  const token = localStorage.getItem("_key_usr_tkn");
  return token
    ? {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    : {};
};
