import {
  Avatar,
  Grid,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
  CircularProgress,
  TableContainer,
  Portal,
  Button,
  withStyles,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";

import {
  UnarchiveIcon,
  CheckIcon,
  CloseIcon,
  CopyIcon,
  InfoIcon,
  InviteIcon,
  MenuIcon,
  NotificationCheckIcon,
  OpenIcon,
  TrainingRunningIcon,
} from "Components/Icon";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import ExperimentRow from "./ExperimentRow";
import { Alert } from "@material-ui/lab";
import { numberWithSpaces, timeSince } from "utils/format";
import { GlobalContext } from "Context/GlobalState";
import { SAMPLES_IMAGES_URL, SERVER_URL } from "services/api/config";
import CollaborationPopup from "../CollaborationPopup";
import DatasetDialog from "../DatasetDialog";
import useStickyHeader from "utils/useStickyHeader";
import TeamPopup from "../TeamPopup";
import { useParams } from "react-router-dom";

import { encodeQuery, trainingState } from "utils";
import { experimentsService } from "services/api/experiments";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingLeft: "24px",
    paddingRight: "24px",
    "& .MuiListItemIcon-root": {
      minWidth: 30,
      color: "#656565",
      "& .icon": {
        width: 16,
        color: "#656565",
      },
    },
    "& .MuiListItemText-primary": {
      fontSize: 10,
      color: "#707070",
      fontStyle: "italic",
    },
    "&:focus": {
      // backgroundColor: "#efefef",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        //color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

// { id, name, date, members, rating };
function DataSetRow(props) {
  const {
    state: {
      dataset: { dataset_type },
    },
  } = useContext(GlobalContext);
  const { row, datasetIndex, labelId, onClick, selected, ...rowProps } = props;
  const [open, setOpen] = useState(selected);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [experimentUnarchived, setExperimentUnarchived] = useState(null);
  const [page, setPage] = useState(0);
  const [next, setNext] = useState(null);
  const [experiments, setExperiments] = useState([]);
  const [openDatasetDialog, setOpenDatasetDialog] = useState(false);
  const { componentRef, isSticky, isBottom, coords } = useStickyHeader();
  const { datasetSlug, experimentSlug } = useParams();

  const lastChange = new Date(row.exp_updated_date);
  const IMAGE_URL =
    row.dataset_type === "medical"
      ? SAMPLES_IMAGES_URL
      : SERVER_URL + "images/xray/";

  const rowOpen = () => {
    setOpen(!open);
    onClick();
  };

  const getExperiments = () => {
    setLoading(true);
    
    let query = {};
    query["show-archived"] = 1;
    if (page) {
      query["page"] = page;
    }
    if (experimentSlug) {
      query["first"] = experimentSlug;
    }
    query = encodeQuery(query);

    experimentsService.getDatasetExperiments(row.id, query)
      .then((data) => {
        const { results, next } = data;
        const experimentsToAdd = [];
        results.forEach((item) => {
          const result = experiments.filter((obj) => {
            return obj.id === item.id;
          });
          if (result.length < 1) {
            experimentsToAdd.push(item);
          }
        });
        setNext(next);
        if (experimentsToAdd.length > 0) {
          setExperiments([...experiments, ...experimentsToAdd]);
        }
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };

  const handleDatasetDetails = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setOpenDatasetDialog(true);
  };

  const handleUnarchiveDataset = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    props.unArchiveDataset(row, datasetIndex);
  };

  useEffect(() => {
    if (datasetSlug === row.dataset_key) {
      rowOpen();
    }
  }, [datasetSlug, experimentSlug]);

  useEffect(() => {
    if (open) {
      getExperiments();
    }

    return () => {
      setExperiments([]);
      setPage(0);
      setNext(null);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      getExperiments();
    }
  }, [page]);

  useEffect(() => {
    if (isBottom && next) {
      const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      setPage(params.page);
    }
  }, [isBottom]);

  const sendUnarchiveExperiment = (archive, experiment) => {
    experimentsService.archiveExperiment(experiment.id, archive)
      .then((data) => {
        console.log("You Archived", data);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
      });
  };

  /// this is coming from the single experiment
  const handleArchiveExperiment = (experiment, expIndex) => {
    const result = experiments.filter((obj) => {
      return obj.id !== experiment.id;
    });
    console.log("Experiment-to-Archive", result);
    setExperiments([...result]);

    setExperimentUnarchived({
      message: "Experiment unarchived successfully",
      index: expIndex,
      experiment: experiment,
    });
    //setTimeout(() => {
    // sendUnarchiveExperiment("1", experiment);
    // setExperimentUnarchived(null);
    //}, 2000);
  };

  // console.log("EXP", row.is_archived)

  return (
    <React.Fragment>
      {isSticky && props.showHeader === true && (
        /*
        sticky will be the copy of table header while sticky 
        needed as otherwise table won't preserve columns width
        */
        <TableRow>
          <TableCell
            style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
            colSpan={10}>
            <TableContainer className="experiments-table-conatainer">
              <Table
                className="table sticky"
                style={{
                  display: openDatasetDialog ? "none" : "",
                  position: "fixed",
                  width: coords.width,
                  top: 0,
                  left: coords.left,
                  zIndex: 1300,
                }}>
                <TableBody>
                  <TableRow selected={open} {...rowProps} onClick={rowOpen}>
                    <TableCell style={{ width: "40px" }}>
                      <IconButton
                        title="Dataset Info"
                        onClick={handleDatasetDetails}
                        className="icon-button"
                        aria-label="archive"
                        color="primary">
                        {/* <InfoOutlined /> */}
                        <InfoIcon className="icon" />
                      </IconButton>
                    </TableCell>

                    <TableCell>{row.title}</TableCell>
                    <TableCell>
                      <Grid container alignItems="center">
                        {!row.isCollaboration && (
                          <div
                            title={
                              row.access_type === "Global"
                                ? "Owner can share dataset with everyone ​"
                                : "Owner can share dataset within own organization​"
                            }
                            className={`tag ${row.access_type}-tag`}>
                            <span>{row.access_type}</span>
                          </div>
                        )}
                        {row.isCollaboration ? (
                          <>
                            <Grid item>
                              <div
                                title="Owner can share dataset with own organization​"
                                className="tag competition-tag">
                                <span>Collaboration</span>
                              </div>
                              <CollaborationPopup dataset={row} />
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </TableCell>
                    <TableCell id={labelId}>
                      <Grid container alignItems="center">
                        <Grid xs={7} item>
                          {row.dataset_key}
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          style={{
                            paddingLeft: "4px",
                            alignSelf: "flex-start",
                          }}>
                          <IconButton
                            title="Copy ID"
                            className="icon-button"
                            aria-label="share"
                            color="primary">
                            <CopyIcon className="icon" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>

                    {/* <TableCell >{row.class_count}</TableCell> */}
                    <TableCell>{numberWithSpaces(row.images)}</TableCell>

                    <TableCell title="N° of Experiments executed">
                      {row.experiments}
                    </TableCell>

                    <TableCell>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center">
                        <AvatarGroup
                          spacing={5}
                          classes={{
                            root: "avatars-group",
                            avatar: "avatar-small",
                          }}
                          max={5}>
                          {row.isCollaboration ? (
                            <Avatar
                              title={`Host: ${row.host.name}`}
                              className="avatar-small"
                              alt={row.host.name}
                              src={`${row.host.company_logo}`}
                            />
                          ) : (
                            <Avatar
                              title={`Owner: ${row.owner.first_name} ${row.owner.last_name}`}
                              className="avatar-small"
                              alt={row.owner.first_name}
                              src={`${row.owner.profile_image}`}
                            />
                          )}
                          {row.users &&
                            row.users.map((user) => {
                              return user.id !== row.owner.id ? (
                                <Avatar
                                  className="avatar-small"
                                  alt={user.first_name}
                                  src={`${user.profile_image}`}
                                />
                              ) : null;
                            })}
                        </AvatarGroup>
                        <Avatar
                          title="Add user to dataset​"
                          className="avatar-icon">
                          <InviteIcon className="icon" />
                        </Avatar>
                        <TeamPopup
                          datasetKey={row.dataset_key}
                          datasetId={row.id}
                          isCollaboration={row.isCollaboration}
                          users={row.users}
                          owner={row.owner}
                          pending={row.pending_invitations}
                          host={row.host}
                          accessType={row.access_type}
                        />
                      </Grid>
                    </TableCell>

                    <TableCell>
                      {timeSince(
                        new Date(
                          lastChange.toLocaleString("en-US", {
                            timeZone:
                              Intl.DateTimeFormat().resolvedOptions().timeZone,
                          })
                        )
                      )}
                    </TableCell>

                    <TableCell>
                      <Grid container direction="row" alignItems="center">
                        {row.status === trainingState.completed ? (
                          <CheckIcon className="icon" />
                        ) : (
                          // <div class="pulse-blob"></div>
                          <TrainingRunningIcon className="icon" />
                        )}
                      </Grid>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        className="icon-button"
                        aria-label="archive"
                        color="primary">
                        {/* <ArchiveIcon className="icon" /> */}
                        <MenuIcon className="icon" />
                      </IconButton>
                      {row.is_archived && (
                        <StyledMenuItem onClick={handleUnarchiveDataset}>
                          <ListItemIcon>
                            <UnarchiveIcon className="icon" />
                          </ListItemIcon>
                          <ListItemText primary="Unarchive" />
                        </StyledMenuItem>
                      )}

                      <StyledMenuItem onClick={rowOpen}>
                        <ListItemIcon>
                          {open ? (
                            <CloseIcon className="icon" />
                          ) : (
                            <OpenIcon className="icon" />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={open ? "Close" : "Open"} />
                      </StyledMenuItem>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TableCell>
        </TableRow>
      )}

      {props.showHeader && (
        <TableRow
          selected={open}
          {...rowProps}
          onClick={rowOpen}
          className={row.is_archived ? "archived" : ""}>
          <TableCell style={{ width: "40px" }}>
            <IconButton
              title="Dataset Info"
              onClick={handleDatasetDetails}
              className="icon-button"
              aria-label="archive"
              color="primary">
              {/* <InfoOutlined /> */}
              <InfoIcon className="icon" />
            </IconButton>
          </TableCell>

          <TableCell>{row.title}</TableCell>
          <TableCell>
            <Grid container alignItems="center">
              {!row.isCollaboration && (
                <div
                  title={
                    row.access_type === "Global"
                      ? "Owner can share dataset with everyone ​"
                      : "Owner can share dataset within own organization​"
                  }
                  className={`tag ${row.access_type}-tag`}>
                  <span>{row.access_type}</span>
                </div>
              )}
              {row.isCollaboration ? (
                <>
                  <Grid item>
                    <div
                      title="Owner can share dataset with own organization​"
                      className="tag competition-tag">
                      <span>Collaboration</span>
                    </div>
                    <CollaborationPopup dataset={row} />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </TableCell>
          <TableCell id={labelId}>
            <Grid container alignItems="center">
              <Grid item>{row.dataset_key}</Grid>
            </Grid>
          </TableCell>

          {/* <TableCell >{row.class_count}</TableCell> */}
          <TableCell>
            {numberWithSpaces(row?.dataset_meta?.unique_data_items_count || 0)}
          </TableCell>

          <TableCell title="N° of Experiments executed">
            {row.experiments}
          </TableCell>

          <TableCell>
            <Grid container justifyContent="flex-start" alignItems="center">
              <AvatarGroup
                spacing={5}
                classes={{ root: "avatars-group", avatar: "avatar-small" }}
                max={5}>
                {row.isCollaboration ? (
                  <Avatar
                    title={`Host: ${row?.host?.name}`}
                    className="avatar-small"
                    alt={row?.host?.name}
                    src={`${row?.host?.company_logo}`}
                  />
                ) : (
                  <Avatar
                    title={`Owner: ${row.owner.first_name} ${row.owner.last_name}`}
                    className="avatar-small"
                    alt={row.owner.first_name}
                    src={`${row.owner.profile_image}`}
                  />
                )}
                {row.users &&
                  row.users.map((user) => {
                    return user.id !== row.owner.id ? (
                      <Avatar
                        className="avatar-small"
                        alt={user.first_name}
                        src={`${user.profile_image}`}
                      />
                    ) : null;
                  })}
              </AvatarGroup>
              <Avatar title="Add user to dataset​" className="avatar-icon">
                <InviteIcon className="icon" />
              </Avatar>
              <TeamPopup
                datasetKey={row.dataset_key}
                datasetId={row.id}
                isCollaboration={row.isCollaboration}
                users={row.users}
                owner={row.owner}
                pending={row.pending_invitations}
                host={row.host}
                accessType={row.access_type}
              />
            </Grid>
          </TableCell>

          <TableCell>
            {timeSince(
              new Date(
                lastChange.toLocaleString("en-US", {
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })
              )
            )}
          </TableCell>

          <TableCell>
            <Grid container direction="row" alignItems="center">
              {row.status === trainingState.completed ? (
                <CheckIcon className="icon" />
              ) : (
                // <div class="pulse-blob"></div>
                <TrainingRunningIcon className="icon" />
              )}
            </Grid>
          </TableCell>

          <TableCell>
            <IconButton
              className="icon-button"
              aria-label="archive"
              color="primary">
              {/* <ArchiveIcon className="icon" /> */}
              <MenuIcon className="icon" />
            </IconButton>
            <StyledMenuItem onClick={handleUnarchiveDataset}>
              <ListItemIcon>
                <UnarchiveIcon className="icon" />
              </ListItemIcon>
              <ListItemText primary="Unarchive" />
            </StyledMenuItem>

            <StyledMenuItem onClick={rowOpen}>
              <ListItemIcon>
                {open ? (
                  <CloseIcon className="icon" />
                ) : (
                  <OpenIcon className="icon" />
                )}
              </ListItemIcon>
              <ListItemText primary={open ? "Close" : "Open"} />
            </StyledMenuItem>
          </TableCell>
        </TableRow>
      )}

      {/* 
                ////// Expansion ////////////////
                /////\\\\\\\\\\\\\\///////////////
             */}

      <TableRow ref={componentRef}>
        <TableCell
          style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}>
          <Collapse
            className="experiment-container"
            style={{ minHeight: "60px" }}
            in={open}
            timeout="auto"
            unmountOnExit>
            <Grid
              alignItems="center"
              container
              style={{
                position: isSticky ? "fixed" : "absolute",
                top: isSticky ? "60px" : "",
                marginTop: isSticky
                  ? "0px"
                  : props.showHeader
                  ? "20px"
                  : "-6px",
                // marginBottom: isSticky ? "0px" : "4px",
                marginLeft: "61px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                paddingTop: "8px",
                zIndex: 100,
                width: "100%",
                maxWidth: componentRef?.current?.clientWidth,
              }}>
              {!loading && props.showHeader === false && (
                <Grid
                  style={{
                    // position: "absolute",
                    marginLeft: "auto",
                    // right: -360,
                    paddingRight: "105px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  item>
                  <Box
                    onClick={props.showArchived}
                    style={{
                      borderColor: "#707070",
                      backgroundColor: "#707070",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderRadius: "5px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      cursor: "pointer",
                    }}>
                    <Typography
                      style={{
                        fontSize: "10px",
                        fontStyle: "normal",
                        color: "#FFFFFF",
                      }}>
                      Archived
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>

            {experiments.length > 0 &&
              experiments.map((experiment, index) => {
                return (
                  <ExperimentRow
                    key={experiment.id}
                    expIndex={index}
                    experimentData={experiment}
                    unArchiveExperiment={handleArchiveExperiment}
                    showHeader={props.showHeader}
                  />
                );
              })}
            {loading && (
              <Grid container justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

      <Portal>
        {experimentUnarchived !== null && (
          <Snackbar
            open={experimentUnarchived !== null}
            autoHideDuration={2000}
            disableWindowBlurListener
            onClose={() => {
              //console.log("CLCLLC");
              if (experimentUnarchived !== null) {
                sendUnarchiveExperiment(false, experimentUnarchived.experiment);
              }
              setExperimentUnarchived(null);
            }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <Alert
              variant="filled"
              severity="success"
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              action={
                <Button
                  size="small"
                  onClick={() => {
                    const expeUndo = experiments;
                    expeUndo.splice(
                      experimentUnarchived.index,
                      0,
                      experimentUnarchived.experiment
                    );
                    setExperiments([...expeUndo]);
                    console.log("EXP-UNDO", expeUndo);
                    setExperimentUnarchived(null);
                  }}>
                  UNDO
                </Button>
              }>
              {experimentUnarchived.message}
            </Alert>
          </Snackbar>
        )}
      </Portal>

      <DatasetDialog
        dataset_type={dataset_type}
        imageUrl={IMAGE_URL}
        dataset={row}
        open={openDatasetDialog}
        closeCallback={() => setOpenDatasetDialog(false)}
      />
    </React.Fragment>
  );
}

export default DataSetRow;
