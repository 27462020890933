import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  Popover,
} from "@material-ui/core";

import React, { useRef, useState } from "react";
import { formatCommentDate } from "utils/format";
import { AddCommentIcon } from "Components/Icon";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import UserPopup from "Components/UserPopup";
import { experimentsService } from "services/api/experiments";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "0px 0px 5px 5px",
    borderColor: "#F7F7F7",
    border: 0,
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
      padding: 0,
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
    "& .MuiTextField-root": {
      width: "100%",
      color: "#f7f7f7",
    },
    "& textarea": {
      width: "100%",
      fontSize: 12,
      lineHeight: "20px",
      height: "32px",
      padding: "6px 12px",
      border: "1px solid #F7F7F7",
      borderWidth: "0px",
      borderRightWidth: "1px",
    },
  },
}));

const Comment = (props) => {
  const {
    userId: { first_name, last_name },
    comment,
    created_date,
    id,
    experimentId_id,
  } = props.comment;
  const [saving, setSaving] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const commentBoxRef = useRef(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const d = new Date(created_date);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const saveComment = () => {
    setSaving(true);
    experimentsService
      .saveComment(experimentId_id, value, id)
      .then((res) => {
        const data = res.data;
        props.saveChildComment(data);
        setSaving(false);
        setError(null);
        setValue("");
      })
      .catch((error) => {
        if (error.response) {
          setError("Please make sure you are authorized.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setSaving(false);
      });
  };

  /// Popup open and close
  const openUserPopup = Boolean(userAnchorEl);
  const userPopupId = openUserPopup ? "simple-user-popover" : undefined;

  const handleUserPopup = (e) => {
    e.stopPropagation();
    setUserAnchorEl(e.currentTarget);
  };

  const handleCloseUserPopup = (e) => {
    setUserAnchorEl(null);
  };

  return (
    <Grid container className="comment">
      <Grid item>
        <Avatar
          onClick={handleUserPopup}
          src={props.comment.userId.profile_image}
          className="avatar"
          alt={props.comment.userId.first_name}
        />
        <Popover
          id={userPopupId}
          open={openUserPopup}
          anchorEl={userAnchorEl}
          onClose={handleCloseUserPopup}
          elevation={0}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}>
          <UserPopup
            itsMe={false}
            profile_image={props.comment?.userId?.profile_image}
            user={props.comment.userId}
            closeCallback={handleCloseUserPopup}
          />
        </Popover>
      </Grid>
      <Grid xs={11} item>
        <Grid item className="comment-header normal-text">
          <div onClick={handleUserPopup} className="name">
            {first_name} {last_name}
          </div>
          <div className="date">{formatCommentDate(d)} ago</div>
        </Grid>

        <Grid className="comment-container" item>
          {comment}
        </Grid>
        {props.children && (
          <Grid className="child-comments" item>
            {props.children}
          </Grid>
        )}

        {!props.child && !showCommentBox && (
          <div ref={commentBoxRef} className={classes.root}>
            <TextField
              id="standard-multiline-flexible"
              label=""
              value={value}
              placeholder="Reply here"
              multiline
              rowsMax={4}
              rows={1}
              variant="outlined"
              onChange={handleChange}
            />
            <Button
              onClick={saveComment}
              className="create-comment-btn create-reply-btn"
              disabled={value.length < 1}>
              {saving ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <AddCommentIcon />
              )}
            </Button>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default Comment;
