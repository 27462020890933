import { Typography } from "@material-ui/core";
import React from "react";
import { descriptionStyle, subHeadingStyle, useStyles } from "../style";

export default function German() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography style={subHeadingStyle}>Präambel</Typography>

      <Typography style={descriptionStyle}>
        Die tracebloc GmbH, Rosenheimer Str. 38, 10781 Berlin (nachfolgend:
        „tracebloc“) betreibt eine Plattform, auf der u.a. Kunden Wettbewerbe
        für Software-Entwickler bzw. Entwickler-Teams (nachfolgend einzeln
        „Nutzer“ oder gemeinsam „Teams“ genannt) veranstalten und für die Sieger
        Preise ausloben können (nachfolgend: „Collaboration“ genannt). Zu diesem
        Zweck können Veranstalter Datensätze über die Plattform veröffentlichen
        (nachfolgend zusammenfassend „Collaboration-Daten“ genannt), die den
        Nutzern dazu dienen, Anwendungen im Bereich des Maschinellen Lernens
        (nachfolgend: „ML-Lösung“ genannt) zu entwickeln. Nutzer können an einem
        Collaboration teilnehmen, indem sie sich über die Plattform registrieren
        und die von ihnen entwickelten ML-Lösungen über die Plattform
        bereitzustellen, um diese mit den Trainings-Daten des Veranstalters zu
        trainieren. Die nachfolgenden allgemeinen Nutzungsbedingungen
        (nachfolgend „AGB“) gelten für sämtliche Nutzer der Plattform:
      </Typography>

      <br />

      <h3>Allgemeine Bestimmungen</h3>
      <div>
        <ol>
          <li>
            Diese AGB gelten für alle auf der tracebloc-Plattform registrierten
            Nutzer. Die AGB können jederzeit in der jeweils gültigen Fassung
            unter dem folgenden Link{" "}
            <a href="https://www.tracebloc.io/terms">
              https://www.tracebloc.io/terms
            </a>
            abgerufen werden.
          </li>
          <li>
            Sofern zwischen den Parteien nicht abweichend vereinbart, ist die
            Nutzung der Plattform für Nutzer kostenfrei. tracebloc steht es
            frei, dem Nutzer Rechenleistung in Form eines Computation Budgets
            (FLOPS-Einheiten) nach freiem Ermessen bereitzustellen, was vom
            Nutzer für das Trainieren von eigenen ML-Lösungen auf allgemeinen
            Datensätzen verwendet werden kann. Das so bereitgestellte
            Computation Budget kann nicht für Collaborations verwendet werden.
            Ein künftiger Anspruch auf die Gewährung von weiterem Computation
            Budget wird hierdurch nicht begründet.
          </li>
          <li>
            Weiteres, über das in Ziff. 1.2 hinausgehende Computation Budget
            kann vom Nutzer kostenpflichtig erworben werden. Hierfür gelten die
            Bedingungen des jeweiligen Leistungspakets.
          </li>
          <li>
            Diese AGB gelten ausschließlich. Abweichende, entgegenstehende oder
            ergänzende Allgemeine Geschäftsbedingungen der Nutzer werden nur
            dann und insoweit Vertragsbestandteil, als tracebloc ihrer Geltung
            ausdrücklich zugestimmt hat.
          </li>
        </ol>
      </div>

      <br />
      <h3>Leistungsgegenstand und Verfügbarkeit</h3>
      <div>
        <ol>
          <li>
            Hauptleistungspflicht von tracebloc ist die Bereitstellung der
            Plattform. Die Bereitstellung der Plattform wird durch die
            Zugänglichmachung eines Online-Zugangs (SaaS-Lösung) erfüllt. Im
            Übrigen stellt tracebloc die Plattform so bereit, wie diese bei
            Registrierung und der zu diesem Zeitpunkt geltenden AGB verfügbar
            gewesen ist („as is“). Darüber hinaus hat der Nutzer keinen Anspruch
            auf eine bestimmte Ausgestaltung oder Ausstattung der Plattform.
          </li>
          <li>
            Durch die Teilnahme an einem Collaboration kommt ein
            Vertragsverhältnis zwischen dem Nutzer und dem Veranstalter nach
            Maßgabe von Ziff. 5 zustande. tracebloc wird nicht Vertragspartei
            dieser Vertragsbeziehung, sondern stellt lediglich die Plattform mit
            den in diesen AGB bestimmten Funktionalitäten bereit. Ein Anspruch
            darauf, an einem Collaboration teilzunehmen, wird durch diese AGB
            nicht begründet.
          </li>
          <li>
            tracebloc wird die Plattform fortlaufend warten und
            weiterentwickeln. Weiterentwicklungen der Plattform werden dem
            Nutzer im Zuge von Updates oder Upgrades bereitgestellt. Ein
            Anspruch auf die Entwicklung individueller oder die Bereitstellung
            der neuen Versionsstände wir hierdurch nicht begründet.
          </li>
          <li>
            tracebloc stellt die Plattform mit einer Gesamtverfügbarkeit von
            98,5 % im Jahresmittel bereit. Die Verfügbarkeit berechnet sich
            dabei abzüglich der auf notwendige Wartungszeiten entfallenden
            Zeiträume. Während der Wartungsarbeiten steht die Plattform nicht
            zur Verfügung.
          </li>
        </ol>
      </div>

      <br />
      <h3>Registrierung</h3>
      <div>
        <ol>
          <li>
            Nutzer können nur Unternehmer i.S.d. § 14 Abs.1 BGB sein. Mit der
            Registrierung erkennt der Nutzer die zu diesem Zeitpunkt gültigen
            AGB an. Zur Vermeidung missbräuchlicher Registrierungen erfolgt nach
            Übermittlung des Formulars an tracebloc unverzüglich die Bestätigung
            der Registrierung per E-Mail an die im Sign-Up-Formular angegebene
            E-Mail-Adresse. Über den darin enthaltenen Link zur Freischaltung
            wird die Registrierung abgeschlossen und das Nutzerprofil aktiviert.
            Ändern sich die Daten des Nutzers, ist dieser zur unverzüglichen
            Aktualisierung der Angaben in seinem Nutzerprofil verpflichtet.
          </li>
          <li>
            Der Nutzer ist zur Geheimhaltung seines Passworts verpflichtet und
            hat dieses vor unbefugter Benutzung durch Dritte zu schützen. Der
            Nutzer ist zur Änderung des Passworts in regelmäßigen Abständen
            verpflichtet. Ist dem Nutzer ein Missbrauch seiner Zugangsdaten
            bekannt geworden, besteht die Möglichkeit oder droht ein solcher
            Missbrauch, wird der Nutzer tracebloc unverzüglich hierüber
            informieren. Für eine missbräuchliche Nutzung seines Nutzerprofils
            haftet der Nutzer; dies gilt nicht, wenn der Nutzer die
            missbräuchliche Nutzung nicht zu vertreten hat.
          </li>
        </ol>
      </div>

      <br />
      <h3>Abruf und Nutzung von Inhalten</h3>
      <div>
        <ol>
          <li>
            tracebloc stellt den Nutzern über die Plattform unterschiedliche
            Inhalte, insbesondere Collaboration- Daten, zur zeitlich befristeten
            Ansicht zur Verfügung. Solche Inhalte können insbesondere Daten
            sein, die für die Austragung eines Collaborationes von Dritten
            bereitgestellt werden, insbesondere Bild- sowie Videomaterial.
          </li>
          <li>
            tracebloc ist lediglich technischer Dienstleister. Die über die
            Plattform durch Dritten eingestellte Inhalte werden von tracebloc
            lediglich gespeichert. Werden von tracebloc ausnahmsweise eigene
            Inhalte bereitgestellt, sind diese ausdrücklich als solche
            gekennzeichnet.
          </li>
          <li>
            Für die Einhaltung der gesetzlichen Vorschriften hinsichtlich der
            von Dritten eingestellten Inhalte ist die einstellende Person selbst
            verantwortlich. tracebloc wird diese nicht auf Vollständigkeit,
            Korrektheit, Rechtmäßigkeit, Aktualität, Qualität und Eignung für
            einen Collaboration überprüfen.
          </li>
        </ol>
      </div>

      <br />

      <h3>Teilnahme an einem Collaboration</h3>
      <div>
        <ol>
          <li>
            Einem Nutzer steht es frei, sich für die Teilnahme an einem
            Collaboration zu registrieren. Mit Registrierung und einem Klick auf
            den Button „Join Collaboration“ akzeptiert er die von dem jeweiligen
            Veranstalter bestimmten Teilnahmebedingungen und wird so zum
            Teilnehmer (nachfolgend: „Competitor“). Bei Widersprüchen zwischen
            solchen Teilnahmebedingungen des Veranstalters und diesen AGB, gehen
            die Bestimmungen der Teilnahmebedingungen des Veranstalters vor.
          </li>
          <li>
            Während einer laufenden Collaboration können Competitor bzw. Teams
            ihre ML-Lösungen entsprechend der jeweils geltenden Bestimmungen
            trainieren. Zum Ende eines Collaboration wählt jeder Competitor bzw.
            jedes Teams in eigener Verantwortung von dem Veranstalter eine
            bestimmte Anzahl an ML- Lösungen aus, die an der Preis-Ermittlung
            durch den Veranstalter teilnehmen.
          </li>
          <li>
            Sofern in den Teilnahmebedingungen des Veranstalters nicht
            abweichend bestimmt, gelten für die Durchführung eines Collaboration
            die folgenden Bestimmungen:
            <ul>
              <li>
                Dem Veranstalter steht es frei, die Teilnehmerzahl zu
                limitieren. Weiter steht es dem Veranstalter frei, an einer
                eigenen Collaboration teilzunehmen, wobei er bzw. sein Team bei
                der Gewinn-Ermittlung nicht berücksichtigt wird.
              </li>
              <li>
                Competitor können sich bis zum Ende einer Collaboration (der
                team merger deadline) zu Teams von maximal 5 natürlichen
                Personen zusammenschließen. Ein Competitor kann an einem
                Collaboration entweder als Einzelperson oder als Team-Mitglied
                teilnehmen. Ein Competitor kann nicht Mitglied verschiedener
                Teams sein. Ein Team-Zusammenschluss kann nur aus wichtigem
                Grund rückgängig gemacht werden. Team Zusammenschlüsse können
                nicht mehr rückgängig gemacht werden. Sobald ein Team
                Zusammenschluss erfolgt, werden die von den jeweiligen
                Competitor/Team bis zu diesem Zeitpunkt entwickelten ML-Lösungen
                mit allen Mitgliedern eines Teams geteilt.
              </li>
              <li>
                Für jedes Team bzw. jeden Competitor gelten die in den
                Teilnahmebedingungen des Veranstalters konkretisierenden
                Bestimmungen, so insbesondere zum verfügbaren Computation Budget
                pro Nutzer bzw. Team. Der Zusammenschluss zu einem Team ist dann
                ausgeschlossen, wenn das je Competitor bis zu diesem Zeitpunkt
                des gewünschten Zusammenschlusses aufgebrauchte Computation
                Budget in der Summe nicht das vom Veranstalter bestimmte
                Computation Budget je Team überschreitet und eine ggf. vom
                Veranstalter für den Zusammenschluss bestimmte deadline zum
                Team-Zusammenschluss nicht überschritten wird.
              </li>
              <li>
                Jedes Team kann maximal 5 Modelle zur ML-Lösung pro Tag in der
                Zeitzone von tracebloc einreichen.
              </li>
              <li>
                Die von Competitoren bzw. Teams eingereichten ML-Lösungen werden
                von dem Veranstalter während der laufenden Collaboration mit den
                vom Veranstalter bestimmten Trainings-Daten trainiert. Die
                Ergebnisse veröffentlicht der Veranstalter über das Leaderboard,
                wobei die dortigen Angaben zum Score (evaluation metric) in
                alleiniger Verantwortung des Veranstalters bestimmt werden.
              </li>
              <li>
                Ein Anspruch auf die Bereitstellung oder Überlassung der
                Trainings-Daten steht den Competitoren bzw. Teams nicht zu.
              </li>
              <li>
                Der Veranstalter kann die Collaboration so gestallten, dass
                vortrainierte Modelle zugelassen sind oder auch nicht. Die
                vortrainierten Modelle müssen auf Datensätzen traniert worden
                sein, die es zulassen das Modell kommerzielle zu nutzen.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <br />

      <h3>Ermittlung der Gewinner</h3>
      <div>
        <ol>
          <li>
            Die Collaboration-Daten werden nach den auf der Wettbewerbswebsite
            des Vernatsalters bzw. in den Teilnahmebedingungen des Veranstalters
            angegebenen Bewertungsmaßstäben bewertet und eingestuft. Während
            einer Collaboration wird die aktuelle Rangliste auf dem öffentlichen
            Leaderboard der Wettbewerbswebsite angezeigt. Der potenzielle
            Gewinner wird ausschließlich durch die Rangliste auf dem privaten
            Leaderboard bestimmt, vorbehaltlich der Einhaltung der vorliegenden
            Regeln. Die öffentliche Rangliste basiert auf dem öffentlichen
            Testset und die private Rangliste auf dem privaten Testset.
          </li>
          <li>
            Zur Vermeidung von Missverständnissen wird bestimmt, dass der
            Gewinner in alleiniger Verantwortung des Veranstalter bestimmt wird.
          </li>
          <li>
            Im Falle eines Gleichstandes gewinnt die ML-Lösungen, die zeitlich
            zuerst zur Competiton eingereicht wurde. Bei Disqulifizierung eines
            potentiellen Gewinners, gewinnt der Teilnehmer mit der nächsthöheren
            Punktzahl.
          </li>
          <li>
            Die Teilnahme von Mitarbeitern des Veranstalters und von tracebloc
            ist ausgeschlossen.
          </li>
        </ol>
      </div>

      <br />

      <h3>Nutzungsrechte</h3>
      <div>
        <ol>
          <li>
            Sämtliche über die Plattform angebotenen Inhalte sind unter
            urheber-, marken-, namens- und gegebenenfalls weiteren rechtlichen
            Gesichtspunkten geschützt. Die Zusammenstellung der Inhalte als
            Solche ist ggf. geschützt als Datenbank oder Datenbankwerk iSd. §§ 4
            Abs. 2, 87a Abs. 1 UrhG. Nutzer dürfen diese Inhalte lediglich gemäß
            diesen AGB sowie im auf der Plattform vorgegebenen Rahmen nutzen.
          </li>
          <li>
            Während der Vertragslaufzeit räumt tracebloc dem Nutzer ein
            einfaches und nicht übertragbares, zeitlich auf das
            Vertragsverhältnis beschränktes Nutzungsrecht an der Plattform ein.
            Eine über diese Rechteeinräumung hinausgehende Nutzung der Plattform
            ist nicht gestattet.
          </li>
          <li>
            Sofern in den Teilnahmebedingungen eines Veranstalters nicht
            abweichend bestimmt, dienen die über die Plattform veröffentlichten
            Daten, insbesondere die Collaboration-Daten, allein zur Ansicht.
            Darüberhinausgehende Nutzungs- und/oder Verwertungsrechte an diesen
            Inhalten werden den Nutzern nicht eingeräumt oder übertragen.
          </li>
          <li>
            Der Nutzer ist nicht berechtigt die Plattform oder die hierüber
            bereitgestellten Inhalte zu bearbeiten, insbesondere abzuändern, zu
            übersetzen, zurück und weiter zu entwickeln, zu dekompilieren oder
            zu disassemblieren und umzugestalten, sofern nicht in dieser Ziffer
            6 gestattet oder ein gesetzlicher Erlaubnistatbestand vorliegt. Die
            Dekompilierung der Plattform ist ausnahmsweise nur zulässig, wenn
            die in § 69 e Abs. 1 UrhG genannten Voraussetzungen und Bedingungen
            vorliegen. Die hierdurch gewonnenen Informationen dürfen nicht
            entgegen den Maßgaben von § 69 e Abs. 2 UrhG verwendet bzw.
            weitergegeben werden.
          </li>
          <li>
            Für die Schaffung der im Verantwortungsbereich des Nutzers zur
            vertragsgemäßen Nutzung bzw. zum Abruf der Inhalte notwendigen
            technischen Voraussetzungen ist der Nutzer selbst verantwortlich.
          </li>
        </ol>
      </div>

      <br />

      <h3>Inhalte und Pflichten der Nutzer sowie Verstöße</h3>
      <div>
        <ol>
          <li>
            Der Nutzer hat in eigener Verantwortung sicherzustellen, dass er die
            Teilnahmebedingungen des jeweiligen Veranstalters einer
            Collaboration achtet.
          </li>
          <li>
            Sofern in den Teilnahmebedingungen eines Veranstalters nicht
            abweichend bestimmt, ist es den Nutzern während einer laufenden
            Collaboration untersagt, eine im Rahmen der jeweiligen Collaboration
            auf Basis der Collaboration-Daten entwickelte ML-Lösung bzw. Teile
            hiervon oder den zugrundeliegenden Quellcode Dritten, insbesondere
            anderen Teams, bereitzustellen, sofern diese Dritten nicht vor der
            Bereitstellung nachweislich in einem Team mit dem Nutzer verbunden
            waren. Zulässig ist die Bereitstellung dann, wenn dies für alle
            Teilnehmer eines Collaborationes über das Forum der Plattform
            erfolgt.
          </li>
          <li>
            Ferner hat jeder Nutzer das geltende Recht zu beachten und die
            Rechte Dritter zu wahren. Den Nutzern ist es insbesondere untersagt,
            <ul>
              <li>
                Schutzrechte Dritter wie Marken, Urheber- und Namensrechte zu
                verletzen;
              </li>
              <li>
                Die Inhalte der Plattform in Gänze oder teilweise zu crawlen
                oder zu scrapen;
              </li>
              <li>
                Die Plattform zu dekompilieren oder rückentwickeln
                (reverse-engineering) oder anderweitig versuchen, den Quellcode
                oder die von Dritten über die Plattform bereitgestellten Inhalte
                zu erlangen, sofern dies nicht ausdrücklich erlaubt ist;
              </li>
              <li>strafrechtlich relevante Inhalte einzustellen;</li>
              <li>
                über die auf der Plattform von tracebloc bereitgestellten
                Funktionalitäten und Schnittstellen hinausgehende Mechanismen,
                Software und/oder Skripte einsetzen, insbesondere wenn hierdurch
                Dienstleistungen von tracebloc blockiert, modifiziert, kopiert
                und/oder überschrieben werden und diese Dienstleistungen für die
                vertragsgemäße Nutzung von tracebloc erforderlich sind.
              </li>
            </ul>
          </li>
          <li>
            tracebloc ist dazu berechtigt einzelne rechtswidrige bzw. gegen
            diese AGB verstoßende Inhalte zu löschen. Ferner darf tracebloc nach
            billigem Ermessen (§ 315 BGB) unter Beachtung der Interessen des
            Nutzers eine oder mehrere Sanktionen gegen diesen verhängen, wenn
            schuldhaft gegen diese AGB oder die jeweiligen Teilnahmebedingungen
            verstoßen wurde oder ein wichtiger Grund im Sinne von Ziff. 8.4
            dieser AGB vorliegt. Die Schwere der Sanktionen richtet sich dabei
            nach der Schwere der jeweiligen Zuwiderhandlung. Die Sanktion bleibt
            solange bestehen, bis die sanktionierte Handlung von dem Nutzer
            abgestellt und eine etwaige Wiederholungsgefahr ausgeräumt ist.
          </li>
          <li>
            Als Sanktionen kommen in Betracht: (i) die Deaktivierung oder
            Sperrung einzelner Funktionen der Plattform für den betroffenen
            Nutzer (ii) die Löschung, Verschiebung oder Schließung der für den
            Nutzer eingestellten Inhalte (iii) die vollständige Sperrung des
            Zugangs zur Plattform sowie (iv) die vollständige Löschung eins
            Accounts. Das Recht zur außerordentlichen Kündigung aus wichtigem
            Grund durch tracebloc nach Ziff. 8.4 bleibt hiervon ebenso unberührt
            wie die Geltendmachung weitergehenden Ansprüche.
          </li>
        </ol>
      </div>

      <br />

      <h3>Laufzeit und Kündigung</h3>
      <div>
        <ol>
          <li>
            Das Vertragsverhältnis über die Nutzung der Plattform beginnt mit
            der erfolgreichen Registrierung des Nutzers und läuft für
            unbestimmte Zeit.
          </li>
          <li>
            Das Vertragsverhältnis kann von jeder Partei mit einer Frist von 3
            Monaten zum Monatsende ordentlich gekündigt werden.
          </li>
          <li>
            Eine Kündigung bedarf zu ihrer Wirksamkeit einer eindeutigen
            Erklärung in der Textform und kann per E- Mail an info@tracebloc.io
            erfolgen.
          </li>
          <li>
            Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt
            unberührt. Ein wichtiger Grund zur Kündigung liegt für tracebloc
            insbesondere dann vor, wenn
            <ul>
              <li>
                ein Nutzer die Pflichten nach Ziff. 4 und/oder 5 schuldhaft
                verletzt,
              </li>
              <li>
                ein Nutzer schuldhaft gegen die Bestimmungen von Ziff. 7.2
                verstößt,
              </li>
              <li>
                ein Nutzer schuldhaft Collaboration-Daten rekonstruiert (sog.
                reverse engineering bzw. model- inversion-attack), oder
              </li>
              <li>
                ein Nutzer schuldhaft gegen die jeweiligen Teilnahmebedingungen
                eines Collaborationes verstößt, oder § 314 BGB bleibt unberührt.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <br />
      <h3>Haftung</h3>
      <div>
        <ol>
          <li>
            Die Parteien haften einander für von ihnen, ihren gesetzlichen
            Vertretern oder Erfüllungsgehilfen oder von ihnen eingeschalteten
            Dritten, insbesondere Subunternehmern, verursachte unmittelbare und
            mittelbare Sach- und Vermögensschäden nach Maßgabe der nachfolgenden
            Bestimmungen.
          </li>
          <li>
            tracebloc haftet - außer bei Verletzung wesentlicher
            Vertragspflichten, bei Verletzung von Leben, Körper oder Gesundheit
            oder bei Ansprüchen aus dem Produkthaftungsgesetz – nur für Vorsatz
            und grobe Fahrlässigkeit. Wesentliche Vertragspflichten sind solche,
            deren Erfüllung zur Erreichung des Vertragszweckes notwendig ist.
          </li>
          <li>
            Für Pflichtverletzungen des Nutzers, insbesondere bei Verletzung
            fremder Marken, Urheber- und Namensrechte, haftet ausschließlich der
            Nutzer selbst. Der Nutzer stellt tracebloc von sämtlichen auf
            Zahlung gerichteten Ansprüchen anderer Nutzer und Dritter frei, die
            gegen tracebloc wegen einer Rechtsverletzung durch die für einen
            Nutzer auf eingestellten Inhalte und/oder wegen der Nutzung der
            Plattform durch den Nutzer geltend gemacht werden. Der Nutzer
            übernimmt auf erstes Anfordern alle tracebloc entstehenden und
            angemessenen Kosten, die aus einer solchen Rechtsverletzung
            resultieren. Davon umfasst sind insbesondere die notwendigen Kosten
            der Rechtsverteidigung. Dies gilt nicht, wenn der Nutzer die
            Rechtsverletzung nicht zu vertreten hat. Andere Ansprüche von
            tracebloc gegen den Nutzer bleiben hiervon unberührt.
          </li>
          <li>
            Die Verfügbarkeit der Plattform nach Ziffer 2.4 kann zeitweise wegen
            technischer Störungen, die nicht von tracebloc zu vertreten sind,
            oder aus Gründen höherer Gewalt (Ausfall von Stromversorgung
            und/oder Internet, Brand, Explosion, Erdbeben, Unwetter,
            Überschwemmungen, von tracebloc nicht zu vertretende
            Arbeitskampfmaßnahmen) im Ganzen oder in Teilen eingeschränkt sein,
            ferner wegen der Durchführung von Wartungsarbeiten, die der
            Aufrechterhaltung und Verbesserung der Betriebsfähigkeit und
            Funktionalität der Plattform dienen. Für die Folgen einer
            eingeschränkten Verfügbarkeit haftet tracebloc ausschließlich im
            Rahmen einer dem Nutzer in der Leistungsbeschreibung zugesagten
            Verfügbarkeit.
          </li>
          <li>
            Die verschuldensunabhängige Schadensersatzhaftung für Mängel,
            insbesondere nach § 536a BGB, die bereits bei Vertragsschluss
            vorlagen, ist ausgeschlossen. Diese Ansprüche richten sich im
            Übrigen nach den gesetzlichen Bestimmungen.
          </li>
        </ol>
      </div>

      <br />
      <h3>Schlussbestimmungen</h3>
      <div>
        <ol>
          <li>
            Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss
            des UN-Kaufrechts und des Kollisionsrechts; Art. 3 EGBGB bleibt
            unberührt. Hat der Nutzer zum Zeitpunkt der Begründung des
            Vertragsverhältnisses seinen gewöhnlichen Aufenthalt in einem
            anderen Land innerhalb von EU/EWR, bleibt die Anwendung zwingender
            Rechtsvorschriften dieses Landes von der in diesen AGB getroffenen
            Rechtswahl unberührt.
          </li>
          <li>
            Mündliche Nebenabreden wurden nicht getroffen. Änderungen und
            Ergänzungen dieser AGB sowie alle auf die Vertragsbeziehung
            bezogenen Erklärungen der Parteien bedürfen der Textform (§ 126b
            BGB). Dies gilt auch für die Aufhebung des Formerfordernisses.
          </li>
          <li>
            Erfüllungsort und Gerichtsstand für die beiderseitigen Ansprüche aus
            der Vertragsbeziehung ist der Sitz von tracebloc, soweit nichts
            anderes bestimmt ist. tracebloc ist berechtigt, den Nutzer an seinem
            allgemeinen Gerichtsstand gerichtlich in Anspruch zu nehmen.
          </li>
        </ol>
      </div>

      <br />
    </div>
  );
}
