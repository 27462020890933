import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  makeStyles,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import React, { useRef, useState } from "react";

import "./style.css";
import { Alert } from "@material-ui/lab";
import { isEmail } from "utils";
import { inviteToDataset, inviteToTeam } from "services/api/collaborations";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: "1px solid #e6e6e6",
  },
  input: {
    marginRight: "11px",
    flex: 1,
    fontSize: 12,
    backgroundColor: "white",
    width: "149px",
    height: "32px",
    border: "1px solid #E6E6E6",
    borderRadius: "5px",
    padding: "3px 7px",
  },
  inviteButton: {
    padding: 4,
    minWidth: "65px",
    backgroundColor: "var(--primary)",
    color: "white",
    fontSize: 10,
    textTransform: "capitalize",
    "&.Mui-disabled": {
      opacity: 0.5,
      color: "white",
    },
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "var(--primary)",
    },
  },
  divider: {
    height: 20,
    margin: 4,
  },
}));

const TeamPopup = ({
  title,
  users,
  host,
  owner,
  pending,
  accessType,
  teamId,
  datasetId,
  datasetKey,
  isCollaboration,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [enableInvite, setEnableInvite] = useState(false);
  const inputRef = useRef(null);
  const classes = useStyles();

  const setShareDataset = async (email) => {
    setLoading(true);
    try {
      await inviteToDataset(email, datasetId);
      setLoading(false);
      setError(null);
      setOpen(true);
    } catch (error) {
      handleError(error);
    }
  };

  const setTeamInvite = async (data) => {
    setLoading(true);
    try {
      await inviteToTeam(data, teamId);
      setLoading(false);
      setError(null);
      setOpen(true);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response?.status === 400) {
      setError(Object.values(error.response?.data));
    } else if (error.response?.status === 500) {
      setError([
        "Something went wrong on our end. We are working hard to fix it as soon as possible.",
      ]);
    } else {
      setError(["Make sure you are connected to the internet."]);
    }

    if (error.message !== "canceled") {
      setLoading(false);
    }
  };

  const handleInvite = () => {
    const data = inputRef.current.value;
    if (isCollaboration) {
      setTeamInvite(data);
    } else {
      if (isEmail(data)) {
        setShareDataset(data);
      }
    }
  };

  const handleKeyDown = (e) => {
    console.log(e.keyCode);
    if (e.keyCode === 13) {
      handleInvite();
    }
  };

  const onInputChange = (e) => {
    console.log(e.target.value);
    if (isCollaboration) {
      setEnableInvite(e.target.value.length > 2);
    } else {
      setEnableInvite(isEmail(e.target.value));
    }
  };

  return (
    <div data-testid="team-popup" className="team-popup">
      <div
        style={{
          color: "var(--textPrimary)",
          fontSize: 12,
          marginLeft: 4,
          marginBottom: 16,
        }}>
        {title || `Invite to dataset`}
      </div>

      <Grid container direction="row">
        <Grid item>
          <InputBase
            title={
              isCollaboration
                ? `Enter a valid email or team name`
                : `Enter a valid email address`
            }
            onChange={onInputChange}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            className={classes.input}
            placeholder={
              isCollaboration
                ? `Enter a valid email or team name`
                : `Enter a valid email address`
            }
            inputProps={{
              "aria-label": isCollaboration
                ? `Enter a valid email or team name`
                : `Enter a valid email address`,
            }}
          />
        </Grid>

        <Grid className="gradient-button" style={{ minWidth: "65px" }} item>
          <Button
            disabled={!enableInvite || loading}
            onClick={handleInvite}
            color="secondary"
            style={{ minWidth: "65px" }}
            // className={classes.inviteButton}
            role="button"
            aria-label="invite-team">
            {loading ? (
              <CircularProgress color="inherit" size={12} />
            ) : (
              "Invite"
            )}
          </Button>
        </Grid>

        <Box mt={1} className={"inner-message"}>
          <Collapse in={error !== null}>
            <Alert
              severity="error"
              icon={false}
              // action={
              //     <IconButton size="small" onClick={() => { setError(null) }}>
              //         <CloseIcon fontSize="inherit" />
              //     </IconButton>}
            >
              {error &&
                error.length > 0 &&
                error.map((str) => <div style={{ fontSize: 12 }}>* {str}</div>)}
            </Alert>
          </Collapse>

          <Collapse in={open}>
            <Alert icon={false} variant="filled" severity="success">
              User invited successfully.
            </Alert>
          </Collapse>
        </Box>
      </Grid>

      <Box mt={2} className="team-header">
        <Grid container justify="space-between">
          <Grid item>Users</Grid>
          {/* <Grid item>{ users.length }</Grid> */}
        </Grid>
      </Box>

      <Box mt={1} className="teams-list">
        {isCollaboration && (
          <Grid container className="team-row" key={host.id}>
            {/* <Grid xs={1} item>1</Grid> */}
            <Grid item>
              <Avatar
                variant="circular"
                className="avatar-small"
                alt={host.name}
                src={`${host.company_logo}`}
              />
            </Grid>
            <Grid xs={7} item className="team-name">
              {host.name}
            </Grid>
            <Grid xs={2} item className="team-member">
              Host
            </Grid>
          </Grid>
        )}
        {users &&
          users.map((user) => {
            // console.log(user.id, owner.id)
            return (
              <Grid container className="team-row" key={user.id}>
                {/* <Grid xs={1} item>1</Grid> */}
                <Grid item>
                  <Avatar
                    variant="circular"
                    className="avatar-small"
                    alt={user.first_name}
                    src={`${user.profile_image}`}
                  />
                </Grid>
                <Grid xs={7} item className="team-name">
                  {user.first_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.username}
                </Grid>
                <Grid xs={2} item className="team-member">
                  {user.id === owner?.id ? "Owner" : "Member"}
                </Grid>
              </Grid>
            );
          })}

        {pending &&
          pending.map((email) => {
            return (
              <Grid container className="team-row" key={email}>
                {/* <Grid xs={1} item>1</Grid> */}
                <Grid item>
                  <Avatar variant="circular" className="avatar-small">
                    {email.charAt(0).toUpperCase()}
                  </Avatar>
                </Grid>
                <Grid xs={6} item className="team-name">
                  {email}
                </Grid>
                <Grid xs={3} item className="team-member">
                  Pending
                </Grid>
              </Grid>
            );
          })}
      </Box>
    </div>
  );
};

export default TeamPopup;
