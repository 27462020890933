import React from "react";
import { Box } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import "./style.css";
import Row from "./Row";

const ExperimentRow = (props) => {
  return (
    <>
      {props.expIndex === 0 && (
        <FiberManualRecordIcon
          className="competition-experiments-crown"
          style={{
            color: "#3576A7",
            fontSize: "14px",
            marginLeft: "3.5px",
            marginTop: "4px",
          }}
        />
      )}
      <Box className={`experiment-view`}>
        <Row {...props} />
      </Box>
    </>
  );
};

export default ExperimentRow;
