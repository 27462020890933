import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import CopyToClipboard from "react-copy-to-clipboard";

import { Box, Portal, Snackbar } from "@material-ui/core";
import { parseJsonString } from "utils";
import { Alert } from "@material-ui/lab";
import { NotificationCheckIcon } from "Components/Icon";
import NoteBook from "Components/NoteBook";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DatasetDialog = ({ open, closeCallback, dataset }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const handleClose = () => {
    closeCallback();
  };

  const copyDatasetId = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    console.log("Clicked");
  };

  const showCopyMessage = (e) => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {dataset.title}
            </Typography>
            <Typography
              onClick={copyDatasetId}
              variant="h6"
              className={classes.title}
              style={{ cursor: "pointer" }}>
              <CopyToClipboard
                text={dataset.dataset_key}
                onCopy={showCopyMessage}>
                <span>{dataset.dataset_key}</span>
              </CopyToClipboard>
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box p={2}>
          {<NoteBook json={dataset?.eda_pdf} />}
        </Box>
      </Dialog>

      <Portal>
        {copied && (
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <Alert
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              variant="filled"
              severity="success">
              Dataset ID successfully copied
            </Alert>
          </Snackbar>
        )}
      </Portal>
    </>
  );
};

export default DatasetDialog;
