import axios from "axios";
import { API_URL, getAuthConfig } from "./config";

export const datasetService = {
  createDataset: async ({
    ratio,
    defects,
    voltages,
    parts,
    material,
    company,
  }) => {
    const filteredVoltages = voltages.filter((item) => item.label !== "all");
    const filteredParts = parts.filter((item) => item.label !== "all");
    const filteredMaterial = material.filter((item) => item.label !== "all");

    const defectsString =
      defects &&
      defects.map((item) => `${item.label}=${item.diameter}`).join("&");
    const voltageString =
      filteredVoltages?.length > 0 &&
      `voltage=${filteredVoltages
        .map((item) => item.label.slice(0, -2))
        .join(",")}`;
    const partsString =
      filteredParts?.length > 0 &&
      `part=${filteredParts.map((item) => item.label).join(",")}`;
    const materialString =
      filteredMaterial?.length > 0 &&
      `material=${filteredMaterial.map((item) => item.label).join(",")}`;
    const companyString =
      company?.length > 0 &&
      `company=${company.map((item) => item.label).join(",")}`;

    return axios.get(
      `${API_URL}/createdataset/?ratio=${ratio}&${defectsString}&${voltageString}&${partsString}&${materialString}&${companyString}`,
      getAuthConfig()
    );
  },

  createMedicalDataset: async ({ ratio, classes, company, type, category }) => {
    const classesString =
      classes && `classes=${classes.map((item) => item.label).join(",")}`;
    const companyString =
      company?.length > 0 &&
      `company=${company.map((item) => item.label).join(",")}`;
    const typeString = `type=${type}`;

    return axios.get(
      `${API_URL}/create-medical-xray-dataset/?ratio=${ratio}&${classesString}&${companyString}&${typeString}&category=${category}`,
      getAuthConfig()
    );
  },

  saveDataset: async (title, imageSamples, dataset_type, access_type) => {
    const data = { title, access_type };
    if (dataset_type === "industrial") {
      data.data_samples = imageSamples;
    }

    return axios.post(`${API_URL}/dataset/`, data, getAuthConfig());
  },

  getUserDatasets: async (page) => {
    return axios.get(
      `${API_URL}/dataset/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },
};
