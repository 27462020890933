import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

import { GlobalContext } from "Context/GlobalState";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./style.css";
import { collaborationsService } from "services/api/collaborations";

export default function Admin(props) {
  const { updateCollaboration } = useContext(GlobalContext);
  const {
    dataset,
    invited_users,
    isPrivate,
    flops,
    description,
    allow_experiments,
    competition_rules_template,
    announced_date,
    start_date,
    team_merger_date,
    final_submission_date,
    end_date,
    privacy_type,
  } = props;

  // Competition Configuration States
  const [flopsLimit, setFlopsLimit] = useState(flops / 1e12);
  const [competitionDescription, setCompetitionDescription] =
    useState(description);
  const [edaPdf, setEdaPdf] = useState(null);
  const [configErrors, setConfigErrors] = useState({
    flops: "",
    description: "",
    edaFile: "",
  });

  // Experiment Control States
  const [allowExperiments, setAllowExperiments] = useState(allow_experiments);
  const [isExperimentLoading, setIsExperimentLoading] = useState(false);

  // Invited Users States
  const [users, setUsers] = useState(invited_users || []);
  const [emailInput, setEmailInput] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // Add new state for flops loading
  const [flopsLoading, setFlopsLoading] = useState(false);

  // Add new state for configuration loading
  const [configLoading, setConfigLoading] = useState(false);

  // Add new state for competition rules
  const [competitionRules, setCompetitionRules] = useState(
    competition_rules_template
  );

  // Add new state for rules loading
  const [rulesLoading, setRulesLoading] = useState(false);

  // Add new state for rules errors
  const [rulesErrors, setRulesErrors] = useState("");

  // Collaboration Dates States
  const [announcedDate, setAnnouncedDate] = useState(new Date(announced_date));
  const [startDate, setStartDate] = useState(new Date(start_date));
  const [teamMergerDate, setTeamMergerDate] = useState(
    new Date(team_merger_date)
  );
  const [finalSubmissionDate, setFinalSubmissionDate] = useState(
    new Date(final_submission_date)
  );
  const [endDate, setEndDate] = useState(new Date(end_date));
  const [datesLoading, setDatesLoading] = useState(false);

  // Add new state for privacy type
  const [privacyType, setPrivacyType] = useState(privacy_type || "public");
  const [privacyLoading, setPrivacyLoading] = useState(false);

  // Add new state for flops error message
  const [flopsError, setFlopsError] = useState(null);

  // Add useEffect to update allowExperiments when prop changes
  useEffect(() => {
    setAllowExperiments(allow_experiments);
  }, [allow_experiments]);

  // Collaboration Configuration Functions
  const validateInputs = () => {
    const newErrors = {};
    let isValid = true;

    if (!flopsLimit || flopsLimit <= 0) {
      newErrors.flops = "Valid FLOPS limit is required";
      isValid = false;
    }

    if (!competitionDescription?.trim()) {
      newErrors.description = "Description is required";
      isValid = false;
    }

    setConfigErrors(newErrors);
    return isValid;
  };

  const updateFlopsConfiguration = async () => {
    if (!validateInputs()) return;
    setFlopsLoading(true);
    setFlopsError(null); // Clear previous error
    try {
      const response = await collaborationsService.updateFlopsConfiguration(
        dataset,
        flopsLimit
      );
      if (response.status === 200) {
        updateCollaboration({ ...response.data });
      }
    } catch (error) {
      console.error("Error updating Flops:", error.response.data);

      if (error.response?.status === 400) {
        setFlopsError(Object.values(error.response?.data));
      } else if (error.response?.status === 500) {
        setFlopsError([
          "Something went wrong on our end. We are working hard to fix it as soon as possible.",
        ]);
      } else {
        setFlopsError([
          "Failed to update FLOPS configuration. Please try again.",
        ]);
      }
      
    } finally {
      setFlopsLoading(false);
    }
  };

  const updateConfiguration = async () => {
    if (!validateInputs()) return;
    setConfigLoading(true);
    try {
      const response = await collaborationsService.updateConfiguration(
        dataset,
        flopsLimit,
        competitionDescription,
        edaPdf
      );
      if (response.status === 200) {
        updateCollaboration({ ...response.data });
      }
    } catch (error) {
      console.error("Error updating configuration:", error);
    } finally {
      setConfigLoading(false);
    }
  };

  // Experiment Control Functions
  const handleExperimentToggle = async (event) => {
    setIsExperimentLoading(true);
    const newState = event.target.checked;
    try {
      const response = await collaborationsService.toggleExperimentControl(
        dataset,
        newState
      );
      if (response.status === 202 || response.status === 200) {
        setAllowExperiments(response.data.allow_experiments);
        updateCollaboration({ ...response.data });
      }
    } catch (error) {
      console.error("Error toggling experiments:", error);
      setAllowExperiments(newState); // Revert switch state on error
    } finally {
      setIsExperimentLoading(false);
    }
  };

  // Invited Users Functions
  const handleInvite = async () => {
    setInviteLoading(true);
    const newEmails = emailInput
      .split(",")
      .map((email) => email.trim())
      .filter(Boolean);
    if (newEmails.length === 0) return;
    try {
      const response = await collaborationsService.inviteUsers(
        dataset,
        newEmails.toString()
      );
      if (response.status === 200) {
        setUsers(response.data["invited_users"]);
        setEmailInput("");
        updateCollaboration({ ...response.data });
      }
    } catch (error) {
      console.error("Error inviting users:", error);
    } finally {
      setInviteLoading(false);
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await collaborationsService.deleteUser(
        dataset,
        selectedEmail
      );
      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.email !== selectedEmail)
        );
        updateCollaboration({ ...response.data });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setDeleteLoading(false);
      setOpenDialog(false);
    }
  };

  // Function to update Collaboration rules
  const updateCompetitionRules = async () => {
    if (!competitionRules.trim()) {
      setRulesErrors("Rules are required");
      return;
    }
    setRulesLoading(true);
    try {
      const response = await collaborationsService.updateCompetitionRules(
        dataset,
        competitionRules
      );
      if (response.status === 200) {
        updateCollaboration({ ...response.data });
      }
    } catch (error) {
      console.error("Error updating Collaboration rules:", error);
    } finally {
      setRulesLoading(false);
    }
  };

  const updateCollaborationDates = async () => {
    setDatesLoading(true);
    const dates = {
      announced_date: announcedDate,
      start_date: startDate,
      team_merger_date: teamMergerDate,
      final_submission_date: finalSubmissionDate,
      end_date: endDate,
    };
    try {
      const response = await collaborationsService.updateCollaborationDates(
        dataset,
        dates
      );
      if (response.status === 200) {
        updateCollaboration({ ...response.data });
      }
    } catch (error) {
      console.error("Error updating collaboration dates:", error);
    } finally {
      setDatesLoading(false);
    }
  };

  const updatePrivacyType = async (newPrivacyType) => {
    setPrivacyLoading(true);
    try {
      const response = await collaborationsService.updatePrivacyType(
        dataset,
        newPrivacyType
      );
      if (response.status === 200) {
        setPrivacyType(newPrivacyType);
        updateCollaboration({ ...response.data });
      }
    } catch (error) {
      console.error("Error updating privacy type:", error);
    } finally {
      setPrivacyLoading(false);
    }
  };

  return (
    <Box p={3}>
      {/* Collaboration Configuration Section */}
      {isPrivate && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap={4}
          mt={2}
          mb={3}>
          {/* Left Column - Per Team Budget */}
          <Paper className="admin-card config-card">
            <Typography className="card-header" variant="h6" gutterBottom>
              Computation Budget per Team
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ marginBottom: 0 }}>
              Enter compute budget in TFLOP
            </Typography>
            <Box mt={1} mb={1}>
              <TextField
                type="number"
                label=""
                variant="outlined"
                margin="dense"
                placeholder="e.g 1000"
                style={{ width: "100%", fontSize: 20, fontWeight: "bold" }}
                value={flopsLimit}
                onChange={(e) => {
                  setFlopsLimit(e.target.value);
                  setConfigErrors((prev) => ({ ...prev, flops: "" }));
                }}
                error={!!configErrors.flops}
                InputProps={{
                  style: {
                    fontSize: 20,
                    fontWeight: "bold",
                  },
                  endAdornment: (
                    <>
                      <InputAdornment className="flops-label" position="end">
                        TFLOP
                      </InputAdornment>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={updateFlopsConfiguration}
                        disabled={
                          !flopsLimit || configErrors.flops || flopsLoading
                        }
                        style={{
                          borderTopRightRadius: 3,
                          borderBottomRightRadius: 3,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          marginLeft: 8,
                          marginTop: 0,
                          marginBottom: 0,
                          marginRight: -13,
                          padding: "4px 0px",
                          minWidth: flopsLoading ? "130px" : "100px",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}>
                        {flopsLoading ? "Applying..." : "Apply"}
                      </Button>
                    </>
                  ),
                }}
              />
            </Box>
            {flopsError && flopsError.length > 0 && flopsError.map((str) => (
                <Typography color="error" variant="caption">
                  {str}
                </Typography>
              ))}
            <Box mt={4}>
              <Typography variant="body2" color="textPrimary">
                Total: ${" "}
                <span style={{ fontWeight: "bold" }}>
                  {(flopsLimit * 0.0549).toFixed(2)}
                </span>{" "}
                per team
              </Typography>
            </Box>
          </Paper>

          {/* Middle Column - Total Budget */}
          <Paper className="admin-card config-card">
            <Typography className="card-header" variant="h6" gutterBottom>
              Total Compute Budget
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ marginBottom: 12 }}>
              Max compute budget for this collaboration
            </Typography>
            <Typography
              className="budget-display"
              variant="h4"
              style={{ marginBottom: 12 }}>
              {flopsLimit * (users.length || 1)} TFLOP | ${" "}
              {(flopsLimit * (users.length || 1) * 0.0549).toFixed(2)}
            </Typography>
            <Typography
              style={{ marginTop: 32 }}
              variant="body2"
              color="textPrimary">
              Compute Budget per Team x Invited Users
            </Typography>
          </Paper>

          {/* Right Column - Switch */}
          <Paper className="admin-card config-card">
            <Typography className="card-header" variant="h6" gutterBottom>
              Experiments Control
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ marginBottom: 12 }}>
              Control training & inference of user's models
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                className="budget-display"
                variant="body2"
                color="textPrimary">
                {allowExperiments
                  ? "Allow training & inference"
                  : "Restrict training & inference"}
              </Typography>
              <Switch
                checked={allowExperiments}
                onChange={handleExperimentToggle}
                disabled={isExperimentLoading}
                color="primary"
                className="experiment-switch"
              />
            </Box>
            <Typography
              variant="body2"
              className={
                allowExperiments ? "switch-status-on" : "switch-status-off"
              }
              style={{ marginTop: 32 }}>
              {allowExperiments
                ? "Teams can run experiments"
                : "Teams cannot run experiments"}
            </Typography>
          </Paper>
        </Box>
      )}

      {/* Invited Users Section */}
      {isPrivate && (
        <>
          <Paper
            className="admin-card"
            style={{ marginBottom: 24, padding: 24 }}>
            <Typography
              className="card-header"
              variant="h6"
              style={{ marginBottom: 24 }}
              gutterBottom>
              Invited Users
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                label="Emails (comma-separated)"
                variant="outlined"
                style={{ flexGrow: 1 }}
                value={emailInput}
                margin="dense"
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder="email1@example.com, email2@example.com"
                InputProps={{
                  endAdornment: (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleInvite}
                      disabled={!emailInput.trim() || inviteLoading}
                      style={{
                        borderRadius: 3,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        marginTop: -1,
                        marginBottom: -1,
                        marginRight: -14,
                        padding: "7px 18px",
                      }}>
                      {inviteLoading ? "Inviting..." : "Invite"}
                    </Button>
                  ),
                }}
              />
            </Box>

            {users.length > 0 && (
              <Box className="users-table-container">
                <Table className="users-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Access</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.email}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {user.first_name || user.last_name
                            ? `${user.first_name || ""} ${user.last_name || ""}`
                            : " - "}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setSelectedEmail(user.email);
                                setOpenDialog(true);
                              }}>
                              <DeleteIcon color="textSecondary" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this user?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleDelete}
                  color="primary"
                  disabled={deleteLoading}>
                  {deleteLoading ? "Deleting..." : "Confirm"}
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </>
      )}

      {/* Description Card */}
      <Paper className="admin-card" style={{ padding: 24, marginBottom: 24 }}>
        <Typography
          className="card-header"
          variant="h6"
          style={{ marginBottom: 20 }}
          gutterBottom>
          Collaboration Description
        </Typography>
        <CKEditor
          editor={ClassicEditor}
          data={competitionDescription}
          onChange={(event, editor) => {
            setCompetitionDescription(editor.getData());
            setConfigErrors((prev) => ({ ...prev, description: "" }));
          }}
        />
        {configErrors.description && (
          <Typography color="error" variant="caption">
            {configErrors.description}
          </Typography>
        )}
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            className="apply-button"
            variant="contained"
            color="primary"
            onClick={updateConfiguration}
            disabled={configLoading}>
            {configLoading ? "Updating..." : "Update Description"}
          </Button>
        </Box>
      </Paper>

      {/* Collaboration Rules Card */}
      <Paper
        className="admin-card"
        style={{ padding: 24, marginBottom: 24, marginTop: 24 }}>
        <Typography
          className="card-header"
          variant="h6"
          style={{ marginBottom: 20 }}
          gutterBottom>
          Collaboration Rules
        </Typography>
        <CKEditor
          editor={ClassicEditor}
          data={competitionRules}
          onChange={(event, editor) => {
            setCompetitionRules(editor.getData());
            setRulesErrors("");
          }}
        />
        {rulesErrors && (
          <Typography color="error" style={{ fontWeight: 500 }} variant="caption">
            {rulesErrors}
          </Typography>
        )}
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            className="apply-button"
            variant="contained"
            color="primary"
            onClick={updateCompetitionRules}
            disabled={rulesLoading}>
            {rulesLoading ? "Updating..." : "Update Rules"}
          </Button>
        </Box>
      </Paper>

      {/* Collaboration Dates Section */}
      <Paper
        className="admin-card"
        style={{ padding: 24, marginBottom: 24, marginTop: 24 }}>
        <Typography
          className="card-header"
          variant="h6"
          style={{ marginBottom: 20 }}
          gutterBottom>
          Collaboration Dates
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          style={{ flexWrap: "wrap" }}>
          <Paper className="date-card">
            <Typography className="date-label">Announced Date</Typography>
            <TextField
              className="date-input"
              type="datetime-local"
              value={announcedDate.toISOString().slice(0, 16)}
              onChange={(e) => setAnnouncedDate(new Date(e.target.value))}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="date-description">
              The date when the collaboration is announced.
            </Typography>
          </Paper>

          <Paper className="date-card">
            <Typography className="date-label">Start Date</Typography>
            <TextField
              className="date-input"
              type="datetime-local"
              value={startDate.toISOString().slice(0, 16)}
              onChange={(e) => setStartDate(new Date(e.target.value))}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="date-description">
              The date when the collaboration starts.
            </Typography>
          </Paper>

          <Paper className="date-card">
            <Typography className="date-label">Team Merger Date</Typography>
            <TextField
              className="date-input"
              type="datetime-local"
              value={teamMergerDate.toISOString().slice(0, 16)}
              onChange={(e) => setTeamMergerDate(new Date(e.target.value))}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="date-description">
              The deadline for team mergers.
            </Typography>
          </Paper>

          <Paper className="date-card">
            <Typography className="date-label">
              Final Submission Date
            </Typography>
            <TextField
              className="date-input"
              type="datetime-local"
              value={finalSubmissionDate.toISOString().slice(0, 16)}
              onChange={(e) => setFinalSubmissionDate(new Date(e.target.value))}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="date-description">
              The deadline for final submissions.
            </Typography>
          </Paper>

          <Paper className="date-card">
            <Typography className="date-label">End Date</Typography>
            <TextField
              className="date-input"
              type="datetime-local"
              value={endDate.toISOString().slice(0, 16)}
              onChange={(e) => setEndDate(new Date(e.target.value))}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="date-description">
              The date when the collaboration ends.
            </Typography>
          </Paper>
        </Box>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            className="apply-button"
            variant="contained"
            color="primary"
            onClick={updateCollaborationDates}
            disabled={datesLoading}>
            {datesLoading ? "Updating..." : "Update Dates"}
          </Button>
        </Box>
      </Paper>

      <Box display="flex" gap={3} mb={3}>
        {/* EDA Card */}
        <Paper
          className="admin-card config-card"
          style={{ flex: 1, padding: 24 }}>
          <Typography
            className="card-header"
            variant="h6"
            style={{ marginBottom: 20 }}
            gutterBottom>
            Exploratory Data Analysis
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ marginBottom: 16 }}
            gutterBottom>
            Please upload a JSON file for EDA.
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <input
              accept=".json,.pdf"
              style={{ display: "none" }}
              id="eda-file-upload"
              type="file"
              onChange={(e) => setEdaPdf(e.target.files[0])}
            />
            <label htmlFor="eda-file-upload">
              <Button
                className="upload-button"
                variant="outlined"
                component="span"
                color="primary">
                Select EDA
              </Button>
            </label>
            {edaPdf && (
              <Typography variant="body2" color="textSecondary">
                Selected file: {edaPdf.name}
              </Typography>
            )}
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              className="apply-button"
              variant="contained"
              color="primary"
              onClick={updateConfiguration}
              disabled={configLoading || !edaPdf}>
              {configLoading ? "Uploading..." : "Upload EDA"}
            </Button>
          </Box>
        </Paper>

        {/* Privacy Type Card */}
        <Paper
          className="admin-card config-card"
          style={{ flex: 1, padding: 24 }}>
          <Typography
            className="card-header"
            variant="h6"
            style={{ marginBottom: 20 }}
            gutterBottom>
            Privacy Settings
          </Typography>

          <FormControl variant="outlined" fullWidth className="privacy-select">
            <InputLabel>Privacy Type</InputLabel>
            <Select
              value={privacyType}
              onChange={(e) => setPrivacyType(e.target.value)}
              label="Privacy Type"
              disabled={privacyLoading}>
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="company">Company</MenuItem>
            </Select>
          </FormControl>
          <Typography
            className="privacy-description"
            variant="body2"
            color="textSecondary"
            style={{ marginTop: 16 }}>
            {privacyType === "public"
              ? "Anyone can view and participate in this collaboration"
              : "Only invited company members can access this collaboration"}
          </Typography>
          <Box mt="auto" display="flex" justifyContent="flex-end">
            <Button
              className="apply-button"
              variant="contained"
              color="primary"
              onClick={() => updatePrivacyType(privacyType)}
              disabled={privacyLoading}>
              {privacyLoading ? "Updating..." : "Update Privacy"}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
