import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  makeStyles,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import React, { useRef, useState } from "react";
import { Alert } from "@material-ui/lab";
import { updateTeamName } from "services/api/collaborations";

import "./style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: "1px solid #e6e6e6",
  },
  input: {
    marginRight: "11px",
    flex: 1,
    fontSize: 12,
    backgroundColor: "white",
    width: "149px",
    height: "32px",
    border: "1px solid #E6E6E6",
    borderRadius: "5px",
    padding: "3px 7px",
  },
  inviteButton: {
    padding: 4,
    minWidth: "65px",
    backgroundColor: "var(--primary)",
    color: "white",
    fontSize: 10,
    textTransform: "capitalize",
    "&.Mui-disabled": {
      opacity: 0.5,
      color: "white",
    },
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "var(--primary)",
    },
  },
  divider: {
    height: 20,
    margin: 4,
  },
}));

const TeamEditPopup = ({
  title,
  team,
  datasetId,
  datasetKey,
  isCollaboration,
  onUpdateTeam,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [enableInvite, setEnableInvite] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const inputRef = useRef(null);
  const classes = useStyles();

  const handleTeamNameUpdate = async (name) => {
    setLoading(true);
    setOpen(false);
    setError(null);

    try {
      const response = await updateTeamName(team.id, name);
      setLoading(false);
      setError(null);
      setOpen(true);
      onUpdateTeam(response.data);
    } catch (error) {
      setOpen(false);
      if (error.response?.status === 400) {
        setError(Object.values(error.response?.data));
      } else if (error.response?.status === 500) {
        setError([
          "Something went wrong on our end. We are working hard to fix it as soon as possible.",
        ]);
      } else {
        setError(["Make sure you are connected to the internet."]);
      }
      setLoading(false);
    }
  };

  const handleInvite = () => {
    const name = inputRef.current.value.trim();
    if (name) {
      handleTeamNameUpdate(name);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleInvite();
    }
  };

  const onInputChange = (e) => {
    const name = e.target.value.trim();
    setEnableInvite(name.length > 2);
    setShowMessage(name.length < 3);
  };

  return (
    <div className="team-popup">
      <div
        style={{
          color: "var(--textPrimary)",
          fontSize: 12,
          marginLeft: 4,
          marginBottom: 16,
        }}>
        {title || `Update team name`}
      </div>

      <Grid container direction="row">
        <Grid item>
          <InputBase
            onChange={onInputChange}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            className={classes.input}
            placeholder="Enter team name"
            inputProps={{ "aria-label": "Enter team name" }}
          />
        </Grid>

        <Grid className="gradient-button" style={{ minWidth: "65px" }} item>
          <Button
            disabled={!enableInvite || loading}
            onClick={handleInvite}
            color="secondary"
            style={{ minWidth: "65px" }}
            aria-label="update-team-name">
            {loading ? (
              <CircularProgress color="inherit" size={12} />
            ) : (
              "Update"
            )}
          </Button>
        </Grid>

        <Box>
          {showMessage && (
            <span
              style={{
                color: "#e31010",
                fontSize: 9,
                marginLeft: 4,
                marginBottom: 16,
              }}>
              * team name needs to be at least 3 characters long.
            </span>
          )}
        </Box>

        <Box mt={1} className={"inner-message"}>
          <Collapse in={error !== null}>
            <Alert severity="error" icon={false}>
              {error &&
                error.length > 0 &&
                error.map((str) => <div style={{ fontSize: 12 }}>* {str}</div>)}
            </Alert>
          </Collapse>

          {error === null && (
            <Collapse in={open}>
              <Alert icon={false} variant="filled" severity="success">
                Team name edited successfully.
              </Alert>
            </Collapse>
          )}
        </Box>
      </Grid>
    </div>
  );
};

export default TeamEditPopup;
