import { CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./style.css";
import { authService } from "services/api/auth";

const COUNT_DOWN_SEC = 30;

const ThankYou = (props) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(COUNT_DOWN_SEC);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const resendVerification = () => {
    setLoading(true);

    authService
      .resendVerification(props.token)
      .then(() => {
        setError(null);
        setSuccess(true);
        setLoading(false);
        setCountdown(COUNT_DOWN_SEC);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data || "An error occurred. Please try again.";
        setError({ message: errorMessage });
        setLoading(false);
      });
  };

  const showCountdown = () => {
    return countdown > 0 ? (
      <span>Resend verification in {countdown} seconds</span>
    ) : (
      <span
        onClick={resendVerification}
        style={{ cursor: "pointer", color: "blue" }}>
        Resend verification mail.
      </span>
    );
  };

  return (
    <Grid className="form" container direction="column">
      <Grid item>
        <h3>Thank you for signing up!</h3>
        <h3 style={{ color: "#212529", marginBottom: "24px" }}>
          Please check your email and verify your account.
        </h3>
      </Grid>

      <Grid item>
        <img
          style={{ marginBottom: "24px" }}
          alt="email sent"
          src="/images/email_sent.svg"
        />
      </Grid>

      {error && (
        <Grid item>
          <div className="error-message">{error.message}</div>
        </Grid>
      )}
      {success && (
        <Grid item>
          <div className="success-message">
            Verification mail resent successfully.
          </div>
        </Grid>
      )}
      <Grid className="resend-verification" item>
        Didn't get the email? Please check your spam/junk folder.
      </Grid>

      <Grid style={{ lineHeight: "22px" }} className="resend-verification" item>
        {!loading ? showCountdown() : <CircularProgress size={16} />}
      </Grid>
    </Grid>
  );
};

export default ThankYou;
