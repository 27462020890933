import React from "react";
import "./style.css";
import { Grid } from "@material-ui/core";

const TrainingPlan = (props) => {
  const trainingParams =
    props.experiment &&
    Object.entries(props.experiment.training_parameters).map(([key, value]) => {
      return (
        <Grid key={key} xs={3} item className="item">
          <span className="head">{key}</span>
          <span title={value} className="normal-text">
            {value !== "" ? `${value}` : "Nil"}
          </span>
        </Grid>
      );
    });

  const augmentationParams =
    props.experiment &&
    Object.entries(props.experiment.augmentation_parameters).map(
      ([key, value]) => {
        return (
          <Grid key={key} xs={3} item className="item">
            <span className="head">{key}</span>
            <span title={value} className="normal-text">
              {value !== "" ? `${value}` : "Nil"}
            </span>
          </Grid>
        );
      }
    );

  const hyperParams =
    props.experiment &&
    Object.entries(props.experiment.hyperparameters).map(([key, value]) => {
      return (
        <Grid key={key} xs={3} item className="item">
          <span className="head">{key}</span>
          <span title={value} className="normal-text">
            {value !== "" ? `${value}` : "Nil"}
          </span>
        </Grid>
      );
    });

  const datasetParams =
    props.experiment &&
    Object.entries(props.experiment.dataset_parameters).map(([key, value]) => {
      return (
        <Grid key={key} xs={3} item className="item">
          <span className="head">{key}</span>
          <span title={value} className="normal-text">
            {value !== "" ? `${value}` : "Nil"}
          </span>
        </Grid>
      );
    });

  return (
    <div className={`training-plan ${props.expanded ? "expand" : ""}`}>
      {props.expanded && props.experiment && (
        <>
          <h3>Training Parameters</h3>
          <Grid container className="detailed-list">
            {trainingParams}
          </Grid>
          <h3>Hyperparameters</h3>
          <Grid container className="detailed-list">
            {hyperParams}
          </Grid>
          <h3>Augmentation Parameters</h3>
          <Grid container className="detailed-list">
            {augmentationParams}
          </Grid>
          <h3>Dataset Parameters</h3>
          <Grid container className="detailed-list">
            {datasetParams}
          </Grid>
        </>
      )}
    </div>
  );
};

export default TrainingPlan;
