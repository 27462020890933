import axios from "axios";
import { API_URL, getAuthConfig } from "./config";

export const authService = {
  login: async ({ username, password, consent }) => {
    return axios.post(`${API_URL}/api-token-auth/`, {
      username,
      password,
      consent,
    });
  },

  logout: async () => {
    const headers = getAuthConfig();
    localStorage.removeItem("_key_usr_tkn");
    return axios.post(`${API_URL}/logout/`, null, headers);
  },

  getUserInfo: async () => {
    return axios.get(`${API_URL}/userinfo/`, getAuthConfig());
  },

  register: async (registrationData) => {
    return axios.post(`${API_URL}/register/`, {
      ...registrationData,
      confirm_password: registrationData.password,
    });
  },

  updatePassword: async (newPassword) => {
    const config = {
      method: "PATCH",
      url: `${API_URL}/change-password/`,
      data: { new_password: newPassword },
      ...getAuthConfig(),
    };

    return axios(config);
  },
  getUserSummary: async (userId) => {
    const config = {
      method: "GET",
      url: `${API_URL}/usersummary/${userId}/`,
      data: { claps: 1 },
      ...getAuthConfig(),
    };

    return axios(config);
  },

  updateProfileImage: async (formData) => {
    const config = {
      method: "PATCH",
      url: `${API_URL}/userinfo/`,
      data: formData,
      headers: {
        ...getAuthConfig().headers,
        "Content-Type": "multipart/form-data",
      },
    };

    return axios(config);
  },

  resendVerification: async (token) => {
    const config = {
      method: "GET",
      url: `${API_URL}/resend-verification-link/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    return axios(config);
  },
};

export const resetPassword = async (password, token) => {
  const config = {
    method: "POST",
    url: `${API_URL}/password_reset/confirm/`,
    data: { password, token },
  };

  return axios(config);
};

export const updateUserProfile = async (formData) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}/userinfo/`,
    data: formData,
    ...getAuthConfig(),
  };

  return axios(config);
};
