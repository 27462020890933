import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Divider } from "@material-ui/core";
import CollaborationChip from "Components/CollaborationChip";
import {
  formatDateWithTimeZone,
  getDateDifferenceInDays,
  numberWithSpaces,
} from "utils/format";
import "./style.css";

export default function CollaborationCard({
  competition,
  index,
  key,
  onClick,
}) {
  const totalDaysOfCollaboration = getDateDifferenceInDays(
    competition.start_date,
    competition.final_submission_date
  );
  const remainingDaysOfCollaboration =
    totalDaysOfCollaboration -
    getDateDifferenceInDays(
      new Date().toISOString(),
      competition.final_submission_date
    );

  const renderLabels =
    competition.labels &&
    competition.labels.map(({ name, description, id }, index) => {
      const iconUrl =
        "https://tracebloc-develop-s3-bucket.s3.eu-central-1.amazonaws.com/xrayimagedata/collab/icons";
      const icon = { src: `${iconUrl}/${description}.svg`, height: 7 };
      return (
        <CollaborationChip
          marginTop={6}
          marginLeft={2}
          marginRight={2}
          key={name}
          icon={
            <img
              style={{
                width: "9px",
                height: "auto",
                marginLeft: 3,
                marginRight: 3,
              }}
              alt={"icon"}
              src={icon.src}
            />
          }
          text={`${
            description === "teams"
              ? `${competition?.teams.length} Teams`
              : name
          }`}
        />
      );
    });

  const progressStatusColor = () => {
    if (competition?.progress_status === "Upcoming") {
      return "#4BBDCC";
    } else if (competition?.progress_status === "Finished") {
      return "#FF0000";
    } else if (competition?.progress_status === "Preparation") {
      return "#EAC706";
    } else if (competition?.progress_status === "Active") {
      return "#5DD39E";
    }
  };

  const prizeTypeColor = () => {
    if (competition?.prize_type === "money") {
      return "#4BBDCC";
    } else if (competition?.prize_type === "knowledge") {
      return "#FFA700";
    } else if (competition?.prize_type === "flops") {
      return "#9439CD";
    }
    return "#9439CD";
  };

  const prizeLabel = () => {
    if (competition?.prize_type === "money") {
      return numberWithSpaces(parseFloat(competition?.prize).toFixed(0)) + " €";
    } else if (competition?.prize_type === "flops") {
      return "Computation";
    } else if (competition?.prize_type === "evaluation") {
      return "Evaluation";
    }
    return competition?.prize_type;
  };

  return (
    <Box
      className="competition-card"
      onClick={onClick}
      key={key}
      style={{
        borderRadius: 6,
        overflow: "hidden",
        width: 302,
        borderWidth: 1,
        borderColor: "#E2E2E2",
        borderStyle: "solid",
        backgroundColor: "white",
        cursor: "pointer",
        position: "relative",
      }}>
      {competition?.is_sustainable && (
        <img
          src={"/images/icons/sustainability-filled.svg"}
          alt={competition?.host_company?.name}
          height={20}
          title={"Sustainable competition"}
          style={{
            position: "absolute",
            height: 20,
            width: "auto",
            right: 24,
            top: 12,
          }}
        />
      )}

      <img
        src={
          competition?.competition_thumbnail
            ? competition?.competition_thumbnail
            : "/images/pic1.png"
        }
        alt="competition"
        width={302}
        height={142}
        style={{
          objectFit: "cover",
        }}
      />

      {/* Logo + prize details*/}

      <Box
        style={{
          display: "flex",
          marginTop: 14,
          marginLeft: 23.89,
          marginRight: 16,
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        {/* <Box> */}
        <img
          src={competition?.host_company?.company_logo}
          alt={competition?.host_company?.name}
          height={19}
          style={{
            objectFit: "contain",
          }}
        />
        {/* </Box> */}
        <Box
          style={{
            backgroundColor: prizeTypeColor(),
            marginRight: 8,
            marginLeft: 8,
            marginBottom: 4,
            borderRadius: 2,
            height: 24,
            minWidth: 40,
            padding: "2px 8px",
          }}>
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "white",
              lineHeight: "20px",
              textTransform: "Capitalize",
            }}>
            {prizeLabel()}
          </Typography>
        </Box>
      </Box>

      {/* Title + description */}
      <Box
        style={{
          marginLeft: 25,
          marginRight: 24,
          marginTop: 9,
        }}>
        <Typography
          style={{
            color: "#3576A7",
            fontStyle: "italic",
            lineHeight: "1.1em",
            height: "2.3em",
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "left",
            letterSpacing: "0px",
            lineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
            display: "-webkit-box",
            boxOrient: "vertical",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "2",
          }}
          title={competition?.title}>
          {competition?.title}
        </Typography>

        <Typography
          style={{
            color: "#707070",
            fontStyle: "italic",
            lineHeight: "1.2em",
            height: "2.4em",
            fontSize: "12px",
            textAlign: "left",
            marginTop: "14px",
            letterSpacing: "0px",
            lineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
            display: "-webkit-box",
            boxOrient: "vertical",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "2",
          }}
          title={competition?.goal}>
          {competition?.goal}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}>
        <Divider style={{ flexGrow: 1 }} light />
        <Typography
          variant="body1"
          style={{
            margin: "0 8px",
            fontSize: "12px",
            fontWeight: 500,
            color: "#707070",
          }}>
          {competition?.privacy_type === "company"
            ? "Private Collaboration"
            : "Open Collaboration"}
        </Typography>
        <Divider style={{ flexGrow: 1 }} light />
      </Box>

      <Box
        style={{
          minHeight: 105,
        }}>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: 20,
            marginRight: 20,
          }}>
          {renderLabels}
        </Box>
      </Box>

      {/* Card footer */}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "auto",
          marginBottom: "16px",
        }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              fontSize: "10px",
              color: progressStatusColor(),
            }}>
            {competition?.progress_status}
          </Typography>

          {competition?.progress_status === "Active" && (
            <div className="tflops-container">
              <div
                className="tflops-progress"
                style={{
                  borderWidth: remainingDaysOfCollaboration > 0 ? "1px" : "0px",
                  width: `${
                    (remainingDaysOfCollaboration / totalDaysOfCollaboration) *
                      100 || 0
                  }%`,
                }}
              />
            </div>
          )}

          {competition?.progress_status === "Upcoming" && (
            <Typography
              style={{
                height: "15px",
                fontSize: "10px",
                color: "#909B9D",
                marginLeft: "8px",
              }}>
              {formatDateWithTimeZone(competition?.start_date)}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
