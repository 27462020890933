import { Box, CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./style.css";
import { collaborationsService } from "services/api/collaborations";
import { nFormatter } from "utils/format";

const CollaborationPopup = ({ dataset }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState(null);

  const getTflops = async () => {
    setLoading(true);
    try {
      const { data } = await collaborationsService.getLeaderboardSummary(
        dataset.id
      );
      setSummary({
        ...data,
        percentage:
          (data.company_flops_used / data.company_flops_provided) * 100,
      });
      setError(null);
    } catch (error) {
      setError(
        error.response
          ? "Please make sure you are logged in."
          : "Make sure you are connected to the internet."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTflops();
  }, []);

  const topCandidates =
    summary &&
    summary.top_candidates_info.map((row, index) => {
      let team = null;
      if (row.is_private) {
        if (row.team) {
          team = {
            name: row.team.name,
            company_logo: "/images/avatar.png",
          };
        } else {
          team = {
            name: `${row.data_scientist.first_name} ${row.data_scientist.last_name}`,
            company_logo: "/images/avatar.png",
          };
        }
      } else {
        team = {
          ...row.company,
        };
      }

      return (
        <Grid container className="competitor-row">
          <Grid xs={1} item>
            {index + 1}
          </Grid>
          <Grid xs={2} item>
            <img
              alt={team?.name}
              src={team?.company_logo || "/images/avatar.png"}
            />
          </Grid>
          <Grid xs={6} item className="competitor-name">
            {team?.name}
          </Grid>
          <Grid
            xs={3}
            item
            style={{ textAlign: "right", color: "var(--textPrimary)" }}>
            {(+row.score).toFixed(2)}
          </Grid>
        </Grid>
      );
    });

  return (
    <>
      {loading && (
        <Grid
          alignContent="center"
          justifyContent="center"
          container
          className="competition-popup">
          <CircularProgress size={20} />
        </Grid>
      )}

      {summary && (
        <div onClick={(e) => e.stopPropagation()} className="competition-popup">
          <Box mt={2} className="competition-header">
            <Grid container justify="space-between">
              <Grid item>Leaderboard</Grid>
              <Grid item>F1</Grid>
            </Grid>
          </Box>

          <Box mt={1} className="competitors-list">
            {topCandidates}
          </Box>

          <Box mt={2}>
            <div className="tflops-container">
              <div className="tflops-progress-text">
                {nFormatter(summary.company_flops_used)}F of{" "}
                {nFormatter(summary.company_flops_provided)}F utilized
              </div>
              <div
                className="tflops-progress"
                style={{ width: `${summary.percentage}%` }}
              />
            </div>
          </Box>
        </div>
      )}
    </>
  );
};

export default CollaborationPopup;
