import axios from "axios";
import { API_URL, getAuthConfig } from "./config";

export const experimentsService = {
  getUserExperiments: async (page) => {
    return axios.get(
      `${API_URL}/experiments/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },

  getUserArchivedExperiments: async (page) => {
    return axios.get(
      `${API_URL}/experiments/?show-archived=1${page ? "&page=" + page : ""}`,
      getAuthConfig()
    );
  },

  updateExperimentsStatus: async (datasets) => {
    return axios.get(
      `${API_URL}/experiments-status/?datasets=${datasets}`,
      getAuthConfig()
    );
  },

  saveComment: async (experimentId, comment, parentId) => {
    return axios.post(
      `${API_URL}/comment/`,
      { experimentId, comment, parent_id: parentId },
      getAuthConfig()
    );
  },

  getExperimentUpdates: async (
    experimentId,
    datasetId,
    epochUpdatedDate,
    aggregateCycleUpdatedDate,
    lastEpoch = 0
  ) => {
    const urlParams = new URLSearchParams();
    if (epochUpdatedDate) {
      urlParams.append("epoch_updated_date", epochUpdatedDate);
    }
    if (aggregateCycleUpdatedDate) {
      urlParams.append(
        "aggregate_cycle_updated_date",
        aggregateCycleUpdatedDate
      );
    }

    if (lastEpoch !== 0) {
      urlParams.append("epoch", lastEpoch);
    }

    return axios.get(
      `${API_URL}/experiment-details-ping/${datasetId}/${experimentId}/?${urlParams}`,
      getAuthConfig()
    );
  },

  updateObjective: async (experimentId, objective) => {
    return axios.patch(
      `${API_URL}/experiments/${experimentId}/`,
      { objective },
      getAuthConfig()
    );
  },

  downloadModel: async (experimentId, cycleNo) => {
    return axios({
      url: `${API_URL}/downloadmodel/${experimentId}/${cycleNo}/`,
      method: "GET",
      responseType: "blob",
      ...getAuthConfig(),
    });
  },

  getDownloadToken: async (experimentId, cycleNo) => {
    return axios.get(
      `${API_URL}/getdownloadtoken/${experimentId}/${cycleNo}/`,
      getAuthConfig()
    );
  },

  updateExperimentStatus: async (experimentId, status) => {
    return axios.post(
      `${API_URL}/psr/${experimentId}/${status}/`,
      {},
      getAuthConfig()
    );
  },

  updateExperiment: async (experimentId, data) => {
    return axios.patch(
      `${API_URL}/experiments/${experimentId}/`,
      data,
      getAuthConfig()
    );
  },

  submitInference: async (experimentId, cycle) => {
    return axios.post(
      `${API_URL}/submit/${experimentId}/${cycle}/`,
      {},
      getAuthConfig()
    );
  },

  getComments: async (experimentId, page) => {
    return axios.get(
      `${API_URL}/comment/${experimentId}/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },

  getModelCode: async (experimentId) => {
    return axios.get(`${API_URL}/modelcode/${experimentId}/`, getAuthConfig());
  },

  getDatasetExperiments: async (datasetId, query) => {
    const config = {
      method: "get",
      url: `${API_URL}/experiment-details/${datasetId}/${query}`,
      ...getAuthConfig(),
    };
    const response = await axios(config);
    return response.data;
  },
  getExperimentDetails: async (datasetId, experimentId) => {
    return axios.get(
      `${API_URL}/experiment-details/${datasetId}/${experimentId}/`,
      getAuthConfig()
    );
  },
  archiveExperiment: async (experimentId, archive) => {
    return axios.patch(
      `${API_URL}/userinfo/archive-experiment/${experimentId}/`,
      { archive, type: "experiment" },
      getAuthConfig()
    );
  },

  archiveDataset: async (datasetId, archive) => {
    return axios.patch(
      `${API_URL}/userinfo/archive-dataset/${datasetId}/`,
      { archive, type: "dataset" },
      getAuthConfig()
    );
  },
};
