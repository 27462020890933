import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import "./style.css";
import DocumentationIcon from "assets/documentationIcon.png";
import DiscordImg from "assets/icons/discord-icon-svgrepo-com.svg";

const subBoxContainerStyle = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
};
const subHeadingStyle = {
  color: "#3576A7",
  fontSize: "12px",
  fontStyle: "italic",
  fontWeight: "bold",
  marginLeft: "8px",
};
const subDescriptionStyle = {
  color: "#707070",
  fontSize: "12px",
  fontStyle: "italic",
  marginTop: "24px",
};

export default function Discussion(props) {
  return (
    <div className="competition-experiments">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "48px",
          marginBottom: "110px",
        }}>
        <Typography
          style={{
            fontSize: "16px",
            color: "#707070",
          }}>
          Get Answers and Join the Discussion!
        </Typography>

        <Typography
          style={{
            fontSize: "12px",
            color: "#707070",
          }}>
          Whether you're seeking quick answers in our FAQs or want to engage
          with our community on Discord, we're here to help! Choose your path
          for support and discussions.
        </Typography>
        <Box
          style={{
            marginTop: "61px",
            display: "flex",
          }}>
          <Box
            style={{
              width: "174px",
            }}>
            <Box
              component={"a"}
              href="https://discord.gg/suXKUCAMfA"
              target="_blank"
              style={subBoxContainerStyle}>
              {/* <PlayCircleOutlineIcon
                  style={{ fontSize: "32px", color: "#266B9A" }}
                /> */}
              <img
                style={{ filter: "grayscale(0.8)" }}
                src={DiscordImg}
                alt="playIcon"
                height={"31.75px"}
              />
              <Typography style={subHeadingStyle}>Discord</Typography>
            </Box>
            <Typography style={subDescriptionStyle}>
              Join our Discord Community for Instant Answers and Support!
            </Typography>
          </Box>

          <Divider
            flexItem
            orientation="vertical"
            variant="inset"
            style={{
              marginRight: "59.5px",
            }}
          />
          <Box
            style={{
              width: "174px",
            }}>
            <Box
              component={"a"}
              href="https://docs.tracebloc.io/FAQ/General"
              target="_blank"
              style={subBoxContainerStyle}>
              <img src={DocumentationIcon} alt="docIcon" height={"31.75px"} />
              <Typography style={subHeadingStyle}>FAQs</Typography>
            </Box>
            <Typography style={subDescriptionStyle}>
              Explore our FAQs Page for Quick Answers!
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
