import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import { API_URL, GlobalContext } from "Context/GlobalState";
import { Alert } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { AddPictureIcon, LastNameIcon, SurNameIcon } from "Components/Icon";
import OnboardWizard from "Components/OnboardWizard";
import { updateUserProfile } from "services/api/auth";

const useStyles = makeStyles((theme) => ({
  avatarLarge: {
    width: 72,
    height: 72,
    "& .MuiAvatar-img": {
      // objectFit: "contain",
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#bdbdbd",
    color: "#fff",
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    border: "1px solid #FFFFFF",
    "& .MuiSvgIcon-root": {
      fontSize: "25px",
    },
  },
}))(Badge);

const UpdateProfile = (props) => {
  const classes = useStyles();
  const { user, setUserUpdate } = useContext(GlobalContext);
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const profilePicRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (firstNameRef && firstNameRef.current) {
      firstNameRef.current.value = user.first_name;
    }
    if (lastNameRef && lastNameRef.current) {
      lastNameRef.current.value = user.last_name;
    }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      const datasetKey = localStorage.getItem("datasetKey");
      setTimeout(() => {
        if (datasetKey) {
          localStorage.removeItem("datasetKey");
          history.push({
            pathname: `/collaborations/`,
          });
        } else {
          history.push({
            pathname: `/collaborations/`,
          });
        }
      }, 1000);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const updateProfile = async ({ first_name, last_name, profile_image }) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    if (profile_image) {
      formData.append("profile_image", profile_image);
    }

    try {
      const response = await updateUserProfile(formData);
      const data = response.data;
      setError(null);
      setSuccess(true);
      setLoading(false);
      setUserUpdate({ ...data });
    } catch (error) {
      if (error.response?.status === 404) {
        setError("error", {
          type: "manual",
          message: error.response.statusText,
        });
      } else if (error.response?.data) {
        Object.entries(error.response.data).forEach((value) => {
          if (
            ["old_password", "new_password", "confirm_password"].includes(
              value[0]
            )
          ) {
            setError(value[0], {
              type: "manual",
              message: value[1],
            });
          }
        });
        setError("error", {
          type: "manual",
          message: "An Error occurred.",
        });
      } else {
        setError("error", {
          type: "manual",
          message: "Make sure you are connected to the internet.",
        });
      }
      setLoading(false);
    }
  };

  const onSubmit = (data) => {
    updateProfile({ ...data, profile_image: preview });
  };

  return (
    <Grid
      direction="column"
      container
      justifyContent="center"
      alignItems="stretch"
      style={{ height: "calc(100vh - 20px)" }}>
      {localStorage.getItem("datasetKey") && success ? (
        <Grid
          container
          direction="row"
          justify="flex-start"
          className="profile-update-main">
          <Grid className="profile-update" container direction="column">
            <Grid
              className="form"
              container
              direction="column"
              style={{ height: "305px", width: "616px" }}>
              <Grid item>
                <h2 className="title" style={{ margin: "8px 0" }}>
                  Thank You
                </h2>
              </Grid>
              <Grid item>
                <div className="thank-you">
                  Your account is ready in just a second. You will be redirected
                  automatically.
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justify="flex-start"
          className="profile-update-main">
          <Grid className="profile-update" container direction="column">
            <Box
              padding={1}
              style={{ display: "flex", flexDirection: "column" }}>
              <Box
                style={{
                  width: "100%",
                  padding: "0px 32px",
                  alignSelf: "center",
                }}>
                <h2
                  className="title"
                  style={{ alignSelf: "center", textAlign: "center" }}>
                  Update Profile
                </h2>
              </Box>
            </Box>

            {user && (!user.first_name || !user.last_name) && (
              <OnboardWizard page={"profile"} />
            )}

            <Grid className="form" container direction="column">
              {user && user.is_password_changed === false ? (
                <Grid item>
                  <Alert
                    icon={false}
                    severity="warning"
                    className="sub-title"
                    style={{ textAlign: "left" }}>
                    For security, you need to change the auto generated password
                    sent to you in email.
                  </Alert>
                </Grid>
              ) : null}

              {errors.error && (
                <Grid item>
                  <Alert icon={false} severity="error">{errors.error?.message}</Alert>
                </Grid>
              )}

              {success && (
                <Grid item>
                  <Alert icon={false} variant="filled" severity="success">
                    Profile updated successfully.
                  </Alert>
                </Grid>
              )}

              <form
                encType="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}>
                <Grid className="profile-picture" item>
                  <input
                    ref={profilePicRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="profile-picture-file"
                    data-testid="profile-picture-input"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file["type"].split("/")[0] === "image") {
                        setPreview(e.target.files[0]);
                      }
                    }}
                  />

                  <label htmlFor="profile-picture-file">
                    <IconButton component="span">
                      <StyledBadge
                        component="span"
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={<AddPictureIcon />}>
                        <Avatar
                          variant="circular"
                          src={
                            preview
                              ? URL.createObjectURL(preview)
                              : (user && user.profile_image) ||
                                "/images/avatar.png"
                          }
                          className={classes.avatarLarge}
                        />
                      </StyledBadge>
                    </IconButton>
                  </label>
                  {
                    <div className="error">
                      {errors.profile_picture?.message}
                    </div>
                  }
                </Grid>
                <Grid item>
                  <TextField
                    inputProps={{
                      ...register("first_name", {
                        required: "First Name is required",
                        minLength: {
                          value: 3,
                          message:
                            "First Name must contain at least 3 characters.",
                        },
                      }),
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SurNameIcon />
                        </InputAdornment>
                      ),
                    }}
                    type={"text"}
                    onChange={() => clearErrors()}
                    inputRef={firstNameRef}
                    margin="normal"
                    className="profile-update-name"
                    placeholder="First Name"
                    defaultValue={user.first_name}
                    helperText={
                      <span className="error">
                        {errors.first_name?.message}
                      </span>
                    }
                    variant="outlined"
                  />
                </Grid>

                <Grid item>
                  <TextField
                    inputProps={{
                      ...register("last_name", {
                        required: "Last Name is required",
                        minLength: {
                          value: 3,
                          message:
                            "Last Name must contain at least 3 characters.",
                        },
                      }),
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LastNameIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={() => clearErrors()}
                    inputRef={lastNameRef}
                    type={"text"}
                    margin="normal"
                    className="profile-update-name"
                    placeholder="Last Name"
                    defaultValue={user.last_name}
                    helperText={
                      <span className="error">{errors.last_name?.message}</span>
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    className="profile-update-button"
                    color="primary"
                    classes={{ label: "label" }}
                    disableElevation>
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateProfile;
