import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
  useContext,
} from "react";
import { GlobalContext } from "Context/GlobalState";

const useStickyHeader = (defaultSticky = false) => {
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const {
    sidebar: { open },
  } = useContext(GlobalContext);
  const [isBottom, setIsBottom] = useState(false);
  const componentRef = useRef(null);
  const [coords, setCoords] = useState({
    width: 0,
    left: 0,
    right: 0,
    height: 0,
  });

  const toggleStickiness = useCallback(
    ({ top, bottom }) => {
      if (
        top <= 0 &&
        // When scrolling from bottom to top when and
        // the last row is visible enough, sticky header will be triggered.
        // This number (68) could be adjusted or skipped.
        bottom > 2 * 68
      ) {
        !isSticky && setIsSticky(true);
      } else {
        isSticky && setIsSticky(false);
      }

      if (bottom < 2 * 800) {
        !isBottom && setIsBottom(true);
      } else {
        isBottom && setIsBottom(false);
      }
    },
    [isSticky, isBottom]
  );

  useEffect(() => {
    // console.log('getBoundingClientRect', componentRef.current)
    if (componentRef.current) {
      setCoords(componentRef.current.getBoundingClientRect());
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (componentRef.current) {
        setCoords(componentRef.current.getBoundingClientRect());
      }
    }, 500);
  }, [open]);

  useEffect(() => {
    const handleScroll = (event) => {
      if (componentRef.current) {
        toggleStickiness(componentRef.current.getBoundingClientRect());
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleStickiness, open]);

  useLayoutEffect(() => {
    function updateSize() {
      setTimeout(() => {
        if (componentRef.current) {
          setCoords(componentRef.current.getBoundingClientRect());
        }
      }, 500);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return { componentRef, isSticky, isBottom, coords };
};

export default useStickyHeader;
