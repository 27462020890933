import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, StylesProvider } from "@material-ui/core";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import "./App.css";
import "./animations.css";
import { GlobalProvider } from "./Context/GlobalState";
import theme from "./Theme";
import Routes from "./Routes";
import { AppTourProvider } from "Context/TourContext";

import { reactPlugin } from "./AppInsights";

const App = () => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppInsightsContext.Provider value={reactPlugin}>
          <GlobalProvider>
            <AppTourProvider>
              <Routes />
            </AppTourProvider>
          </GlobalProvider>
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
