import axios from "axios";
import { API_URL, getAuthConfig } from "./config";

export const autonomousService = {
  createAutonomousDataset: async ({
    ratio,
    classes,
    company,
    type,
    category,
  }) => {
    const classesString =
      classes && `classes=${classes.map((item) => item.label).join(",")}`;
    const companyString =
      company?.length > 0 &&
      `company=${company.map((item) => item.label).join(",")}`;
    const typeString = `type=${type}`;

    return axios.get(
      `${API_URL}/create-autonomous-dataset/?ratio=${ratio}&${classesString}&${companyString}&${typeString}&category=${category}`,
      getAuthConfig()
    );
  },
};
