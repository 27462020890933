import React from "react";
import "./style.css";

const OnboardWizard = ({ page }) => {
  return (
    <ul data-testid="onboard-wizard" className="onboard-wizard">
      <li className={page === "login" ? "active" : ""}>1</li>
      <li className={page === "password" ? "active" : ""}>2</li>
      <li className={page === "profile" ? "active" : ""}>3</li>
    </ul>
  );
};

export default OnboardWizard;
