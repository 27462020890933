import {
  Avatar,
  Grid,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
  CircularProgress,
  TableContainer,
  Portal,
  Button,
  withStyles,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";

import {
  ArchiveIcon,
  CheckIcon,
  CloseIcon,
  InfoIcon,
  InviteIcon,
  MenuIcon,
  NotificationCheckIcon,
  OpenIcon,
  TrainingRunningIcon,
} from "Components/Icon";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import ExperimentRow from "./ExperimentRow";
import { Alert } from "@material-ui/lab";
import { numberWithSpaces, timeSince } from "utils/format";
import CopyToClipboard from "react-copy-to-clipboard";
import { GlobalContext } from "Context/GlobalState";
import { SAMPLES_IMAGES_URL, SERVER_URL } from "services/api/config";
import CollaborationPopup from "./CollaborationPopup";
import DatasetDialog from "./DatasetDialog";
import useStickyHeader from "utils/useStickyHeader";
import TeamPopup from "./TeamPopup";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { encodeQuery, trainingState } from "utils";
import CustomPopOver from "Components/CustomPopOver";
import { useTourContext } from "Context/TourContext";
import { ButtonGroup } from "Components/ButtonGroup";
import { HtmlTooltip } from "Components/Sustainability";
import {
  experimentsService
} from "services/api/experiments";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingLeft: "24px",
    paddingRight: "24px",
    "& .MuiListItemIcon-root": {
      minWidth: 30,
      color: "#656565",
      "& .icon": {
        width: 16,
        color: "#656565",
      },
    },
    "& .MuiListItemText-primary": {
      fontSize: 10,
      color: "#707070",
      fontStyle: "italic",
    },
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        //color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

// { id, name, date, members, rating };
function DataSetRow(props) {
  const {
    sidebar: { open: sidebarStatus },
    state: {
      dataset: { amplify, dataset_type },
    },
  } = useContext(GlobalContext);
  const {
    setState,
    state: { run, stepIndex, steps },
  } = useTourContext();

  const { row, datasetIndex, labelId, onClick, selected, ...rowProps } = props;
  const [open, setOpen] = useState(selected);
  const [openUserPopover, setOpenUserPopover] = useState(false);
  const [openMenuPopover, setOpenMenuPopover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [sortBy, setSortBy] = useState("created_date");
  const [experimentArchived, setExperimentArchived] = useState(null);
  const [page, setPage] = useState(0);
  const [next, setNext] = useState(null);
  const [experiments, setExperiments] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorUserEl, setAnchorUserEl] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [openDatasetDialog, setOpenDatasetDialog] = useState(false);
  const { componentRef, isSticky, isBottom, coords } = useStickyHeader();
  const { datasetSlug, experimentSlug } = useParams();
  const history = useHistory();
  const location = useLocation();

  const lastChange = new Date(row.exp_updated_date);

  const IMAGE_URL =
    row.dataset_type === "medical"
      ? SAMPLES_IMAGES_URL
      : SERVER_URL + "images/xray/";

  const rowOpen = () => {
    setOpen(!open);
    onClick();
    setAnchorMenuEl(null);
    console.log("rowOpen", selected, open);
  };

  const showCopyMessage = (e) => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const copyDatasetId = (e) => {
    e.stopPropagation(); // stop the row from being clicked
  };

  const getExperiments = async () => {
    setLoading(true);
    props.expLoading(true);

    try {
      const token = localStorage.getItem("_key_usr_tkn");
      if (!token) {
        const index = location.pathname.indexOf("/details");
        const redirectPath = location.pathname.substring(
          0,
          index + "/details".length
        );

        history.push({
          pathname: "/",
          state: {
            redirect_to: `${redirectPath}/experiments`,
          },
        });
        return false;
      }

      let query = {};
      query["order-by"] = sortBy;
      if (sortBy === "v_gap_accuracy" || sortBy === "v_gap") {
        query["descending"] = 0;
      }
      if (page) {
        query["page"] = page;
      }
      if (experimentSlug) {
        query["first"] = experimentSlug;
      }
      query = encodeQuery(query);

      const { results, next } = await experimentsService.getDatasetExperiments(row.id, query);
      const experimentsToAdd = results.filter(
        (item) => !experiments.some((obj) => obj.id === item.id)
      );

      setNext(next);
      if (experimentsToAdd.length > 0) {
        setExperiments([...experiments, ...experimentsToAdd]);
      }

      if (datasetIndex === 0 && !localStorage.getItem("tour")) {
        setState({ run: true, tourActive: true, stepIndex: 0 });
      }

      setError(null);
    } catch (error) {
      setError(
        error.response
          ? "Something went wrong. Please try again in a while."
          : "Make sure you are connected to the internet."
      );
    } finally {
      setLoading(false);
      props.expLoading(false);
    }
  };

  const sendArchiveExperiment = async (archive, experiment) => {
    try {
      await experimentsService.archiveExperiment(experiment.id, archive);
      setError(null);
    } catch (error) {
      console.log("CATCH", error.response);
    }
  };

  const handleDatasetDetails = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    // console.log(row)
    setOpenDatasetDialog(true);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = open ? "competition-popper" : undefined;

  const usersPopover = Boolean(anchorUserEl);
  const userPopoverId = openUserPopover ? "users-popper" : undefined;

  const openMenu = Boolean(anchorMenuEl);
  const menuPopoverId = openMenuPopover ? "menu-popper" : undefined;

  const handleCollaborationDetails = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    console.log(row);
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setAnchorEl(null);
  };

  const handleUsersDetails = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    console.log(row);
    setAnchorUserEl(e.currentTarget);
  };

  const handleUserPopoverClose = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setAnchorUserEl(null);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setAnchorMenuEl(e.currentTarget);
  };

  const handleMenuClose = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    setAnchorMenuEl(null);
  };

  const handleArchiveDataset = (e) => {
    e.stopPropagation(); // stop the row from being clicked
    props.archiveDataset(row, datasetIndex);
    setAnchorMenuEl(null);
  };

  useEffect(() => {
    /*
      Important: props.showHeader is added to ignore the tour param. otherwise the experiments
      would not show up when the tour set to null.
      the showHeader === true signifies that it is not the competition experiment tab.
    */
    if (datasetIndex === 0 && props.showHeader && !localStorage.getItem("tour")) {
      console.log("TOUR####", localStorage.getItem("tour"))
      rowOpen();
    }
  }, []);

  useEffect(() => {
    if (datasetSlug === row.dataset_key) {
      rowOpen();
    }
  }, [datasetSlug, experimentSlug]);

  useEffect(() => {
    if (open) {
      getExperiments();
    }

    return () => {
      setExperiments([]);
      setPage(0);
      setNext(null);
    };
  }, [open, sortBy]);

  useEffect(() => {
    if (experiments.length > 0) {
      props.hasExperiment(true);
    } else {
      props.hasExperiment(false);
    }
    return () => {};
  }, [experiments]);

  useEffect(() => {
    if (open) {
      getExperiments();
    }
  }, [page]);

  useEffect(() => {
    if (isBottom && next) {
      const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      setPage(params.page);
    }
  }, [isBottom]);

  useEffect(() => {}, [sidebarStatus]);



  const handleArchiveExperiment = (experiment, expIndex) => {
    const result = experiments.filter((obj) => {
      return obj.id !== experiment.id;
    });
    setExperiments([...result]);

    setExperimentArchived({
      message: "Experiment archived successfully",
      index: expIndex,
      experiment: experiment,
    });
  };

  const accessTypeToolTip = () => {
    if (row.access_type === "Global") {
      return "Anyone with dataset owner's permission can join.";
    } else if (row.access_type === "Enterprise") {
      return "Only people in your organization with dataset owner's permission can join.";
    } else if (row.access_type === "Public") {
      return "Everyone automatically joins the dataset.";
    }
  };

  const handleSortChange = (value) => {
    if (value !== sortBy) {
      setSortBy(value);
      setExperiments([]);
      setPage(0);
      setNext(null);
    }
  };

  console.log('experiments', experiments.length);

  return (
    <React.Fragment>
      {isSticky && props.showHeader === true && (
        /*
        sticky will be the copy of table header while sticky 
        needed as otherwise table won't preserve columns width
        */
        <TableRow>
          <TableCell
            style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
            colSpan={10}>
            <TableContainer className="experiments-table-conatainer">
              <Table
                className="table sticky"
                style={{
                  display: openDatasetDialog ? "none" : "",
                  position: "fixed",
                  width: coords.width,
                  top: 5,
                  left: coords.left,
                  zIndex: 900,
                }}>
                <TableBody>
                  <TableRow selected={open} {...rowProps} onClick={rowOpen}>
                    <TableCell
                      id={
                        datasetIndex === 0
                          ? "first-dataset-icon"
                          : "dataset-icon"
                      }
                      style={{ width: "40px" }}>
                      <Tooltip title="Dataset Info">
                        <IconButton
                          onClick={handleDatasetDetails}
                          className="icon-button"
                          aria-label="archive"
                          color="primary">
                          <InfoIcon className="icon" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Dataset name​​">
                        <span>{row.title}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Grid container alignItems="center">
                        {!row.isCollaboration && (
                          <Tooltip
                            title={
                              row.access_type === "Global"
                                ? "Owner can share dataset with everyone ​"
                                : "Owner can share dataset within own organization​"
                            }>
                            <div
                              aria-describedby={popoverId}
                              onClick={handleCollaborationDetails}
                              className={`tag ${row.access_type}-tag`}>
                              <span>{row.access_type}</span>
                            </div>
                          </Tooltip>
                        )}
                        {row.isCollaboration ? (
                          <>
                            <Grid item>
                              <Tooltip title="Only people in you organization with permission can join the competition.">
                                <div
                                  className="tag competition-tag">
                                  <span>Collaboration</span>
                                </div>
                              </Tooltip>
                              <CustomPopOver
                                id={popoverId}
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}>
                                <CollaborationPopup dataset={row} />
                              </CustomPopOver>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </TableCell>
                    <TableCell onClick={copyDatasetId} id={labelId}>
                      <Tooltip title="Copy ID​">
                        <CopyToClipboard
                          text={row.dataset_key}
                          onCopy={showCopyMessage}>
                          <Grid container alignItems="center">
                            <Grid item>{row.dataset_key}</Grid>
                          </Grid>
                        </CopyToClipboard>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Number of data points in dataset​">
                        <span>
                          {numberWithSpaces(
                            row?.dataset_meta?.unique_data_items_count || 0
                          )}
                        </span>
                      </Tooltip>
                    </TableCell>

                    <Tooltip title="N° of Experiments executed">
                      <TableCell>{row.experiments}</TableCell>
                    </Tooltip>
                    <TableCell>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center">
                        <AvatarGroup
                          aria-describedby={userPopoverId}
                          onClick={handleUsersDetails}
                          spacing={5}
                          classes={{
                            root: "avatars-group",
                            avatar: "avatar-small",
                          }}
                          max={5}>
                          {row.isCollaboration ? (
                            <Tooltip title={`Host: ${row?.host?.name}`}>
                              <Avatar
                                className="avatar-small"
                                alt={row?.host?.name}
                                src={`${row?.host?.company_logo}`}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={`Owner: ${row.owner.first_name} ${row.owner.last_name}`}>
                              <Avatar
                                className="avatar-small"
                                alt={row.owner.first_name}
                                src={`${row.owner.profile_image}`}
                              />
                            </Tooltip>
                          )}
                          {row.users &&
                            row.users.map((user) => {
                              return user.id !== row.owner.id ? (
                                <Tooltip
                                  title={`${user.first_name} ${user.last_name}`}>
                                  <Avatar
                                    className="avatar-small"
                                    alt={user.first_name}
                                    src={`${user.profile_image}`}
                                  />
                                </Tooltip>
                              ) : null;
                            })}
                        </AvatarGroup>

                        <Tooltip title="Add user to dataset">
                          <Avatar
                            onClick={handleUsersDetails}
                            className="avatar-icon">
                            <InviteIcon className="icon" />
                          </Avatar>
                        </Tooltip>

                        <CustomPopOver
                          id={userPopoverId}
                          open={usersPopover}
                          anchorEl={anchorUserEl}
                          onClose={handleUserPopoverClose}>
                          <TeamPopup
                            datasetKey={row.dataset_key}
                            datasetId={row.id}
                            teamId={row?.user_team?.id || null}
                            isCollaboration={row.isCollaboration}
                            users={row.users}
                            owner={row.owner}
                            pending={row.pending_invitations}
                            host={row.host}
                            accessType={row.access_type}
                          />
                        </CustomPopOver>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      {timeSince(
                        new Date(
                          lastChange.toLocaleString("en-US", {
                            timeZone:
                              Intl.DateTimeFormat().resolvedOptions().timeZone,
                          })
                        )
                      )}
                    </TableCell>

                    <TableCell>
                      <Tooltip
                        title={
                          row.status === trainingState.completed
                            ? "No experiment created for this dataset yet. Be the first!"
                            : "Experiments In Progress"
                        }>
                        <Grid container direction="row" alignItems="center">
                          {row.status === trainingState.completed ? (
                            <CheckIcon className="icon" />
                          ) : (
                            <TrainingRunningIcon className="icon" />
                          )}
                        </Grid>
                      </Tooltip>
                    </TableCell>

                    <TableCell style={{ width: "40px" }}>
                      <Tooltip title="More Options​">
                        <IconButton
                          aria-describedby={menuPopoverId}
                          onClick={handleMenuClick}
                          className="icon-button"
                          aria-label="archive"
                          color="primary">
                          <MenuIcon className="icon" />
                        </IconButton>
                      </Tooltip>

                      <CustomPopOver
                        id={menuPopoverId}
                        open={openMenu}
                        anchorEl={anchorMenuEl}
                        onClose={handleMenuClose}
                        ContainerPadding={{
                          paddingLeft: "0px",
                          paddingRight: "0px",
                          paddingTop: "24px",
                          paddingBottom: "24px",
                        }}>
                        <StyledMenuItem onClick={handleArchiveDataset}>
                          <ListItemIcon>
                            <ArchiveIcon className="icon" />
                          </ListItemIcon>
                          <ListItemText primary="Archive" />
                        </StyledMenuItem>

                        <StyledMenuItem onClick={rowOpen}>
                          <ListItemIcon>
                            {open ? (
                              <CloseIcon className="icon" />
                            ) : (
                              <OpenIcon className="icon" />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={open ? "Close" : "Open"} />
                        </StyledMenuItem>
                      </CustomPopOver>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TableCell>
        </TableRow>
      )}

      {props.showHeader && (
        <TableRow
          id={datasetIndex === 0 ? "first-dataset-row" : "dataset-row"}
          selected={open}
          {...rowProps}
          onClick={rowOpen}>
          <TableCell
            id={datasetIndex === 0 ? "first-dataset-icon" : "dataset-icon"}
            style={{ width: "40px" }}>
            <Tooltip title="Dataset Info">
              <IconButton
                onClick={handleDatasetDetails}
                className="icon-button"
                aria-label="archive"
                color="primary">
                <InfoIcon className="icon" />
              </IconButton>
            </Tooltip>
          </TableCell>

          <TableCell>
            <Tooltip title="Dataset name​​">
              <span>{row.title}</span>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Grid container alignItems="center">
              {!row.isCollaboration && (
                <Tooltip title={accessTypeToolTip()}>
                  <div
                    aria-describedby={popoverId}
                    onClick={handleCollaborationDetails}
                    className={`tag ${row.access_type}-tag`}>
                    <span>{row.access_type}</span>
                  </div>
                </Tooltip>
              )}
              {row.isCollaboration ? (
                <>
                  <Grid item>
                    <Tooltip title="Only people in you organization with permission can join the competition">
                      <div
                        className="tag competition-tag">
                        <span>Collaboration</span>
                      </div>
                    </Tooltip>
                    <CustomPopOver
                      id={popoverId}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}>
                      <CollaborationPopup dataset={row} />
                    </CustomPopOver>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </TableCell>
          <TableCell onClick={copyDatasetId} id={labelId}>
            <Tooltip title="Copy ID​">
              <CopyToClipboard text={row.dataset_key} onCopy={showCopyMessage}>
                <Grid container alignItems="center">
                  <Grid item>{row.dataset_key}</Grid>
                </Grid>
              </CopyToClipboard>
            </Tooltip>
          </TableCell>

          <TableCell>
            <Tooltip title="Number of data points in dataset​">
              <span>
                {numberWithSpaces(
                  row?.dataset_meta?.unique_data_items_count || 0
                )}
              </span>
            </Tooltip>
          </TableCell>

          <Tooltip title="N° of Experiments executed">
            <TableCell>{row.experiments}</TableCell>
          </Tooltip>
          <TableCell>
            <Grid container justifyContent="flex-start" alignItems="center">
              <AvatarGroup
                aria-describedby={userPopoverId}
                onClick={handleUsersDetails}
                spacing={5}
                classes={{ root: "avatars-group", avatar: "avatar-small" }}
                max={5}>
                {row.isCollaboration ? (
                  <Tooltip title={`Host: ${row?.host?.name}`}>
                    <Avatar
                      className="avatar-small"
                      alt={row?.host?.name}
                      src={`${row?.host?.company_logo}`}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`Owner: ${row.owner.first_name} ${row.owner.last_name}`}>
                    <Avatar
                      className="avatar-small"
                      alt={row.owner.first_name}
                      src={`${row.owner.profile_image}`}
                    />
                  </Tooltip>
                )}
                {row.users &&
                  row.users.map((user) => {
                    return user.id !== row.owner.id ? (
                      <Tooltip title={`${user.first_name} ${user.last_name}`}>
                        <Avatar
                          className="avatar-small"
                          alt={user.first_name}
                          src={`${user.profile_image}`}
                        />
                      </Tooltip>
                    ) : null;
                  })}
              </AvatarGroup>

              <Tooltip title="Add user to dataset​">
                <Avatar onClick={handleUsersDetails} className="avatar-icon">
                  <InviteIcon className="icon" />
                </Avatar>
              </Tooltip>

              <CustomPopOver
                id={userPopoverId}
                open={usersPopover}
                anchorEl={anchorUserEl}
                onClose={handleUserPopoverClose}>
                <TeamPopup
                  datasetKey={row.dataset_key}
                  datasetId={row.id}
                  isCollaboration={row.isCollaboration}
                  teamId={row?.user_team?.id || null}
                  users={row.users}
                  owner={row.owner}
                  pending={row.pending_invitations}
                  host={row.host}
                  accessType={row.access_type}
                />
              </CustomPopOver>
            </Grid>
          </TableCell>

          <TableCell>
            <Tooltip title="Last Updated​">
              <span>
                {timeSince(
                  new Date(
                    lastChange.toLocaleString("en-US", {
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    })
                  )
                )}
              </span>
            </Tooltip>
          </TableCell>

          <TableCell>
            <Tooltip
              title={
                row.status === trainingState.completed
                  ? "No experiment currently in training​​"
                  : "Experiments In Progress​"
              }>
              <Grid container direction="row" alignItems="center">
                {row.status === trainingState.completed ? (
                  <CheckIcon className="icon" />
                ) : (
                  <TrainingRunningIcon className="icon" />
                )}
              </Grid>
            </Tooltip>
          </TableCell>

          <TableCell style={{ width: "40px" }}>
            <Tooltip title="More Options​">
              <IconButton
                aria-describedby={menuPopoverId}
                onClick={handleMenuClick}
                className="icon-button"
                aria-label="archive"
                color="primary">
                <MenuIcon className="icon" />
              </IconButton>
            </Tooltip>

            <CustomPopOver
              id={menuPopoverId}
              open={openMenu}
              anchorEl={anchorMenuEl}
              onClose={handleMenuClose}
              ContainerPadding={{
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingTop: "24px",
                paddingBottom: "24px",
              }}>
              <StyledMenuItem onClick={handleArchiveDataset}>
                <ListItemIcon>
                  <ArchiveIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="Archive" />
              </StyledMenuItem>

              <StyledMenuItem onClick={rowOpen}>
                <ListItemIcon>
                  {open ? (
                    <CloseIcon className="icon" />
                  ) : (
                    <OpenIcon className="icon" />
                  )}
                </ListItemIcon>
                <ListItemText primary={open ? "Close" : "Open"} />
              </StyledMenuItem>
            </CustomPopOver>
          </TableCell>
        </TableRow>
      )}


      <TableRow ref={componentRef}>
        <TableCell
          style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}>
          <Collapse
            className="experiment-container"
            in={open}
            timeout="auto"
            unmountOnExit>
            {props.showHeader === false && experiments.length === 0 ? (
              <></>
            ) : (
              <Grid
                alignItems="center"
                container
                style={{
                  position: isSticky ? "fixed" : "absolute",
                  top: isSticky ? "60px" : "-8px",
                  marginTop: isSticky
                    ? "0px"
                    : props.showHeader
                    ? "20px"
                    : "0px",
                  marginLeft: "32px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  paddingTop: "0px",
                  zIndex: 100,
                  width: "100%",
                  maxWidth: componentRef?.current?.clientWidth,
                }}>
                <Grid
                  style={{
                    maxWidth: "fit-content",
                    minWidth: "600px",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "4px",
                    padding: "9px 12px",
                  }}
                  alignItems="center"
                  container>
                  <Grid item>
                    <span className="sort-by">Sort By</span>
                  </Grid>
                  <Grid item>
                    <ButtonGroup>
                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-sub-title">
                              Sorting by the latest experiment added to the
                              dataset
                            </div>
                          </div>
                        }>
                        <Button
                          onClick={() => handleSortChange("created_date")}
                          className={
                            sortBy === "created_date" ? `selected` : ""
                          }
                          variant="contained"
                          color="primary">
                          Date
                        </Button>
                      </HtmlTooltip>

                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-sub-title">
                              Sorting by your latest added experiments
                            </div>
                          </div>
                        }>
                        <Button
                          onClick={() => handleSortChange("own_experiments")}
                          className={
                            sortBy === "own_experiments" ? `selected` : ""
                          }
                          variant="contained"
                          color="primary">
                          Mine
                        </Button>
                      </HtmlTooltip>

                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-sub-title">
                              Sorting by the most upvoted experiments
                            </div>
                          </div>
                        }>
                        <Button
                          onClick={() => handleSortChange("claps")}
                          className={sortBy === "claps" ? `selected` : ""}
                          variant="contained"
                          color="primary">
                          Upvote
                        </Button>
                      </HtmlTooltip>
                    </ButtonGroup>
                  </Grid>

                  <Grid item>
                    <span className="sort-by-label">Validation</span>
                  </Grid>
                  <Grid item>
                    <ButtonGroup
                      style={{
                        marginLeft: "0px",
                      }}>
                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Sorting for{" "}
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "acc"}
                              -flops'
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Weighted sum of training{" "}
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "accuracy"}{" "}
                              and normalized flops utilization.
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              The{" "}
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "accuracy"}{" "}
                              to flops weight ratio is 80/20. The flops
                              utilization is normalized by min-max scaling to
                              the interval [0,1]. A score of 1 marks the best
                              performance.
                            </div>
                          </div>
                        }>
                        <Button
                          onClick={() => handleSortChange("accuracy_flops")}
                          className={
                            sortBy === "accuracy_flops" ? `selected` : ""
                          }
                          style={{
                            fontWeight:
                              sortBy === "accuracy_flops" ? "bold" : "normal",
                          }}
                          variant="contained"
                          color="primary">
                          {row.category === "keypoint_detection"
                            ? "pck"
                            : "acc"}
                          -flops'
                        </Button>
                      </HtmlTooltip>

                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Sorting for{" "}
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "acc"}
                              -gCO2e'
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Weighted sum of training{" "}
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "accuracy"}{" "}
                              and normalized gCO2e emission.
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              The{" "}
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "accuracy"}{" "}
                              to gCO2e weight ratio is 80/20. The gCO2e emission
                              is normalized by min-max scaling to the interval
                              [0,1]. A score of 1 marks the best performance.
                            </div>
                          </div>
                        }>
                        <Button
                          onClick={() => handleSortChange("accuracy_gco2")}
                          className={
                            sortBy === "accuracy_gco2" ? `selected` : ""
                          }
                          variant="contained"
                          color="primary">
                          {row.category === "keypoint_detection"
                            ? "pck"
                            : "acc"}
                          -gCO2e'
                        </Button>
                      </HtmlTooltip>

                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Sorting for vgap'-
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "acc"}
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Weighted sum of training{" "}
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "accuracy"}{" "}
                              and normalized vgap (|validation loss - training
                              loss|).
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              The{" "}
                              {row.category === "keypoint_detection"
                                ? "pck"
                                : "accuracy"}{" "}
                              to vgap weight ratio is 80/20. The vgap is
                              normalized by min-max scaling to the interval
                              [0,1]. A score of 1 marks the best performance.
                            </div>
                          </div>
                        }>
                        <Button
                          onClick={() => handleSortChange("v_gap_accuracy")}
                          className={
                            sortBy === "v_gap_accuracy" ? `selected` : ""
                          }
                          variant="contained"
                          color="primary">
                          vgap'-
                          {row.category === "keypoint_detection"
                            ? "pck"
                            : "acc"}
                        </Button>
                      </HtmlTooltip>

                      <HtmlTooltip
                        arrow
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Sorting for the validation gap
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              vgap = min( | validation loss - training loss | )
                            </div>
                          </div>
                        }>
                        <Button
                          onClick={() => handleSortChange("v_gap")}
                          className={sortBy === "v_gap" ? `selected` : ""}
                          variant="contained"
                          color="primary">
                          vgap
                        </Button>
                      </HtmlTooltip>
                    </ButtonGroup>
                  </Grid>
                </Grid>

                {props.showHeader === false && (
                  <Grid
                    style={{
                      marginLeft: "auto",
                      paddingRight: "44px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    item>
                    <Box
                      onClick={props.showArchived}
                      style={{
                        borderColor: "#3576A7",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "5px",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                      }}>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontStyle: "normal",
                          color: "#3576A7",
                        }}>
                        Archived
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}

            {experiments.length > 0 &&
              experiments.map((experiment, index) => {
                return (
                  <ExperimentRow
                    key={experiment.id}
                    expIndex={index}
                    experimentData={experiment}
                    archiveExperiment={handleArchiveExperiment}
                    showHeader={props.showHeader}
                  />
                );
              })}
            {props.showHeader === true &&
              experiments.length === 0 &&
              !loading &&
              !error && (
                <Grid
                  style={{ minHeight: "200px" }}
                  container
                  justify="center"
                  alignItems="center">
                  No experiment created for this dataset yet. Be the first!
                </Grid>
              )}

            {props.showHeader === true &&
              experiments.length === 0 &&
              !loading &&
              error && (
                <Grid
                  style={{ minHeight: "200px" }}
                  container
                  justify="center"
                  alignItems="center">
                  {error}
                </Grid>
              )}

            {loading && (
              <Grid
                style={{ minHeight: "200px" }}
                container
                justify="center"
                alignItems="center">
                <CircularProgress />
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

      <Portal>
        {copied && (
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <Alert
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              variant="filled"
              severity="success">
              Dataset ID successfully copied
            </Alert>
          </Snackbar>
        )}

        {experimentArchived !== null && (
          <Snackbar
            open={experimentArchived !== null}
            autoHideDuration={2000}
            disableWindowBlurListener
            onClose={() => {
              if (experimentArchived !== null) {
                sendArchiveExperiment(true, experimentArchived.experiment);
              }
              setExperimentArchived(null);
            }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <Alert
              variant="filled"
              severity="success"
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              action={
                <Button
                  size="small"
                  onClick={() => {
                    const expeUndo = experiments;
                    expeUndo.splice(
                      experimentArchived.index,
                      0,
                      experimentArchived.experiment
                    );
                    setExperiments([...expeUndo]);
                    console.log("EXP-UNDO", expeUndo);
                    setExperimentArchived(null);
                  }}>
                  UNDO
                </Button>
              }>
              {experimentArchived.message}
            </Alert>
          </Snackbar>
        )}
      </Portal>

      <DatasetDialog
        dataset_type={dataset_type}
        imageUrl={IMAGE_URL}
        dataset={row}
        open={openDatasetDialog}
        closeCallback={() => setOpenDatasetDialog(false)}
      />
    </React.Fragment>
  );
}

export default DataSetRow;
